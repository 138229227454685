import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import * as moment from "moment";
import { useGetApiClient } from "../../api/useApiClient";
import { toast } from "react-toastify";
import { SpinningBlueCircleLoader } from "../../components/common/loaders";

const colorStatus = {
  ARC: "rgb(213 146 255)",
  VOTING: "rgb(80 181 255)",
  ISSUE: "rgb(255 186 104)",
  EVENT: "rgb(108 117 125)",
  BILLING: "rgb(45 205 178)",
};

const Calendar = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const getList = async () => {
    try {
      setLoading(true);
      const newDate = new Date();
      const monthPlus1 = newDate.getMonth() + 1;
      const todayMonth = monthPlus1 < 10 ? `0${monthPlus1}` : monthPlus1;
      const todayDay =
        newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
      const todayYear = newDate.getFullYear();
      const { data } = await useGetApiClient.get(
        `/admin/dashboard/get/calendar?from=${todayYear}-${todayMonth}-01&to=${todayYear}-12-31`
      );

      if (data?.error) throw new Error(data?.error);

      setEvents(
        data.map((item) => ({
          title: item.name,
          url: "",
          start: new Date(item.startDate),
          end: new Date(item.endDate),
          color: colorStatus[item.type],
        }))
      );
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      {loading ? (
        <div style={{ width: "100%" }}>
          <SpinningBlueCircleLoader />
        </div>
      ) : (
        <FullCalendar
          className="calendar-s"
          initialView="dayGridMonth"
          contentHeight="auto"
          eventLimit=" true"
          dayMaxEvents={1}
          plugins={[dayGridPlugin, listPlugin, bootstrapPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
          }}
          events={events}
        />
      )}
    </>
  );
};

export default Calendar;

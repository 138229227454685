import { useEffect, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SpinningBlueCircleLoader, SpinningDynamicCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient, useUploadPictureApi } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import { isValidJson } from "../../../utilities/helpers";
import ConfirmModal from "../../../components/common/ConfirmModal";
import defaultUser from "../../../assets/images/user/default_user-gray.png";
import { prodApiUrl, stageApiUrl } from "../../../utilities/constants";

const AddNewVendor = ({ setShowAddNew, details, setDetails, changesModal, setChangesModal }) => {

  const [loading, setLoading] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });
  const [message, setMessage] = useState(EditorState.createEmpty());
  const [form, setForm] = useState({
    name: "",
    service: "",
    email: "",
    phone: "",
    facebook: "",
    address: "",
    picture: defaultUser,
    headline: "",
  })

  const handleAttachPicture = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    try {
      setLoading(true);
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/configuration/vendor/upload/logo', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({ ...form, picture: uploadedPicture?.directoryPath });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      const inputfile = document.getElementsByName("picture")?.[0];
      inputfile.value = "";
      setLoading(false);
    }
  }

  const handleEditorChange = (state) => {
    setMessage(state);
    const contentState = state.getCurrentContent();
    const plainText = contentState.getPlainText().trim();
    const hiddenInput = document.getElementById('message');
    hiddenInput.value = plainText;
  };

  const handleFormChange = (e, name) => {
    if (!changesModal.fields.find(f => f === name)) setChangesModal({ ...changesModal, fields: [...changesModal.fields, name] })

    if (name === "headline") {
      setForm({ ...form, headline: e })
    }
    else setForm({ ...form, [name]: e.target.value })
  }

  const handleReset = () => {
    setForm({
      name: "",
      service: "",
      email: "",
      phone: "",
      facebook: "",
      address: "",
      picture: defaultUser,
    });
  };

  const handleDelete = async () => {
    setLoadDelete(true)
    try {
      const { data } = await useGetApiClient.delete(`/admin/configuration/vendor/delete/${details?.id}`);
      if (data?.error) throw new Error(data?.error);
      // toast.success(data?.message);
      setOpenModal({ ...openModal, confirmDelete: false, delete: true })
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDelete(false);
    }
  }

  const handleSubmit = async ({ event, type }) => {
    event.preventDefault();

    try {
      setLoading(true);
      const params = {
        name: form.name,
        service: form.service,
        email: form.email ? form.email : null,
        phone: form.phone,
        facebook: form.facebook,
        address: form.address,
        logo: form.picture === defaultUser ? null : form.picture,
        headline: JSON.stringify(
          convertToRaw(message.getCurrentContent())
        ),
      };
      if (type === "add") {
        const { data: savedVendor } = await useGetApiClient.post('/admin/configuration/vendor/save',
          params
        );

        if (savedVendor?.error) throw new Error(savedVendor?.error);
        // toast.success(savedNotification?.message);
        setOpenModal({ ...openModal, save: true })
      } else {
        const { data: updatedVendor } = await useGetApiClient.put(`/admin/configuration/vendor/update/${details?.id}`,
          params
        );
        if (updatedVendor?.error) throw new Error(updatedVendor?.error);
        // toast.success(updatedNotification?.message);
        setOpenModal({ ...openModal, save: true })
      }
      setLoading(false);
      setChangesModal({ open: false, fields: [], targetTab: null })
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setLoading(false);
      console.log(error);

    }
  }

  useEffect(() => {
    if (details) {

      setForm({
        name: details?.name,
        service: details?.service,
        email: details?.email,
        phone: details?.phone,
        facebook: details?.facebook,
        address: details?.address,
        picture: details.logo?.includes(stageApiUrl)
          ? details.logo?.replaceAll(stageApiUrl, "")
          : details.logo?.includes(prodApiUrl)
            ? details.logo?.replaceAll(prodApiUrl, "")
            : defaultUser,
      });
      const messageValue = details?.headline &&
        isValidJson(details?.headline)
        ? EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(details?.headline)
          )
        )
        : EditorState.createEmpty()
      setMessage(messageValue);
      const contentState = messageValue.getCurrentContent();
      const plainText = contentState.getPlainText().trim();
      const hiddenInputMessage = document.getElementById('message');
      hiddenInputMessage.value = plainText;
    }
  }, [details])

  return (
    <>
      <Form
        onSubmit={(event) => handleSubmit({
          event,
          type: details ? "update" : "add"
        })}
      >
        {details?.id &&
          <Card className="config-tasks-list">
            <div className="config-tasks-list_header">
              <div className="d-flex flex-row justify-content-between w-100">
                <h5 className="text-primary">Vendor ID: {details.id}</h5>
              </div>
            </div>
          </Card>
        }
        <Card className="config-tasks-list">
          <div className="config-tasks-list_header">
            <div className="d-flex flex-row justify-content-between w-100">
              <h5 className="text-primary">Vendors</h5>
              <span className="table-add float-end mb-3 me-2">
                <div className="d-flex flex-row gap-2">
                  {!details?.id &&
                    <Button
                      className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                      onClick={handleReset}
                    >
                      <i className="material-symbols-outlined me-1 md-18">add</i>
                      Add Vendor
                    </Button>
                  }
                  {details?.id &&
                    <Button
                      className="btn btn-sm btn-danger-subtle d-flex align-items-center rounded-3"
                      style={{ border: "unset" }}
                      onClick={loadDelete ? () => { } : () => setOpenModal({ ...openModal, confirmDelete: true })}
                    >
                      {loadDelete
                        ? <SpinningDynamicCircleLoader color="var(--bs-danger)" />
                        : <i className="material-symbols-outlined md-18">delete</i>
                      }
                    </Button>
                  }
                </div>
              </span>
            </div>
          </div>

          <Card.Body className="pt-0">

            <Row className="form-group">
              <div className="col-sm-8">
                <Row className="form-group">
                  <label className="control-label col-sm-3 align-self-center mb-0" htmlFor="name">Name</label>
                  <div className="col-sm-8">
                    <input type="text" value={form.name} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
                <Row className="form-group">
                  <label className="control-label col-sm-3 align-self-center mb-0" htmlFor="service">Product or Service</label>
                  <div className="col-sm-8">
                    <input type="text" value={form.service} className="form-control" id="service" name="service" placeholder="-" onChange={(e) => handleFormChange(e, "service")} required />
                  </div>
                </Row>
                <Row className="form-group">
                  <label className="control-label col-sm-3 align-self-center mb-0" htmlFor="email">Email</label>
                  <div className="col-sm-8">
                    <input type="email" value={form.email} className="form-control" id="email" name="email" placeholder="-" onChange={(e) => handleFormChange(e, "email")} />
                  </div>
                </Row>
                <Row className="form-group">
                  <label className="control-label col-sm-3 align-self-center mb-0" htmlFor="phone">Phone</label>
                  <div className="col-sm-8">
                    <input type="text" value={form.phone} className="form-control" id="phone" name="phone" placeholder="-" onChange={(e) => handleFormChange(e, "phone")} />
                  </div>
                </Row>
                <Row className="form-group">
                  <label className="control-label col-sm-3 align-self-center mb-0" htmlFor="facebook">Facebook</label>
                  <div className="col-sm-8">
                    <input type="text" value={form.facebook} className="form-control" id="facebook" name="facebook" placeholder="-" onChange={(e) => handleFormChange(e, "facebook")} />
                  </div>
                </Row>
                <Row className="form-group">
                  <label className="control-label col-sm-3 align-self-center mb-0" htmlFor="address">Address</label>
                  <div className="col-sm-8">
                    <input type="text" value={form.address} className="form-control" id="address" name="address" placeholder="-" onChange={(e) => handleFormChange(e, "address")} />
                  </div>
                </Row>
              </div>
              <div className="col-sm-4 align-self-center">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div>
                    {!loading ? (
                      <img
                        src={form.picture}
                        className="img-fluid"
                        alt="user"
                        loading="lazy"
                      />
                    ) : <SpinningBlueCircleLoader />}
                  </div>
                  {/* <label htmlFor="logo">Logo</label> */}
                  <div className="d-flex flex-row gap-3 mt-4">
                    <div className="d-flex align-items-center">
                      <label htmlFor="picture" className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <i className="material-symbols-outlined">attach_file</i>
                      </label>
                      <input
                        id="picture"
                        name="picture"
                        type="file"
                        accept="image/*"
                        className="d-none"
                        onChange={(e) => handleAttachPicture(e)}
                      />
                    </div>
                    <button
                      type="button"
                      className="border-0 bg-transparent d-flex align-items-center text-danger"
                      onClick={() => setForm({ ...form, picture: defaultUser })}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </button>
                  </div>
                </div>
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-2 mb-0" htmlFor="message">Service Headline</label>
              <div className="col-sm-10">
                <div className="form-control">
                  <Editor
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "embedded",
                        "emoji",
                        "remove",
                        "history",
                      ],
                    }}
                    editorState={message}
                    onEditorStateChange={handleEditorChange}
                    editorStyle={{
                      minHeight: "400px",
                      overflow: "auto",
                    }}
                  />
                  <input
                    type="text"
                    id="message"
                    name="message"
                    style={{
                      opacity: 0,
                      pointerEvents: 'none',
                      height: 0,
                      width: 0,
                    }}
                    required
                  />
                </div>
              </div>
            </Row>
          </Card.Body>
        </Card>

        <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2 flex-row-reverse">
          <button type="submit" className="btn btn-primary w-50">
            {!loading ? 'Save' : <SpinningWhiteCircleLoader />}
          </button>
        </Form.Check>

      </Form>

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.delete}
        onHide={() => setOpenModal({ ...openModal, delete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Successfully deleted."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-danger w-100" onClick={() => {
            setOpenModal({ ...openModal, delete: false });
            setShowAddNew(false);
            setDetails(null);
          }}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.confirmDelete}
        onHide={() => setOpenModal({ ...openModal, confirmDelete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Are you sure you want to delete?"
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-danger w-50" onClick={handleDelete}>
            Yes
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setOpenModal({ ...openModal, confirmDelete: false })}>
            No
          </button>
        }
      />

    </>
  );
}

export default AddNewVendor;
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useGetApiClient } from "../../api/useApiClient";

const useGetDashboardHomeownersApi = () => {
  const [loading, setLoading] = useState(false);
  const [homeOwners, setHomeowners] = useState(null);
  const [badges, setBadges] = useState(null);

  useEffect(() => {
    const getHomeowners = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get("/admin/dashboard/get");
        if (data?.error) throw new Error(data?.error);
        setHomeowners(data);
        setBadges(data.badges);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };

    getHomeowners();
  }, []);

  return { loading, homeOwners, badges };
};

export default useGetDashboardHomeownersApi;

import { Card } from "react-bootstrap";
import * as moment from "moment";

const VotingDetails = ({ setShowDetails, details, tableDetails }) => {

  const columnHeader = ["Voter's Name", "Voted for", "Date Voted"];
  const columnHeaderTally = ["Candidate Name", "Total Votes"];

  const rows = (row) => {
    return (
      <>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.voters}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.voted}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.createdAt}</div></td>
      </>
    )
  }

  const rowsTally = (row) => {
    return (
      <>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.ownerFirstName} {row.ownerLastName}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.tally}</div></td>
      </>
    )
  }

  return (
    <Card className="config-tasks-list mt-3">
      <Card.Header>
        <div className="d-flex flex-row justify-content-between w-100">
          <div className="d-flex flex-row gap-4">
            <h5
              className="text-primary"
            >
              Voting
            </h5>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        <div style={{ backgroundColor: "#56bcfd", borderRadius: "5px 5px 0px 0px", padding: "10px", marginBottom: 10, color: "white" }}>{details?.name} - {moment(details?.startDate).format('MMMM DD, YYYY')}</div>

        <div style={{ backgroundColor: "#56bcfd", borderRadius: "5px 5px 0px 0px", padding: "10px", marginBottom: 10, color: "white" }}>Tally</div>
        <div className="table-responsive">
          <table className="config-tasks-list_table table">
            <thead>
              <tr>
                {columnHeaderTally.map((item, index) => (
                  <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(tableDetails?.candidates?.length > 0)
                ? tableDetails?.candidates?.map((item, idx) => (
                  <tr key={idx}>
                    {rowsTally(item)}
                  </tr>
                ))
                : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
              }
            </tbody>
          </table>
        </div>

        <div style={{ backgroundColor: "#56bcfd", borderRadius: "5px 5px 0px 0px", padding: "10px", marginBottom: 10, color: "white", marginTop: 20 }}>Voters</div>
        <div className="table-responsive">
          <table className="config-tasks-list_table table">
            <thead>
              <tr>
                {columnHeader.map((item, index) => (
                  <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(details?.voters?.length > 0)
                ? details?.voters?.map((item, idx) => (
                  <tr key={idx}>
                    {rows(item)}
                  </tr>
                ))
                : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
              }
            </tbody>
          </table>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <div>
              <span className="text-primary">Total Voters: </span>
              <span>{details?.totalVoters}</span>
            </div>
            <div>
              <span className="text-primary">Total Participated: </span>
              <span>{details?.totalParticipated}</span>

            </div>
            <button type="button" className="btn btn-primary" onClick={() => setShowDetails(false)}>
              Done
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default VotingDetails;
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import useGetRolesApi from "../../../../hooks/role/useGetRolesApi";
import {
  SpinningBlueCircleLoader,
  SpinningWhiteCircleLoader,
} from "../../../../components/common/loaders";
import { useEffect, useState } from "react";
import { stringToURL } from "../../../../utilities/helpers";
import { useGetApiClient } from "../../../../api/useApiClient";
import { toast } from "react-toastify";

const RolesList = () => {
  const [menuLoading, setMenuLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [menuDetails, setMenuDetails] = useState([]);
  const [selectedRole, setSelectedRole] = useState();

  // api calls
  const { loading, rolesMenus } = useGetRolesApi();

  const flattenData = (data) => {
    const result = [];

    const flatten = (items) => {
      items.forEach((item) => {
        const { subLevel, subLevel2, ...rest } = item;
        result.push(rest);

        if (subLevel && subLevel.length > 0) flatten(subLevel);
        if (subLevel2 && subLevel2.length > 0) flatten(subLevel2);
      });
    };

    flatten(data);
    return result;
  };

  useEffect(() => {
    if (rolesMenus && selectedRole) {
      const getList = async () => {
        setMenuLoading(true);
        try {
          const { data } = await useGetApiClient.get(
            `/admin/role/get/${selectedRole}`
          );
          if (data?.error) throw new Error(data?.error);
          setMenuDetails(flattenData(data) || []);
        } catch (error) {
          toast.error(error?.message);
        } finally {
          setMenuLoading(false);
        }
      };

      getList();
    }
  }, [rolesMenus, selectedRole]);

  const handleSelectRoles = ({ event, id, parent }) => {
    let permission = event.target.value;

    setMenuDetails((prevMenuDetails) => {
      let updatedMenuDetails = [...prevMenuDetails];
      let roleIndex = updatedMenuDetails.findIndex((role) => role?.id === id);

      if (roleIndex > -1) {
        // If role exists, update permissions
        let role = updatedMenuDetails[roleIndex];
        let updatedPermissions = role.permission?.permissions.includes(
          permission
        )
          ? role.permission.permissions.filter((perm) => perm !== permission) // Remove permission
          : [...role.permission.permissions, permission]; // Add permission

        updatedMenuDetails[roleIndex] = {
          ...role,
          permission: {
            id: role.id,
            permissions: updatedPermissions,
          },
        };

        // Remove role if no permissions remain
        if (updatedPermissions.length === 0) {
          updatedMenuDetails.splice(roleIndex, 1);
        }
      } else {
        // If role doesn't exist, add it
        updatedMenuDetails.push({
          id,
          permission: { id, permissions: [permission] },
        });
      }

      // Handle parent
      if (parent) {
        let parentIndex = updatedMenuDetails.findIndex(
          (role) => role?.id === parent?.id
        );

        if (parentIndex > -1) {
          // Update existing parent
          let parentRole = updatedMenuDetails[parentIndex];
          updatedMenuDetails[parentIndex] = {
            ...parentRole,
            permission: {
              id: parent.id,
              permissions: [
                "canCreate",
                "canRead",
                "canUpdate",
                "canDelete",
                "canGenerate",
              ],
            },
          };
        } else {
          // Add parent if it doesn't exist
          updatedMenuDetails.push({
            id: parent.id,
            permission: {
              id: parent.id,
              permissions: [
                "canCreate",
                "canRead",
                "canUpdate",
                "canDelete",
                "canGenerate",
              ],
            },
          });
        }

        // Check if all children of the parent are absent from updatedMenuDetails
        const allChildrenAbsent = parent?.subLevel?.every(
          (child) => !updatedMenuDetails.some((role) => role.id === child.id)
        );

        if (allChildrenAbsent) {
          // Remove the parent if all children are absent
          updatedMenuDetails = updatedMenuDetails.filter(
            (role) => role.id !== parent.id
          );
        }
      }
      console.log(updatedMenuDetails);
      return updatedMenuDetails;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedRole) {
      toast.error("Role is required. Select a role before saving.");
      return;
    }

    try {
      setSaving(true);

      const params = menuDetails?.map((menu) => ({
        menuId: menu.id,
        permissions: menu?.permission?.permissions,
      }));

      const { data } = await useGetApiClient.put(
        `/admin/role/update/${selectedRole}`,
        params
      );
      if (data?.error) throw new Error(data?.error);
      toast.success(data?.message);
      setSaving(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setSaving(false);
    }
  };

  if (loading) return <SpinningBlueCircleLoader />;
  return (
    <Card className="config-tasks-list">
      <div className="config-tasks-list_header">
        <div className="d-flex flex-row justify-content-between w-100">
          <h5 className="text-primary">Roles</h5>
        </div>
      </div>
      <Card.Body className="pt-0">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6} className="d-flex flex-row gap-4">
              {rolesMenus?.roles
                ?.filter(
                  (role) => role.sub === null && role.name !== "HOMEOWNER"
                )
                ?.map((role, idx) => (
                  <div
                    key={idx}
                    className="form-check col-sm px-5 py-3 border border-light d-flex justify-content-center align-items-center rounded-3"
                  >
                    <input
                      className="border border-primary"
                      type="checkbox"
                      name={`role_${idx}`}
                      checked={selectedRole === role?.id}
                      // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                      value={role?.id}
                      onChange={(e) => setSelectedRole(role.id)}
                      id={`role_${idx}`}
                    />
                    <label
                      className="form-check-label ms-2 d-flex flex-column gap-2 align-items-center"
                      htmlFor="role"
                    >
                      <svg
                        width="29"
                        height="30"
                        viewBox="0 0 29 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2342_8352)">
                          <path
                            d="M6.20898 7.5C6.20898 9.48912 7.05184 11.3968 8.55213 12.8033C10.0524 14.2098 12.0873 15 14.209 15C16.3307 15 18.3655 14.2098 19.8658 12.8033C21.3661 11.3968 22.209 9.48912 22.209 7.5C22.209 5.51088 21.3661 3.60322 19.8658 2.1967C18.3655 0.790176 16.3307 0 14.209 0C12.0873 0 10.0524 0.790176 8.55213 2.1967C7.05184 3.60322 6.20898 5.51088 6.20898 7.5ZM12.1152 19.2305L13.2777 21.0469L11.1965 28.3066L8.94648 19.6992C8.82148 19.2246 8.33398 18.9141 7.82773 19.0371C3.45273 20.0625 0.208984 23.7773 0.208984 28.2012C0.208984 29.1973 1.07148 30 2.12773 30H10.359C10.359 30 10.359 30 10.3652 30H10.709H17.709H18.0527C18.0527 30 18.0527 30 18.059 30H26.2902C27.3527 30 28.209 29.1914 28.209 28.2012C28.209 23.7773 24.9652 20.0625 20.5902 19.0371C20.084 18.9199 19.5965 19.2305 19.4715 19.6992L17.2215 28.3066L15.1402 21.0469L16.3027 19.2305C16.7027 18.6035 16.2215 17.8125 15.4465 17.8125H14.209H12.9777C12.2027 17.8125 11.7215 18.6094 12.1215 19.2305H12.1152Z"
                            fill="#6C757D"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2342_8352">
                            <rect
                              width="28"
                              height="30"
                              fill="white"
                              transform="translate(0.208984)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>{role?.name}</span>
                    </label>
                  </div>
                ))}
            </Col>
            <Col lg={6} className="d-flex flex-row gap-4 align-items-center">
              <label
                className="control-label mb-0 col-sm-3"
                htmlFor="subCommittee"
              >
                Sub-committee
              </label>
              <select
                className="form-select col-sm"
                id="subCommittee"
                name="subCommittee"
                onChange={(e) => setSelectedRole(e.target.value)}
                value={
                  rolesMenus?.roles
                    ?.filter((role) => role.sub !== null)
                    ?.find((role) => role.id === Number(selectedRole))
                    ? selectedRole
                    : ""
                }
                // defaultValue={(e) => homeownerDetails?.roles?.includes(e.target.value)}
              >
                <option>-</option>
                {rolesMenus?.roles
                  ?.filter((role) => role.sub !== null)
                  ?.map((role, idx) => (
                    <option key={idx} value={role?.id}>
                      {role?.sub}
                    </option>
                  ))}
              </select>
            </Col>
          </Row>
          {menuLoading ? (
            <Row className="pt-4">
              <SpinningBlueCircleLoader />
            </Row>
          ) : (
            <>
              <Row className="pt-4">
                <div className="d-flex flex-row justify-content-between w-100 pb-4">
                  <h5 className="text-primary">Admin menus</h5>
                </div>
                {rolesMenus?.adminMenus?.map((adminMenu, idx) => (
                  <Accordion id={`adminMenu-${idx}`} defaultActiveKey={idx}>
                    <Accordion.Item
                      key={idx}
                      className="mb-3 bg-primary"
                      eventKey={idx}
                    >
                      <Accordion.Header id={stringToURL(adminMenu?.name)}>
                        {adminMenu?.name}
                      </Accordion.Header>
                      <Accordion.Body>
                        {adminMenu?.subLevel?.length !== 0 ? (
                          adminMenu?.subLevel?.map((subMenu, idx) => (
                            <div key={idx} className="row gap-4 pb-4">
                              <div className="col-sm">{subMenu?.name}</div>
                              {rolesMenus?.permissions?.map(
                                (permission, idx) => (
                                  <div
                                    key={idx}
                                    className="form-check col-sm d-flex justify-content-center align-items-center"
                                  >
                                    <label
                                      className="form-check-label me-2"
                                      htmlFor="role"
                                    >
                                      {permission === "canRead"
                                        ? "View"
                                        : permission === "canCreate"
                                        ? "Create"
                                        : permission === "canDelete"
                                        ? "Delete"
                                        : permission === "canUpdate"
                                        ? "Update"
                                        : "Generate"}
                                    </label>
                                    <input
                                      className="border border-primary"
                                      type="checkbox"
                                      name={`${permission}_${idx}`}
                                      checked={
                                        menuDetails?.find(
                                          (menuDetail) =>
                                            menuDetail?.id === subMenu?.id
                                        )
                                          ? menuDetails
                                              ?.find(
                                                (menuDetail) =>
                                                  menuDetail?.id === subMenu?.id
                                              )
                                              ?.permission?.permissions?.includes(
                                                permission
                                              )
                                          : false
                                      }
                                      // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                                      value={permission}
                                      onChange={(event) =>
                                        handleSelectRoles({
                                          event,
                                          id: subMenu?.id,
                                          parent: adminMenu,
                                        })
                                      }
                                      id={`${permission}_${idx}`}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          ))
                        ) : (
                          <div key={idx} className="row gap-4 pb-4">
                            <div className="col-sm">{adminMenu?.name}</div>
                            {rolesMenus?.permissions?.map((permission, idx) => (
                              <div
                                key={idx}
                                className="form-check col-sm d-flex justify-content-center align-items-center"
                              >
                                <label
                                  className="form-check-label me-2"
                                  htmlFor="role"
                                >
                                  {permission === "canRead"
                                    ? "View"
                                    : permission === "canCreate"
                                    ? "Create"
                                    : permission === "canDelete"
                                    ? "Delete"
                                    : permission === "canUpdate"
                                    ? "Update"
                                    : "Generate"}
                                </label>
                                <input
                                  className="border border-primary"
                                  type="checkbox"
                                  name={`${permission}_${idx}`}
                                  checked={
                                    menuDetails?.find(
                                      (menuDetail) =>
                                        menuDetail?.id === adminMenu?.id
                                    )
                                      ? menuDetails
                                          ?.find(
                                            (menuDetail) =>
                                              menuDetail?.id === adminMenu?.id
                                          )
                                          ?.permission?.permissions?.includes(
                                            permission
                                          )
                                      : false
                                  }
                                  // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                                  value={permission}
                                  onChange={(event) =>
                                    handleSelectRoles({
                                      event,
                                      id: adminMenu?.id,
                                      parent: null,
                                    })
                                  }
                                  id={`${permission}_${idx}`}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Row>
              {/* <Row className="pt-4">
                <div className="d-flex flex-row justify-content-between w-100 pb-4">
                  <h5 className="text-primary">
                    Homeowner menus
                  </h5>
                </div>
                {rolesMenus?.homeownerMenus?.map((homeownerMenu, idx) => (
                  <Accordion id={`homeownerMenu-${idx}`} defaultActiveKey={idx}>
                    <Accordion.Item key={idx} className="mb-3 bg-primary" eventKey={idx}>
                      <Accordion.Header id={stringToURL(homeownerMenu?.name)}>
                        {homeownerMenu?.name}
                      </Accordion.Header>
                      <Accordion.Body>
                        {homeownerMenu?.subLevel?.length !== 0 ? (
                          homeownerMenu?.subLevel?.map((subMenu, idx) => (
                            <div key={idx} className="row gap-4 pb-4">
                              <div className="col-sm">{subMenu?.name}</div>
                              {rolesMenus?.permissions?.map((permission, idx) => (
                                <div key={idx} className="form-check col-sm d-flex justify-content-center align-items-center">
                                  <label className="form-check-label me-2" htmlFor="role">
                                    {
                                      permission === "canRead" ? 'View'
                                        : permission === "canCreate" ? 'Create'
                                          : permission === "canDelete" ? 'Delete'
                                            : permission === "canUpdate" ? 'Update'
                                              : 'Generate'
                                    }
                                  </label>
                                  <input
                                    className="border border-primary"
                                    type="checkbox"
                                    name={`${permission}_${idx}`}
                                    checked={menuDetails?.find(menuDetails => menuDetails?.id === subMenu?.id) ? menuDetails?.find(menuDetails => menuDetails?.id === subMenu?.id)?.permission?.permissions?.includes(permission) : false}
                                    value={permission}
                                    onChange={(event) => handleSelectRoles({ event, id: subMenu?.id, parent: homeownerMenu })}
                                    id={`${permission}_${idx}`}
                                  />
                                </div>
                              ))}
                            </div>
                          ))
                        ) : (
                          <div key={idx} className="row gap-4 pb-4">
                            <div className="col-sm">{homeownerMenu?.name}</div>
                            {rolesMenus?.permissions?.map((permission, idx) => (
                              <div key={idx} className="form-check col-sm d-flex justify-content-center align-items-center">
                                <label className="form-check-label me-2" htmlFor="role">
                                  {
                                    permission === "canRead" ? 'View'
                                      : permission === "canCreate" ? 'Create'
                                        : permission === "canDelete" ? 'Delete'
                                          : permission === "canUpdate" ? 'Update'
                                            : 'Generate'
                                  }
                                </label>
                                <input
                                  className="border border-primary"
                                  type="checkbox"
                                  name={`${permission}_${idx}`}
                                  checked={menuDetails?.find(menuDetails => menuDetails?.id === homeownerMenu?.id) ? menuDetails?.find(menuDetails => menuDetails?.id === homeownerMenu?.id)?.permission?.permissions?.includes(permission) : false}
                                  // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                                  value={permission}
                                  onChange={(event) => handleSelectRoles({ event, id: homeownerMenu?.id, parent: null })}
                                  id={`${permission}_${idx}`}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Row> */}
              <div className="d-flex align-items-center justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary w-50"
                  disabled={loading || saving || menuLoading}
                >
                  {!loading && !saving && !menuLoading ? (
                    "Save"
                  ) : (
                    <SpinningWhiteCircleLoader />
                  )}
                </button>
              </div>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default RolesList;

import React from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";

const BadgesComponent = ({ setActiveTab, badges }) => {
  return (
    <Card>
      <Card.Body>
        <ul className="m-0 p-0 job-classification list-inline d-flex flex-column gap-3">
          <Link
            to={"/user-management?tab=homeowners"}
            onClick={() => setActiveTab("homeowners")}
          >
            <li
              className="d-flex flex-row align-items-center rounded-3 p-3"
              style={{ backgroundColor: "#F79F85" }}
            >
              <span className="text-white fw-bold w-50">HOA Directory</span>
              <span className="text-white fw-bold">
                {badges?.homeowner ?? 0}
              </span>
            </li>
          </Link>
          <Link
            to={"/system-configuration?tab=arc"}
            onClick={() => setActiveTab("arc")}
          >
            <li
              className="d-flex flex-row align-items-center rounded-3 p-3"
              style={{ backgroundColor: "#F79F85" }}
            >
              <span className="text-white fw-bold w-50">Requests</span>
              <span className="text-white fw-bold">{badges?.request ?? 0}</span>
            </li>
          </Link>
          <Link
            to={"/system-configuration?tab=billing"}
            onClick={() => setActiveTab("billing")}
          >
            <li
              className="d-flex flex-row align-items-center rounded-3 p-3"
              style={{ backgroundColor: "#F79F85" }}
            >
              <span className="text-white fw-bold w-50">Delinquent</span>
              <span className="text-white fw-bold">
                {badges?.deliquent ?? 0}
              </span>
            </li>
          </Link>
          <Link
            to={"/system-configuration?tab=violation"}
            onClick={() => setActiveTab("violation")}
          >
            <li
              className="d-flex flex-row align-items-center rounded-3 p-3"
              style={{ backgroundColor: "#F79F85" }}
            >
              <span className="text-white fw-bold w-50">Violations</span>
              <span className="text-white fw-bold">
                {badges?.violation ?? 0}
              </span>
            </li>
          </Link>
        </ul>
      </Card.Body>
    </Card>
  );
};

export default BadgesComponent;

import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SpinningBlueCircleLoader, SpinningDynamicCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { useGetVariablesApi } from "../../../hooks/system-configuration/useGetNotificationListApi";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import { isValidJson } from "../../../utilities/helpers";
import ConfirmModal from "../../../components/common/ConfirmModal";

const customStyles = {
  control: (base, state) => ({
    ...base,
    height: "auto",
    border: state.isFocused
      ? "var(--bs-border-width) solid var(--bs-primary)"
      : "var(--bs-border-width) solid var(--bs-border-color)",
    width: "100%",
    fontSize: "0.875rem",
    fontWeight: "400",
    borderRadius: "var(--bs-border-radius)",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: "0.575rem 0.75rem",
  }),
  input: (base, state) => ({
    ...base,
    margin: 0,
    padding: 0,
    color: "var(--bs-body-color)",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    background: "transparent",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: "var(--bs-body-color)",
    margin: 0,
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 2,
  }),
};

const AddNewNotification = ({ setShowAddNewNotification, notificationDetails, setNotificationDetails }) => {

  const [loading, setLoading] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalVariableShow, setModalVariableShow] = useState(false);
  const [message, setMessage] = useState(EditorState.createEmpty());
  const [notifId, setNotifId] = useState();
  const [description, setDescription] = useState();
  const [from, setFrom] = useState("admin@beneventoeast.com");
  const [toValue, setToValue] = useState();
  const [toArray, setToArray] = useState([]);
  const [ccValue, setCCValue] = useState();
  const [ccArray, setCCArray] = useState([]);
  const [bccValue, setBCCValue] = useState();
  const [bccArray, setBCCArray] = useState([]);
  const [mail, setMail] = useState({
    mailer: "smtp.gmail.com",
    host: null,
    port: "587",
    username: "admin@beneventoeast.com",
    password: null,
    encryption: "TSL"
  })
  const [subject, setSubject] = useState();
  const [submitType, setSubmitType] = useState(1);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });

  const { loading: variablesLoading, variablesList } = useGetVariablesApi();

  const handleInputChange = (value, setValue) => {
    setValue(value);
  }

  const NoResultComponent = (props) => {
    var isValid = false;
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    isValid = emailPattern.test(props.value);

    return (
      <>
        {isValid &&
          <div style={{ padding: "0.575rem" }}>
            <p
              style={{
                fontSize: "0.875rem",
                fontWeight: "700",
                color: "var(--bs-primary)",
                cursor: "pointer",
              }}
              onClick={props.handleSetNoOptions}
            >
              {props.value}
            </p>
          </div>
        }
      </>
    )
  };

  const handlePreventSpace = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  const handlePreventPasteSpace = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text').replace(/\s+/g, '');
    event.target.value = pastedText;
  }

  const handleEditorChange = (state) => {
    setMessage(state);
    const contentState = state.getCurrentContent();
    const plainText = contentState.getPlainText().trim();
    const hiddenInput = document.getElementById('message');
    hiddenInput.value = plainText;
  };

  const handleSetNoOptions = (valueArray, setValueArray, inputValue, setValue, hiddenId) => {
    setValueArray(valueArray.length > 0 ? [...valueArray, {
      "value": inputValue,
      "label": inputValue,
    }] : [{
      "value": inputValue,
      "label": inputValue,
    }]);
    if (hiddenId) {
      const hiddenInput = document.getElementById(hiddenId);
      hiddenInput.value = valueArray.length > 0 ? [...valueArray, {
        "value": inputValue,
        "label": inputValue,
      }] : [{
        "value": inputValue,
        "label": inputValue,
      }];
    }
    setValue("");
  };

  const handleReset = () => {
    setNotificationDetails(null);
    setNotifId("");
    setDescription("");
    setFrom("admin@beneventoeast.com");
    setToArray([]);
    setCCArray([]);
    setBCCArray([]);
    setSubject("");
    setMessage(EditorState.createEmpty());
    setMail({
      mailer: "smtp.gmail.com",
      host: null,
      port: "587",
      username: "admin@beneventoeast.com",
      password: null,
      encryption: "TSL"
    })
    const hiddenInputMail = document.getElementById('mail');
    hiddenInputMail.value = null;
  };

  const handleDelete = async () => {
    setLoadDelete(true)
    try {
      const { data } = await useGetApiClient.delete(`/admin/configuration/notification/delete/${notificationDetails?.id}`);
      if (data?.error) throw new Error(data?.error);
      // toast.success(data?.message);
      setOpenModal({ ...openModal, confirmDelete: false, delete: true })
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDelete(false);
    }
  }

  const handleSubmit = async ({ event, type }) => {
    event.preventDefault();

    try {
      setLoading(true);
      const params = {
        notificationId: event.target.notifId.value,
        description: event.target.description.value,
        from: event.target.from.value,
        to: toArray.map(item => item.value),
        cc: ccArray.map(item => item.value),
        bcc: bccArray.map(item => item.value),
        subject: event.target.subject.value,
        body: JSON.stringify(
          convertToRaw(message.getCurrentContent())
        ),
        mail: {
          mailer: mail.mailer,
          host: mail.host,
          port: mail.port,
          username: mail.username,
          password: mail.password,
          encryption: mail.encryption,
        }
      };
      if (submitType === 1) {
        if (type === "add") {
          const { data: savedNotification } = await useGetApiClient.post('/admin/configuration/notification/save',
            params
          );

          if (savedNotification?.error) throw new Error(savedNotification?.error);
          // toast.success(savedNotification?.message);
          setOpenModal({ ...openModal, save: true })

        } else {
          const { data: updatedNotification } = await useGetApiClient.put(`admin/configuration/notification/update/${notificationDetails?.id}`,
            params
          );
          if (updatedNotification?.error) throw new Error(updatedNotification?.error);
          // toast.success(updatedNotification?.message);
          setOpenModal({ ...openModal, save: true })
        }
      } else if (submitType === 0) {
        const { data: testNotification } = await useGetApiClient.post('/admin/configuration/notification/test-email',
          params
        );

        if (testNotification?.error) throw new Error(testNotification?.error);
        toast.success(testNotification?.message);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setLoading(false);
    }
  }

  const MailModal = (props) => {

    const handleMailSubmit = async (event) => {
      event.preventDefault();

      const value = {
        mailer: event.target.mailer.value,
        host: event.target.host.value,
        port: event.target.port.value,
        username: event.target.username.value,
        password: event.target.password.value,
        encryption: event.target.encryption.value,
      };

      setMail(value)
      const hiddenInput = document.getElementById('mail');
      hiddenInput.value = value;
      setModalShow(false)
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form
          onSubmit={handleMailSubmit}
        >
          <Modal.Body>
            <Row className="form-group">
              <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="mailer">MAIL_MAILER</label>
              <div className="col-sm-8">
                <input type="text" defaultValue={mail.mailer} className="form-control" id="mailer" name="mailer" placeholder="-" required />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="host">MAIL_HOST</label>
              <div className="col-sm-8">
                <input type="text" defaultValue={mail.host} className="form-control" id="host" name="host" placeholder="-" required />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="port">MAIL_PORT</label>
              <div className="col-sm-8">
                <input type="text" defaultValue={mail.port} className="form-control" id="port" name="port" placeholder="-" required />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="username">MAIL_USERNAME</label>
              <div className="col-sm-8">
                <input type="text" defaultValue={mail.username} className="form-control" id="username" name="username" placeholder="-" required />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="password">MAIL_PASSWORD</label>
              <div className="col-sm-8">
                <input type="text" defaultValue={mail.password} className="form-control" id="password" name="password" placeholder="-" required />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="encryption">MAIL_ENCRYPTION</label>
              <div className="col-sm-8">
                <input type="text" defaultValue={mail.encryption} className="form-control" id="encryption" name="encryption" placeholder="-" required />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2">
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Form.Check>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  const VariablesModal = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-primary">Variables</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "80vh", overflow: "auto" }}>
          {variablesLoading
            ? <SpinningBlueCircleLoader />
            : <>
              {variablesList.map((item, index) =>
              (
                <div key={index}>{`{{${item}}}`}</div>
              )
              )}
            </>
          }
        </Modal.Body>
      </Modal>
    );
  }

  useEffect(() => {
    if (notificationDetails) {

      setNotifId(notificationDetails?.notificationId);
      setDescription(notificationDetails?.description);
      setFrom(notificationDetails?.from);
      const mailValue = {
        mailer: notificationDetails?.mail?.mailer,
        host: notificationDetails?.mail?.host,
        port: notificationDetails?.mail?.port,
        username: notificationDetails?.mail?.username,
        password: notificationDetails?.mail?.password,
        encryption: notificationDetails?.mail?.encryption
      }
      setMail(mailValue);
      const hiddenInputMail = document.getElementById('mail');
      hiddenInputMail.value = mailValue;
      const messageValue = notificationDetails?.body &&
        isValidJson(notificationDetails?.body)
        ? EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(notificationDetails?.body)
          )
        )
        : EditorState.createEmpty()
      setMessage(messageValue);
      const contentState = messageValue.getCurrentContent();
      const plainText = contentState.getPlainText().trim();
      const hiddenInputMessage = document.getElementById('message');
      hiddenInputMessage.value = plainText;
      setToArray(notificationDetails?.to?.map(item => ({ label: item, value: item })));
      setCCArray(notificationDetails?.cc?.map(item => ({ label: item, value: item })));
      setBCCArray(notificationDetails?.bcc?.map(item => ({ label: item, value: item })));
      setSubject(notificationDetails?.subject);
    }
  }, [notificationDetails])

  return (
    <>
      <Form
        onSubmit={(event) => handleSubmit({
          event,
          type: notificationDetails ? "update" : "add"
        })}
      >
        <Card className="config-tasks-list">
          <div className="config-tasks-list_header">
            <div className="d-flex flex-row justify-content-between w-100">
              <h5 className="text-primary">Notifications</h5>
              <span className="table-add float-end mb-3 me-2">
                <div className="d-flex flex-row gap-2">
                  <Button
                    className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                    onClick={handleReset}
                  >
                    <i className="material-symbols-outlined me-1 md-18">add</i>
                    Add New Notification
                  </Button>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Variables help</Tooltip>}>
                    <Button
                      onClick={() => setModalVariableShow(true)}
                      className="btn btn-sm d-flex align-items-center rounded-3"
                    >
                      <i className="material-symbols-outlined md-18">help</i>
                    </Button>
                  </OverlayTrigger>
                  {notificationDetails?.canDelete &&
                    <Button
                      className="btn btn-sm btn-danger-subtle d-flex align-items-center rounded-3"
                      style={{ border: "unset" }}
                      onClick={loadDelete ? () => { } : () => setOpenModal({ ...openModal, confirmDelete: true })}
                    >
                      {loadDelete
                        ? <SpinningDynamicCircleLoader color="var(--bs-danger)" />
                        : <i className="material-symbols-outlined md-18">delete</i>
                      }
                    </Button>
                  }
                </div>
              </span>
            </div>
          </div>

          <Card.Body className="pt-0">
            <Row className="form-group">
              <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="">Notification ID</label>
              <div className="col-sm-4">
                <input type="text" value={notifId} onChange={(e) => handleInputChange(e.target.value, setNotifId)} className="form-control" id="notifId" name="notifId" placeholder="-" required onKeyDown={handlePreventSpace} onPaste={handlePreventPasteSpace} />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-2 mb-0" htmlFor="description">Description</label>
              <div className="col-sm-4">
                <textarea type="text" value={description} onChange={(e) => handleInputChange(e.target.value, setDescription)} className="form-control" id="description" name="description" placeholder="-" required />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="from">From</label>
              <div className="col-sm-4">
                <input type="text" value={from} onChange={(e) => handleInputChange(e.target.value, setFrom)} className="form-control" id="from" name="from" placeholder="-" required />
              </div>
              <div className="col-sm-1 d-flex align-items-center">
                <Col>
                  <Button
                    onClick={() => setModalShow(true)}
                    className="btn btn-sm d-flex align-items-center rounded-3"
                  >
                    <i className="material-symbols-outlined md-18">more_horiz</i>
                  </Button>
                  <input
                    type="text"
                    id="mail"
                    name="mail"
                    style={{
                      marginLeft: "15px",
                      opacity: 0,
                      pointerEvents: 'none',
                      height: 0,
                      width: 0,
                    }}
                    required
                  />
                </Col>
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="to">To</label>
              <div className="col-sm-4">
                <Select
                  isClearable={false}
                  isSearchable={true}
                  inputValue={toValue}
                  styles={customStyles}
                  components={{
                    Menu: (props) => {
                      var isValid = false;
                      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                      isValid = emailPattern.test(toValue);
                      if (isValid) return (
                        <>
                          <components.Menu {...props} />
                        </>
                      );
                    },
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    NoOptionsMessage: (props) => {
                      if (toValue?.length === 0 || props?.selectProps?.inputValue?.length === 0) return null;
                      return (
                        <NoResultComponent
                          value={toValue}
                          handleSetNoOptions={() => handleSetNoOptions(toArray, setToArray, toValue, setToValue, "to")}
                          {...props}
                        />
                      );
                    },
                  }}
                  onInputChange={(e) => setToValue(e)}
                  onChange={(e) => setToArray(e)}
                  placeholder="-"
                  value={toArray}
                  isMulti
                  closeMenuOnSelect={true}
                />
                <input
                  type="text"
                  id="to"
                  name="to"
                  value={toArray}
                  style={{
                    opacity: 0,
                    pointerEvents: 'none',
                    height: 0,
                    width: 0,
                  }}
                  required
                />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="cc">CC</label>
              <div className="col-sm-4">
                <Select
                  isClearable={false}
                  isSearchable={true}
                  inputValue={ccValue}
                  styles={customStyles}
                  components={{
                    Menu: (props) => {
                      var isValid = false;
                      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                      isValid = emailPattern.test(ccValue);
                      if (isValid) return (
                        <>
                          <components.Menu {...props} />
                        </>
                      );
                    },
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    NoOptionsMessage: (props) => {
                      if (ccValue?.length === 0 || props?.selectProps?.inputValue?.length === 0) return null;
                      return (
                        <NoResultComponent
                          value={ccValue}
                          handleSetNoOptions={() => handleSetNoOptions(ccArray, setCCArray, ccValue, setCCValue)}
                          {...props}
                        />
                      );
                    },
                  }}
                  onInputChange={(e) => setCCValue(e)}
                  onChange={(e) => setCCArray(e)}
                  placeholder="-"
                  value={ccArray}
                  isMulti
                  closeMenuOnSelect={true}
                />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="bcc">BCC</label>
              <div className="col-sm-4">
                <Select
                  isClearable={false}
                  isSearchable={true}
                  inputValue={bccValue}
                  styles={customStyles}
                  components={{
                    Menu: (props) => {
                      var isValid = false;
                      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                      isValid = emailPattern.test(bccValue);
                      if (isValid) return (
                        <>
                          <components.Menu {...props} />
                        </>
                      );
                    },
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    NoOptionsMessage: (props) => {
                      if (bccValue?.length === 0 || props?.selectProps?.inputValue?.length === 0) return null;
                      return (
                        <NoResultComponent
                          value={bccValue}
                          handleSetNoOptions={() => handleSetNoOptions(bccArray, setBCCArray, bccValue, setBCCValue)}
                          {...props}
                        />
                      );
                    },
                  }}
                  onInputChange={(e) => setBCCValue(e)}
                  onChange={(e) => setBCCArray(e)}
                  placeholder="-"
                  value={bccArray}
                  isMulti
                  closeMenuOnSelect={true}
                />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="subject">Subject</label>
              <div className="col-sm-4">
                <input type="text" value={subject} onChange={(e) => handleInputChange(e.target.value, setSubject)} className="form-control" id="subject" name="subject" placeholder="-" required />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-2 mb-0" htmlFor="message">Message Body</label>
              <div className="col-sm-10">
                <div className="form-control">
                  <Editor
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "embedded",
                        "emoji",
                        "remove",
                        "history",
                      ],
                    }}
                    editorState={message}
                    onEditorStateChange={handleEditorChange}
                  />
                  <input
                    type="text"
                    id="message"
                    name="message"
                    style={{
                      opacity: 0,
                      pointerEvents: 'none',
                      height: 0,
                      width: 0,
                    }}
                    required
                  />
                </div>
              </div>
            </Row>
          </Card.Body>
        </Card>

        <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2 flex-row-reverse">
          <button type="submit" autoFocus className="btn btn-primary w-50" onClick={() => setSubmitType(1)}>
            {!loading ? 'Save' : <SpinningWhiteCircleLoader />}
          </button>
          <button type="submit" className="btn btn-success w-25" onClick={() => setSubmitType(0)}>
            {!loading ? 'Test Email' : <SpinningWhiteCircleLoader />}
          </button>
        </Form.Check>

      </Form>

      <MailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <VariablesModal
        show={modalVariableShow}
        onHide={() => setModalVariableShow(false)}
      />

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.delete}
        onHide={() => setOpenModal({ ...openModal, delete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Successfully deleted."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-danger w-100" onClick={() => {
            setOpenModal({ ...openModal, delete: false });
            setShowAddNewNotification(false);
            setNotificationDetails(null);
          }}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.confirmDelete}
        onHide={() => setOpenModal({ ...openModal, confirmDelete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Are you sure you want to delete?"
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-danger w-50" onClick={handleDelete}>
            Yes
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setOpenModal({ ...openModal, confirmDelete: false })}>
            No
          </button>
        }
      />

    </>
  );
}

export default AddNewNotification;
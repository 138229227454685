import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../../../components/common/loaders";
import defaultUser from "../../../../assets/images/user/default_user-gray.png";

const AddNewUser = ({
  open = false,
  onHide,
}) => {

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: "",
    contact_number: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    photo: defaultUser,
  })

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  const handleFormOnChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setForm({ ...form, [name]: value });
  }

  const handleAttachPicture = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    setForm({ ...form, photo: defaultUser });
    
    const inputfile = document.getElementsByName("picture")?.[0];
    inputfile.value = "";
  }

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        show={open}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <button className="btn btn-primary position-absolute d-flex align-items-center" style={{ borderRadius: "30px 0px 0px 30px", left: "-41px", padding: "7px" }} onClick={onHide}>
            <i className="material-symbols-outlined">close</i>
          </button>
          <h5 className="text-primary mb-5">Add User</h5>
          <Row>
            <div className="col-sm-8">
              <Row className="form-group">
                <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="email">Email</label>
                <div className="col-sm-8 mb-3">
                  <input type="text" value={form.email} className="form-control" id="email" name="email" placeholder="-" onChange={handleFormOnChange} required />
                </div>

                <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="contact_number">Contact Number</label>
                <div className="col-sm-8 mb-3">
                  <input type="text" value={form.contact_number} className="form-control" id="contact_number" name="contact_number" placeholder="-" onChange={handleFormOnChange} required />
                </div>

                <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="address">Address</label>
                <div className="col-sm-8 mb-3">
                  <input type="text" value={form.address} className="form-control" id="address" name="address" placeholder="-" onChange={handleFormOnChange} required />
                </div>

                <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="city">City</label>
                <div className="col-sm-8 mb-3">
                  <input type="text" value={form.city} className="form-control" id="city" name="city" placeholder="-" onChange={handleFormOnChange} required />
                </div>

                <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="zip">ZIP</label>
                <div className="col-sm-5 mb-3">
                  <input type="text" value={form.zip} className="form-control" id="zip" name="zip" placeholder="-" onChange={handleFormOnChange} required />
                </div>
              </Row>
            </div>
            <div className="col-sm-4 align-self-center">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div>
                  {!loading ? (
                    <img
                      src={form.photo}
                      className="img-fluid"
                      alt="user"
                      loading="lazy"
                    />
                  ) : <SpinningBlueCircleLoader />}
                </div>
                <div className="d-flex flex-row gap-3 mt-4">
                  <div className="d-flex align-items-center">
                    <label htmlFor="picture" className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                      <i className="material-symbols-outlined">attach_file</i>
                    </label>
                    <input
                      id="picture"
                      name="picture"
                      type="file"
                      accept="image/*"
                      className="d-none"
                      onChange={(e) => handleAttachPicture(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="border-0 bg-transparent d-flex align-items-center text-danger"
                    onClick={() => setForm({ ...form, photo: defaultUser })}
                  >
                    <i className="material-symbols-outlined">delete</i>
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter style={{ border: "unset" }}>
          <button className="btn btn-primary w-100">
            Submit
          </button>
        </ModalFooter>
      </Modal>
    </form>
  );
};

export default AddNewUser;
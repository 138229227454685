import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import Pagination from "../../../components/common/pagination";
import IssuesAndOtherRequestsStatus from "./issuesAndOtherRequestsStatus";
import { useLocation, useNavigate } from "react-router-dom";

const IssuesAndOtherRequestsList = ({ showAddNew, setShowAddNew, details, setDetails, changesModal, setChangesModal }) => {

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [loadDetail, setLoadDetail] = useState(false);
  const [archived, setArchived] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const columnHeader = ["Reference #", "Type", "Requested By", "Date Requested", "Status"];

  const handleClickItem = async (item) => {
    // setLoadDetail(true)
    // try {
    //   const { data } = await useGetApiClient.get(`/admin/request/other-request/get/${item.id}`);
    //   if (data?.error) throw new Error(data?.error);
    //   setDetails(data);
    //   setShowAddNew(true);
    // } catch (error) {
    //   toast.error(error?.message);
    // } finally {
    //   setLoadDetail(false);
    // }
    setShowAddNew(true);
    navigate(`/request-management?tab=issues-other-requests&type=status&id=${item.id}`);
  }

  const rows = (item) => {
    return (
      <>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{item.referenceNumber}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{item.type}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{item.requestedBy}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{item.createdAt}</div></td>
        <td>
          <div className="d-flex align-items-center cursor-pointer" style={{ height: '50px' }} onClick={() => handleClickItem(item)} >
            <div className={`${item.status?.toLowerCase() === "disapproves" ? 'bg-danger' : (item.status?.toLowerCase() === "in-progress" || item.status?.toLowerCase() === "new") ? 'bg-primary' : 'bg-success'} py-1 px-2 rounded-3 text-white`} style={{ fontSize: '12px', width: "fit-content" }}>{item.status}</div>
          </div>
        </td>
      </>
    )
  }

  useEffect(() => {
    const getArcList = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/request/other-request/list?perPage=${perPage}&currentPage=${page}`);
        if (data?.error) throw new Error(data?.error);
        setList(data || [])
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }

    !params.get("type") && getArcList();
  }, [archived, page, perPage, params.get("type")]);

  return (
    <>
      {!params.get("id") &&
        <Card className="config-tasks-list mt-3">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="d-flex flex-row gap-4">
                <h5 className="text-primary">
                  Issues & Other Requests
                </h5>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="pt-0">
            <div className="table-responsive">
              <table className="config-tasks-list_table table">
                <thead>
                  <tr>
                    {columnHeader.map((item, index) => (
                      <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(loading || loadDetail)
                    ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                    : (list?.otherRequests?.length > 0)
                      ? list?.otherRequests?.map((violation, idx) => (
                        <tr key={idx}>
                          {rows(violation)}
                        </tr>
                      ))
                      : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="pt-4">
              <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} totalCount={list?.count ?? 0} />
            </div>
          </Card.Body>
        </Card>
      }
      {/* : <IssuesAndOtherRequestsStatus /> */}
      {params.get("type") === "status" &&
        <IssuesAndOtherRequestsStatus />
      }
    </>
  )
}

export default IssuesAndOtherRequestsList;
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";

// images
import card1 from "../../assets/images/small/img-1.jpg";
import house from "../../assets/images/user/1.jpg";

// react-slick
import Slider from 'react-slick';
import "../../assets/scss/screens/dashboard/house-list.scss";
import "../../assets/scss/screens/dashboard/tasks-list.scss";

//full calender
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import * as moment from "moment";
import useDashboardStore from "../../zustand/useDashboardStore";
import useGetDashboardHomeownersApi from "../../hooks/dashboard/useGetDashboardHomeownersApi";
import { SpinningBlueCircleLoader } from "../../components/common/loaders";

const Index = () => {
  const [showTaskHistory, setShowTaskHistory] = useState(null);
  const { setActiveTab } = useDashboardStore();
  const [open, setOpen] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();

  const [loadContent, setLoadContent] = useState(true);

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  const { loading, homeOwners } = useGetDashboardHomeownersApi();

  useEffect(() => {
    function handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setTimeout(() => {
          setLoadContent(false);
        }, 2000);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: homeOwners?.homeowners.length > 6 ? 6 : homeOwners?.homeowners.length,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const events = [
    {
      title: "5:30a Click for Google",
      url: "http://google.com/",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-20, "days")
          .format("YYYY-MM-DD"),
      color: "#50b5ff",
    },
    {
      title: "5:30a All Day Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-18, "days")
          .format("YYYY-MM-DD"),
      color: "#a09e9e",
    },
    {
      title: "5:30a Long Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-16, "days")
          .format("YYYY-MM-DD"),
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-13, "days")
          .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-14, "days")
          .format("YYYY-MM-DD"),
      color: "#ffba68",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-12, "days")
          .format("YYYY-MM-DD"),
      color: "#d592ff ",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-10, "days")
          .format("YYYY-MM-DD"),
      color: "#ff9b8a",
    },
    {
      title: "5:30a Birthday Party",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-8, "days")
          .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Meeting",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-6, "days")
          .format("YYYY-MM-DD"),
      color: "#a09e9e",
    },
    {
      title: "5:30a Birthday Party",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-5, "days")
          .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Birthday Party",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD"),
      color: "#ff9b8a ",
    },

    {
      title: "5:30a Meeting",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD"),
      color: "#ff9b8a",
    },
    {
      title: "5:30a Click for Google",
      url: "http://google.com/",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD"),
      color: "#d592ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Birthday Party",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD"),
      color: "#f4a965",
    },
    {
      title: "5:30a Doctor Meeting",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD"),
      color: "#f4a965",
    },
    {
      title: "5:30a All Day Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD"),
      color: " #50b5ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(8, "days")
          .format("YYYY-MM-DD"),
      color: " #50b5ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(10, "days")
          .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
  ]

  const houses = [
    { id: 1, imgSrc: house, name: 'House 1' },
    { id: 2, imgSrc: house, name: 'House 2' },
    { id: 3, imgSrc: house, name: 'House 3' },
    { id: 4, imgSrc: house, name: 'House 4' },
    { id: 5, imgSrc: house, name: 'House 5' },
    { id: 6, imgSrc: house, name: 'House 6' },
    { id: 7, imgSrc: house, name: 'House 7' },
    { id: 8, imgSrc: house, name: 'House 8' },
    { id: 9, imgSrc: house, name: 'House 9' },
    { id: 10, imgSrc: house, name: 'House 10' }
  ];

  const tableTasksLists = [
    {
      status: "red",
      task: "Approved",
      datePosted: "2024-03-26",
      initiatedBy: "Board member",
      dateDue: "2024-03-26"
    },
    {
      status: "orange",
      task: "Validate",
      datePosted: "2024-03-26",
      initiatedBy: "Board member",
      dateDue: "2024-03-26"
    },
    {
      status: "yellow",
      task: "Submitted",
      datePosted: "2024-03-26",
      initiatedBy: "Board member",
      dateDue: "2024-03-26"
    }
  ]

  const renderTaskListHistory = (idx) => {
    const dummyTaskListHistoryData = [
      {
        date: '04-04-2024',
        status: 'Done'
      },
      {
        date: '04-03-2024',
        status: 'Task updated'
      },
      {
        date: '04-02-2024',
        status: 'Task created'
      }
    ]

    if (showTaskHistory !== idx) return null;
    return (
      <tr>
        <td colSpan={6}>
          {dummyTaskListHistoryData?.map((taskList, idx) => (
            <div
              key={idx}
              className="d-flex flex-row gap-4 align-items-center w-100 ms-5 my-4"
            >
              <div>{taskList.date}</div>
              <div>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="5" cy="5" r="5" fill="#FE9B89" />
                </svg>
              </div>
              <div>{taskList.status}</div>
            </div>
          ))}
        </td>
      </tr>
    )
  }

  const handleOnClickedThumbnail = (homeOwner) => {
    setActiveTab("homeowners");
    navigate(`/user-management?tab=homeowners`, {
      state: { homeOwner },
    });
    // window.location.href = "/user-management?tab=homeowners";
  }

  if (loading) return <div className="pt-5"><SpinningBlueCircleLoader /></div>;
  return (
    <>
      <Row className="gx-4">
        <Col lg={12}>
          <div className="house-list-container">
            <Slider {...settings}>
              {homeOwners?.homeowners?.map(homeowner => (
                <div
                  key={homeowner.id}
                  className="house-list-item"
                  onClick={() => handleOnClickedThumbnail(homeowner)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src={homeowner?.house?.picture} alt={homeowner?.house?.streetName} className="house-list-img" />
                  <div className="house-list-name">
                    {homeowner?.house?.streetNumber} {homeowner?.house?.streetName}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Col>

        <Col md="8" lg="8">
          <Card >
            <Card.Body>
              <FullCalendar
                className="calendar-s"
                initialView="dayGridMonth"
                contentHeight="auto"
                eventLimit=" true"
                dayMaxEvents={1}
                plugins={[dayGridPlugin, listPlugin, bootstrapPlugin]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                }}
                events={events}
              />
            </Card.Body>
          </Card>

          <Card className="tasks-list">
            <Card.Header className="tasks-list_header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title text-primary fw-bold">My Tasks</h4>
              </div>
            </Card.Header>
            <Card.Body className="pt-0">
              <div className="table-responsive">
                <table className="tasks-list_table table">
                  <thead>
                    <tr>
                      <th scope="col" className="tasks-list_table--header text-primary">Status</th>
                      <th scope="col" className="tasks-list_table--header text-primary">Tasks</th>
                      <th scope="col" className="tasks-list_table--header text-primary">Date Posted</th>
                      <th scope="col" className="tasks-list_table--header text-primary">Initiated By</th>
                      <th scope="col" className="tasks-list_table--header text-primary">Date Due</th>
                      <th scope="col" className="tasks-list_table--header text-primary">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableTasksLists.map((item, idx) => (
                      <>
                        <tr key={idx}>
                          <td>
                            <div className="d-flex flex-row align-items-center gap-3">
                              <div>
                                {
                                  item.status === "red" ?
                                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="8" cy="7.5" r="7.5" fill="#CF2A27" />
                                    </svg>
                                    : item.status === "orange" ?
                                      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="8" cy="7.5" r="7.5" fill="#FF9900" />
                                      </svg>
                                      :
                                      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="8" cy="7.5" r="7.5" fill="#FFFF00" />
                                      </svg>
                                }
                              </div>
                              <button
                                type="button"
                                className="border-0 bg-transparent"
                                onClick={() => showTaskHistory === idx ? setShowTaskHistory(null) : setShowTaskHistory(idx)}
                              >
                                {showTaskHistory !== idx ? (
                                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.54311 4.35561C1.58665 4.31196 1.63838 4.27732 1.69533 4.25369C1.75228 4.23006 1.81333 4.2179 1.87498 4.2179C1.93664 4.2179 1.99769 4.23006 2.05464 4.25369C2.11159 4.27732 2.16332 4.31196 2.20686 4.35561L7.49998 9.64967L12.7931 4.35561C12.8367 4.31203 12.8884 4.27745 12.9454 4.25387C13.0023 4.23028 13.0633 4.21814 13.125 4.21814C13.1866 4.21814 13.2477 4.23028 13.3046 4.25387C13.3615 4.27745 13.4133 4.31203 13.4569 4.35561C13.5004 4.39919 13.535 4.45093 13.5586 4.50787C13.5822 4.56482 13.5943 4.62585 13.5943 4.68748C13.5943 4.74912 13.5822 4.81015 13.5586 4.86709C13.535 4.92404 13.5004 4.97578 13.4569 5.01936L7.83186 10.6444C7.78832 10.688 7.73659 10.7226 7.67964 10.7463C7.62269 10.7699 7.56164 10.7821 7.49998 10.7821C7.43833 10.7821 7.37728 10.7699 7.32033 10.7463C7.26338 10.7226 7.21165 10.688 7.16811 10.6444L1.54311 5.01936C1.49946 4.97582 1.46482 4.92409 1.44119 4.86714C1.41756 4.81019 1.4054 4.74914 1.4054 4.68748C1.4054 4.62583 1.41756 4.56478 1.44119 4.50783C1.46482 4.45088 1.49946 4.39915 1.54311 4.35561Z" fill="#93929B" />
                                  </svg>
                                ) : (
                                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4569 10.6444C13.4133 10.688 13.3616 10.7227 13.3047 10.7463C13.2477 10.7699 13.1867 10.7821 13.125 10.7821C13.0634 10.7821 13.0023 10.7699 12.9454 10.7463C12.8884 10.7227 12.8367 10.688 12.7931 10.6444L7.50002 5.35033L2.20689 10.6444C2.16331 10.688 2.11157 10.7225 2.05463 10.7461C1.99768 10.7697 1.93665 10.7819 1.87502 10.7819C1.81338 10.7819 1.75235 10.7697 1.69541 10.7461C1.63846 10.7225 1.58672 10.688 1.54314 10.6444C1.49956 10.6008 1.46499 10.5491 1.4414 10.4921C1.41781 10.4352 1.40567 10.3742 1.40567 10.3125C1.40567 10.2509 1.41781 10.1899 1.4414 10.1329C1.46499 10.076 1.49956 10.0242 1.54314 9.98064L7.16814 4.35564C7.21168 4.31199 7.26341 4.27736 7.32036 4.25372C7.37731 4.23009 7.43836 4.21793 7.50002 4.21793C7.56167 4.21793 7.62272 4.23009 7.67967 4.25372C7.73662 4.27736 7.78835 4.31199 7.83189 4.35564L13.4569 9.98064C13.5005 10.0242 13.5352 10.0759 13.5588 10.1329C13.5824 10.1898 13.5946 10.2509 13.5946 10.3125C13.5946 10.3742 13.5824 10.4352 13.5588 10.4922C13.5352 10.5491 13.5005 10.6008 13.4569 10.6444Z" fill="#93929B" />
                                  </svg>
                                )}
                              </button>
                            </div>
                          </td>
                          <td>{item.task}</td>
                          <td>{item.datePosted}</td>
                          <td>{item.initiatedBy}</td>
                          <td>{item.dateDue}</td>
                          <td>
                            <Button
                              variant="primary"
                              className="btn-sm d-inline-flex rounded-3"
                            >
                              <i className="material-symbols-outlined me-0">search</i>
                            </Button>{" "}
                          </td>
                        </tr>
                        {renderTaskListHistory(idx)}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md="4" lg="4">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title fw-bold text-black">Upcoming Events</h4>
              </div>
            </Card.Header>
            <Card.Body className="pt-0">
              <ul className="m-0 p-0 job-classification list-inline d-flex flex-column gap-3">
                <li className="d-flex flex-row align-items-center gap-4">
                  <div className="d-flex flex-column bg-primary rounded-3 py-2 px-3">
                    <p className="text-white m-0 fw-bold">10</p>
                    <span className="text-white m-0 fw-bold">May</span>
                  </div>
                  <div className="d-flex flex-column">
                    <h5 className="fw-bold">HOA Board Meeting</h5>
                    <span className="">Event Details</span>
                  </div>
                </li>
                <li className="d-flex flex-row align-items-center gap-4">
                  <div className="d-flex flex-column bg-primary rounded-3 py-2 px-3">
                    <p className="text-white m-0 fw-bold">20</p>
                    <span className="text-white m-0 fw-bold">May</span>
                  </div>
                  <div className="d-flex flex-column">
                    <h5 className="fw-bold">Election</h5>
                    <span className="">Event Details</span>
                  </div>
                </li>
                <li className="d-flex flex-row align-items-center gap-4">
                  <div className="d-flex flex-column bg-primary rounded-3 py-2 px-3">
                    <p className="text-white m-0 fw-bold">31</p>
                    <span className="text-white m-0 fw-bold">May</span>
                  </div>
                  <div className="d-flex flex-column">
                    <h5 className="fw-bold">HOA Events</h5>
                    <span className="">Event Details</span>
                  </div>
                </li>
              </ul>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <ul className="m-0 p-0 job-classification list-inline d-flex flex-column gap-3">
                <Link
                  to={'/user-management?tab=homeowners'}
                  onClick={() => setActiveTab('homeowners')}
                >
                  <li className="d-flex flex-row align-items-center rounded-3 p-3" style={{ backgroundColor: "#F79F85" }}>
                    <span className="text-white fw-bold w-50">HOA Directory</span>
                    <span className="text-white fw-bold">10</span>
                  </li>
                </Link>
                <Link
                  to={'/system-configuration?tab=arc'}
                  onClick={() => setActiveTab('arc')}
                >
                  <li className="d-flex flex-row align-items-center rounded-3 p-3" style={{ backgroundColor: "#F79F85" }}>
                    <span className="text-white fw-bold w-50">Requests</span>
                    <span className="text-white fw-bold">10</span>
                  </li>
                </Link>
                <Link
                  to={'/system-configuration?tab=billing'}
                  onClick={() => setActiveTab('billing')}
                >
                  <li className="d-flex flex-row align-items-center rounded-3 p-3" style={{ backgroundColor: "#F79F85" }}>
                    <span className="text-white fw-bold w-50">Delinquent</span>
                    <span className="text-white fw-bold">7</span>
                  </li>
                </Link>
                <Link
                  to={'/system-configuration?tab=violation'}
                  onClick={() => setActiveTab('violation')}
                >
                  <li className="d-flex flex-row align-items-center rounded-3 p-3" style={{ backgroundColor: "#F79F85" }}>
                    <span className="text-white fw-bold w-50">Violations</span>
                    <span className="text-white fw-bold">10</span>
                  </li>
                </Link>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent", right: "-25px" }}
      onClick={onClick}
    >
      <svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.307396 1.0575C0.365453 0.999296 0.434423 0.953117 0.510354 0.921609C0.586286 0.890101 0.667687 0.873882 0.749896 0.873882C0.832105 0.873882 0.913507 0.890101 0.989438 0.921609C1.06537 0.953117 1.13434 0.999296 1.1924 1.0575L8.6924 8.5575C8.7506 8.61556 8.79678 8.68453 8.82829 8.76046C8.85979 8.83639 8.87601 8.91779 8.87601 9C8.87601 9.08221 8.85979 9.16361 8.82829 9.23954C8.79678 9.31547 8.7506 9.38444 8.6924 9.4425L1.1924 16.9425C1.07504 17.0599 0.915866 17.1258 0.749896 17.1258C0.583927 17.1258 0.424754 17.0599 0.307396 16.9425C0.190038 16.8251 0.124107 16.666 0.124107 16.5C0.124107 16.334 0.190038 16.1749 0.307396 16.0575L7.36615 9L0.307396 1.9425C0.249192 1.88444 0.203013 1.81547 0.171505 1.73954C0.139997 1.66361 0.123779 1.58221 0.123779 1.5C0.123779 1.41779 0.139997 1.33639 0.171505 1.26046C0.203013 1.18453 0.249192 1.11556 0.307396 1.0575Z" fill="#E5E5E5" />
      </svg>
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent", left: "-25px" }}
      onClick={onClick}
    >
      <svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.69252 1.0575C7.75072 1.11556 7.7969 1.18453 7.82841 1.26046C7.85992 1.33639 7.87613 1.41779 7.87613 1.5C7.87613 1.58221 7.85992 1.66361 7.82841 1.73954C7.7969 1.81547 7.75072 1.88444 7.69252 1.9425L0.633768 9L7.69252 16.0575C7.80988 16.1749 7.87581 16.334 7.87581 16.5C7.87581 16.666 7.80988 16.8251 7.69252 16.9425C7.57516 17.0599 7.41599 17.1258 7.25002 17.1258C7.08405 17.1258 6.92488 17.0599 6.80752 16.9425L-0.692482 9.4425C-0.750686 9.38444 -0.796865 9.31547 -0.828373 9.23954C-0.859881 9.16361 -0.876099 9.08221 -0.876099 9C-0.876099 8.91779 -0.859881 8.83639 -0.828373 8.76046C-0.796865 8.68453 -0.750686 8.61556 -0.692482 8.5575L6.80752 1.0575C6.86557 0.999296 6.93454 0.953117 7.01048 0.921609C7.08641 0.890101 7.16781 0.873882 7.25002 0.873882C7.33223 0.873882 7.41363 0.890101 7.48956 0.921609C7.56549 0.953117 7.63446 0.999296 7.69252 1.0575Z" fill="#E5E5E5" />
      </svg>
    </div>
  );
};

export default Index;
export const sidebarIconsMap = [
    {
        name: "Dashboard",
        icon: "space_dashboard",
    },
    {
        name: "Profile",
        icon: "person",
    },
    {
        name: "User Management",
        icon: "manage_accounts",
    },
    {
        name: "Users",
        icon: "group",
    },
    {
        name: "Roles",
        icon: "id_card",
    },
    {
        name: "Homeowners",
        icon: "location_away",
    },
    {
        name: "Request Management",
        icon: "assignment",
    },
    {
        name: "Voting Management",
        icon: "bar_chart",
    },
    {
        name: "Notification Management",
        icon: "notifications",
    },
    {
        name: "Events, Blogs and News Setup",
        icon: "full_coverage",
    },
    {
        name: "Billing And Invoicing",
        icon: "account_balance",
    },
    {
        name: "System Configuration",
        icon: "settings",
    },
    {
        name: "Exit",
        icon: "logout",
    },
    {
        name: "Document Management",
        icon: "folder_open",
    },
]
import { useEffect, useRef, useState } from "react";
import { useGetApiClient, useUploadPictureApi } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import { SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { CustomSelect } from "../../../components/common/CustomSelect";
import { ordinal_suffix } from "../../../utilities/common";
import ConfirmModal from "../../../components/common/ConfirmModal";

const { Card, Form, Accordion, Row } = require("react-bootstrap");

const BillingList = ({ changesModal, setChangesModal }) => {
  const fileInputRef = useRef();
  const fileAcclaimedInputRef = useRef();
  const dayOptions = Array.from({ length: 31 }, (_, i) => ({ label: (i + 1) + ordinal_suffix(i + 1), value: i + 1 }))
  const [toggleView, setToggleView] = useState(false);
  const [fileView, setFileView] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadingPosting, setLoadingPosting] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileAcclaimedLoading, setFileAcclaimedLoading] = useState(false);
  const [columnOptions, setColumnOptions] = useState([]);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });

  const [form, setForm] = useState({
    monthlyFeeDesc: "",
    monthlyFeeAmount: "",
    rentalFeeDesc: "",
    rentalFeeAmount: "",
    autoGenerate: true,
    generateDay: "",
    generateTime: "",
    invoiceDay: "",
    dueDate: "",
    penalty: "",
    stripeEndPoint: "",
    stripeAccount: "",
    stripeSecret: "",
    fileAcclaimed: {
      name: "",
      file: "",
      original_file: "",
      fileType: "",
      fileId: "",
    },
    getEvery: "",
    getEveryTime: "",
    file: {
      name: "",
      file: "",
      original_file: "",
      fileType: "",
      fileId: "",
    },
    amenities: [{ description: "", rate: "" }],
    services: [{ description: "", rate: "" }],
    columns: [
      { name: "propertyId", label: "Property ID", column: "" },
      { name: "status", label: "Status", column: "" },
      { name: "homeowner", label: "Homeowner", column: "" },
      { name: "address", label: "Address", column: "" },
      { name: "date", label: "Date", column: "" },
      { name: "description", label: "Description", column: "" },
      { name: "prevBalance", label: "Prev Balance", column: "" },
      { name: "batch", label: "Batch", column: "" },
      { name: "amount", label: "Amount", column: "" },
      { name: "paid", label: "Paid", column: "" },
      { name: "balance", label: "Balance", column: "" },
    ],
  });

  const handleSelectView = (file) => {
    const params = {
      ...file,
      fileType: file?.fileType,
      path: file.file.toLowerCase().includes("https://")
        ? file.file
        : file.original_file,
    };
    setFileView(params);
    setToggleView(true);
  };

  const handleFileChange = async (e, name) => {
    name === "fileAcclaimed" ? setFileAcclaimedLoading(true) : setFileLoading(true);
    const reader = new FileReader();
    const formData = new FormData();
    const file = e.target.files[0]
    formData.append('file', file);
    reader.readAsDataURL(file);
    let original_file = null;

    reader.onload = (e) => {
      original_file = e.target.result;
    };

    try {
      const { data } = await useUploadPictureApi.post('/admin/configuration/billing/upload/document', formData);
      if (data?.error) throw new Error(data?.error);

      if (name === "fileAcclaimed") {
        setForm({
          ...form,
          fileAcclaimed: {
            name: file.name,
            file: data?.directoryPath,
            original_file: original_file,
            fileType: data?.directoryPath?.slice(data?.directoryPath.lastIndexOf(".") + 1, data?.directoryPath.length),
          }
        });
        const inputfile = document.getElementsByName(`upload_acclaimed`);
        inputfile.value = "";

      } else {
        const { data: dataHeaders } = await useGetApiClient.post('/admin/configuration/billing/get/header', {
          file: data?.directoryPath,
        });
        if (dataHeaders?.error) throw new Error(dataHeaders?.error);
        setForm({
          ...form,
          file: {
            name: file.name,
            file: data?.directoryPath,
            original_file: original_file,
            fileType: data?.directoryPath?.slice(data?.directoryPath.lastIndexOf(".") + 1, data?.directoryPath.length),
            fileId: dataHeaders?.fileId,
          }
        });
        setColumnOptions(dataHeaders?.headers?.map(header => ({ label: header, value: header })))
        const inputfile = document.getElementsByName(`upload_billing`);
        inputfile.value = "";
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      name === "fileAcclaimed" ? setFileAcclaimedLoading(false) : setFileLoading(false);
    }
  }

  const handleFormChange = (e, name, index) => {
    if (!changesModal.fields.find(f => f === name)) setChangesModal({ ...changesModal, fields: [...changesModal.fields, name] })

    if (name === "columns") {
      setForm({
        ...form,
        columns: form.columns.map((item, i) => {
          if (i === index) return { ...item, column: e }
          else return item
        })
      })
    }

    else if (name === "generateDay" || name === "invoiceDay" || name === "dueDate" || name === "getEvery") {
      setForm({ ...form, [name]: e })
    }

    else if (name === "autoGenerate") {
      setForm({ ...form, [name]: e.target.checked })
    }

    else if (e.target.name.includes("amenities")) {
      setForm({
        ...form,
        amenities: form.amenities.map((item, i) => {
          if (i === index) return { ...item, [name]: e.target.value }
          else return item
        })
      })
    }

    else if (e.target.name.includes("services")) {
      setForm({
        ...form,
        services: form.services.map((item, i) => {
          if (i === index) return { ...item, [name]: e.target.value }
          else return item
        })
      })
    }

    else {
      setForm({ ...form, [name]: e.target.value })
    }

  }

  const handlePosting = async () => {
    try {
      setLoadingPosting(true);
      const { data } = await useGetApiClient.post('/admin/configuration/billing/posting', {
        fileId: form.file?.fileId,
        columns: form.columns?.reduce((acc, item) => {
          acc[item.name] = item.column?.label;
          return acc;
        }, {})
      });
      if (data?.error) throw new Error(data?.error);
      toast.success(data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
    } finally {
      setLoadingPosting(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitLoading(true);
      const params = {
        monthlyFeeDesc: form.monthlyFeeDesc,
        monthlyFeeAmount: form.monthlyFeeAmount,
        rentalFeeDesc: form.rentalFeeDesc,
        rentalFeeAmount: form.rentalFeeAmount,
        autoGenerate: form.autoGenerate,
        generateDay: form.generateDay?.value,
        generateTime: form.generateTime,
        invoiceDay: form.invoiceDay?.value,
        dueDate: form.dueDate?.value,
        penalty: Number(form.penalty),
        stripeEndPoint: form.stripeEndPoint,
        stripeAccount: form.stripeAccount,
        stripeSecret: form.stripeSecret,
        filePath: form.fileAcclaimed?.file || "/",
        getEvery: form.getEvery?.value,
        getEveryTime: form.getEveryTime,
        amenities: form.amenities?.map(amenity => ({ description: amenity.description, rate: amenity.rate })),
        services: form.services?.map(service => ({ description: service.description, rate: service.rate })),
        columns: []
        // columns: form.columns?.map(col => ({ name: col.name, column: col.column?.value }))
      }

      const { data } = await useGetApiClient.post('/admin/configuration/billing/save', params);
      if (data?.error) throw new Error(data?.error);
      // toast.success(data?.message);
      setOpenModal({ ...openModal, save: true })
      setChangesModal({ open: false, fields: [], targetTab: null })
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  useEffect(() => {
    const getBilling = async () => {
      try {
        setLoading(true);
        const { data } = await useGetApiClient.get('/admin/configuration/billing/get');
        if (data?.error) throw new Error(data?.error);
        setForm({
          ...form,
          monthlyFeeDesc: data.monthlyFeeDesc,
          monthlyFeeAmount: data.monthlyFeeAmount,
          rentalFeeDesc: data.rentalFeeDesc,
          rentalFeeAmount: data.rentalFeeAmount,
          autoGenerate: data.autoGenerate,
          generateDay: dayOptions?.find(day => day.value === data.generateDay),
          generateTime: data.generateTime,
          invoiceDay: dayOptions?.find(day => day.value === data.invoiceDay),
          dueDate: dayOptions?.find(day => day.value === data.dueDate),
          penalty: data.penalty,
          stripeEndPoint: data.stripeEndPoint,
          stripeAccount: data.stripeAccount,
          stripeSecret: data.stripeSecret,
          getEvery: dayOptions?.find(day => day.value === data.getEvery),
          getEveryTime: data.getEveryTime,
          amenities: data.amenities?.length > 0 ? data.amenities : [{ description: "", rate: "" }],
          services: data.services?.length > 0 ? data.services : [{ description: "", rate: "" }],
        });

      } catch (error) {
        toast.error(error?.response?.data?.message ?? error?.message);
      } finally {
        setLoading(false);
      }
    }

    getBilling();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="config-tasks-list">
        <div className="config-tasks-list_header">
          <div className="d-flex flex-row justify-content-between w-100">
            <h5 className="text-primary">
              Billing
            </h5>
          </div>
        </div>

        <Card.Body className="pt-0">
          <Accordion id="adminMenu-0" defaultActiveKey={0}>
            <Accordion.Item key={0} className="mb-3 bg-primary" eventKey={0}>
              <Accordion.Header id={0}>
                Main Billing
              </Accordion.Header>
              <Accordion.Body>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Monthly Fee</label>
                  <div className="col-sm-5">
                    <input type="text" value={form.monthlyFeeDesc} className="form-control" id="name" name="name" placeholder="Description" onChange={(e) => handleFormChange(e, "monthlyFeeDesc")} required />
                  </div>
                  <div className="col-sm-5">
                    <input type="number" value={form.monthlyFeeAmount} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "monthlyFeeAmount")} required />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Rental Fee</label>
                  <div className="col-sm-5">
                    <input type="text" value={form.rentalFeeDesc} className="form-control" id="name" name="name" placeholder="Description" onChange={(e) => handleFormChange(e, "rentalFeeDesc")} required />
                  </div>
                  <div className="col-sm-5">
                    <input type="number" value={form.rentalFeeAmount} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "rentalFeeAmount")} required />
                  </div>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-1" defaultActiveKey={1}>
            <Accordion.Item key={1} className="mb-3 bg-primary" eventKey={1}>
              <Accordion.Header id={1}>
                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                  <span>Auto Generate Billing</span>
                  <Form.Check
                    className="d-flex align-items-center form-switch form-check-inline"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      className="bg-primary"
                      id="customSwitch01"
                      checked={form.autoGenerate}
                      onChange={(e) => handleFormChange(e, "autoGenerate")}
                    />
                  </Form.Check>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Generate billing every</label>
                  <div className="col-sm-3">
                    <CustomSelect
                      value={form.generateDay}
                      onChange={(e) => handleFormChange(e, "generateDay")}
                      options={dayOptions || []}
                      optLabelKey="label"
                      optValueKey="value"
                      placeholder="-"
                      required={form.autoGenerate}
                    />
                  </div>
                  <label className="control-label align-self-center mb-2" style={{ width: "fit-content" }} htmlFor="name">day of the month, at</label>
                  <div className="col-sm-3">
                    <input type="time" value={form.generateTime} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "generateTime")} required={form.autoGenerate} />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Show invoice date</label>
                  <div className="col-sm-3">
                    <CustomSelect
                      value={form.invoiceDay}
                      onChange={(e) => handleFormChange(e, "invoiceDay")}
                      options={dayOptions || []}
                      optLabelKey="label"
                      optValueKey="value"
                      placeholder="-"
                      required={form.autoGenerate}
                    />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Due date</label>
                  <div className="col-sm-3">
                    <CustomSelect
                      value={form.dueDate}
                      onChange={(e) => handleFormChange(e, "dueDate")}
                      options={dayOptions || []}
                      optLabelKey="label"
                      optValueKey="value"
                      placeholder="-"
                      required={form.autoGenerate}
                    />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Penalty after due</label>
                  <div className="col-sm-3">
                    <input type="number" value={form.penalty} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "penalty")} required={form.autoGenerate} />
                  </div>
                  <label className="control-label align-self-center mb-2" style={{ width: "fit-content" }} htmlFor="name">%</label>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-2" defaultActiveKey={2}>
            <Accordion.Item key={2} className="mb-3 bg-primary" eventKey={2}>
              <Accordion.Header id={1}>
                Amenities Rates
              </Accordion.Header>
              <Accordion.Body>
                {form?.amenities?.map((item, index) => (
                  <Row key={index} className="form-group align-items-end">
                    {index === 0
                      ? <i className="material-symbols-outlined filled text-primary w-auto mb-2 cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, amenities: [...form.amenities, { description: "", rate: "" }] })}>add_box</i>
                      : <div style={{ width: "41px" }} />
                    }
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.amenities[${index}].description`}>Amenities</label>
                      <input type="text" value={form.amenities[index].description} className="form-control" id={`form.amenities[${index}].description`} name={`form.amenities[${index}].description`} placeholder="-" onChange={(e) => handleFormChange(e, "description", index)} required />
                    </div>
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.amenities[${index}].rate`}>Rate</label>
                      <input type="number" value={form.amenities[index].rate} className="form-control" id={`form.amenities[${index}].rate`} name={`form.amenities[${index}].rate`} placeholder="-" onChange={(e) => handleFormChange(e, "rate", index)} required />
                    </div>
                    {form.amenities.length > 1 &&
                      <i className="material-symbols-outlined text-danger w-auto mb-2 cursor-pointer" onClick={() => setForm({ ...form, amenities: form.amenities.filter((_, i) => i !== index) })}>delete</i>
                    }
                  </Row>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-3" defaultActiveKey={3}>
            <Accordion.Item key={3} className="mb-3 bg-primary" eventKey={3}>
              <Accordion.Header id={1}>
                Other Services Rate
              </Accordion.Header>
              <Accordion.Body>
                {form?.services?.map((item, index) => (
                  <Row key={index} className="form-group align-items-end">
                    {index === 0
                      ? <i className="material-symbols-outlined filled text-primary w-auto mb-2 cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, services: [...form.services, { description: "", rate: "" }] })}>add_box</i>
                      : <div style={{ width: "41px" }} />
                    }
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.services[${index}].description`}>Service Name</label>
                      <input type="text" value={form.services[index].description} className="form-control" id={`form.services[${index}].description`} name={`form.services[${index}].description`} placeholder="-" onChange={(e) => handleFormChange(e, "description", index)} required />
                    </div>
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.services[${index}].rate`}>Rate</label>
                      <input type="number" value={form.services[index].rate} className="form-control" id={`form.services[${index}].rate`} name={`form.services[${index}].rate`} placeholder="-" onChange={(e) => handleFormChange(e, "rate", index)} required />
                    </div>
                    {form.services.length > 1 &&
                      <i className="material-symbols-outlined text-danger w-auto mb-2 cursor-pointer" onClick={() => setForm({ ...form, services: form.services.filter((_, i) => i !== index) })}>delete</i>
                    }
                  </Row>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-4" defaultActiveKey={4}>
            <Accordion.Item key={4} className="mb-3 bg-primary" eventKey={4}>
              <Accordion.Header id={1}>
                Stripe Settings
              </Accordion.Header>
              <Accordion.Body>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Endpoint</label>
                  <div className="col-sm-3">
                    <input type="text" value={form.stripeEndPoint} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "stripeEndPoint")} />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Account</label>
                  <div className="col-sm-3">
                    <input type="text" value={form.stripeAccount} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "stripeAccount")} />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Secret</label>
                  <div className="col-sm-3">
                    <input type="text" value={form.stripeSecret} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "stripeSecret")} />
                  </div>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-5" defaultActiveKey={5}>
            <Accordion.Item key={5} className="mb-3 bg-primary" eventKey={5}>
              <Accordion.Header id={1}>
                Billing Upload
              </Accordion.Header>
              <Accordion.Body>
                <label className="control-label align-self-center mb-2" htmlFor="name">Upload Acclaimed</label>
                <Row className="align-items-start">
                  <div className="col-sm-6 col-lg-5" style={{ height: 70 }}>
                    <div className="form-control" style={{ minHeight: "50px" }}>
                      {form.fileAcclaimed.name &&
                        <div className="d-flex flex-row align-items-center justify-content-between gap-1 rounded-3 bg-danger" style={{ width: "fit-content", color: "white", padding: "5px 8px", cursor: "pointer" }} onClick={() => handleSelectView(form.fileAcclaimed)}>
                          <span>{form.fileAcclaimed.name}</span>
                        </div>
                      }
                    </div>
                    <input
                      type="file"
                      disabled={fileAcclaimedLoading}
                      ref={fileAcclaimedInputRef}
                      style={{ opacity: 0, pointerEvents: 'none', height: 0, width: 0 }}
                      name="upload_acclaimed"
                      onChange={(e) => handleFileChange(e, "fileAcclaimed")}
                    />
                  </div>
                  <i className="material-symbols-outlined text-primary w-auto cursor-pointer" style={{ marginTop: 10 }} onClick={() => fileAcclaimedInputRef.current.click()}>upload</i>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Get file every</label>
                  <div className="col-sm-3">
                    <CustomSelect
                      value={form.getEvery}
                      onChange={(e) => handleFormChange(e, "getEvery")}
                      options={dayOptions || []}
                      optLabelKey="label"
                      optValueKey="value"
                      placeholder="-"
                      required
                    />
                  </div>
                  <label className="control-label align-self-center mb-2" style={{ width: "fit-content" }} htmlFor="name">day of the month, at</label>
                  <div className="col-sm-3">
                    <input type="time" value={form.getEveryTime} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "getEveryTime")} required />
                  </div>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-5" defaultActiveKey={5}>
            <Accordion.Item key={5} className="mb-3 bg-primary" eventKey={5}>
              <Accordion.Header id={1}>
                System File Columns
              </Accordion.Header>
              <Accordion.Body>
                <label className="control-label col-sm-12 align-self-center mb-2" htmlFor="name">Upload Billing (CSV)</label>
                <Row className="form-group align-items-start">
                  <div className="col-sm-6 col-lg-5" style={{ height: 70 }}>
                    <div className="form-control" style={{ minHeight: "50px" }}>
                      {form.file.name &&
                        <div className="d-flex flex-row align-items-center justify-content-between gap-1 rounded-3 bg-danger" style={{ width: "fit-content", color: "white", padding: "5px 8px", cursor: "pointer" }} onClick={() => handleSelectView(form.file)}>
                          <span>{form.file.name}</span>
                        </div>
                      }
                    </div>
                    <input
                      type="file"
                      disabled={fileLoading}
                      ref={fileInputRef}
                      style={{ opacity: 0, pointerEvents: 'none', height: 0, width: 0 }}
                      name="upload_billing"
                      onChange={(e) => handleFileChange(e, "file")}
                    />
                  </div>
                  <i className="material-symbols-outlined text-primary w-auto cursor-pointer" style={{ marginTop: 10 }} onClick={() => fileInputRef.current.click()}>upload</i>
                </Row>
                {form.columns?.map((col, index) => (
                  <Row key={index} className="form-group align-items-center">
                    <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">{col.label}</label>
                    <div className="col-sm-3">
                      <CustomSelect
                        value={col?.column}
                        onChange={(e) => handleFormChange(e, "columns", index)}
                        options={columnOptions || []}
                        optLabelKey="label"
                        optValueKey="value"
                        placeholder="-"
                      />
                    </div>
                  </Row>
                ))}
                <button type="button" className="btn btn-primary" style={{ width: "30%" }} onClick={handlePosting}>
                  {!loadingPosting ? 'Post' : <SpinningWhiteCircleLoader />}
                </button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>

      <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2">
        <button type="submit" className="btn btn-primary w-50">
          {!submitLoading ? 'Save' : <SpinningWhiteCircleLoader />}
        </button>
      </Form.Check>

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />
    </Form>

  );
}

export default BillingList;
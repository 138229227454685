import { useEffect, useState } from "react";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import Pagination from "../../../components/common/pagination";
// import ArcStatus from "./arcStatus_old";
import { useLocation, useNavigate } from "react-router-dom";
import ARCStatus from "./arcStatus";
import ARCTimeline from "./arcTimeline";

const ArcList = ({ showAddNew, setShowAddNew, details, setDetails, changesModal, setChangesModal }) => {

  const [isListView, setIsListView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [arcList, setArcList] = useState([]);
  const [loadDetail, setLoadDetail] = useState(false);
  const [archived, setArchived] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const columnHeader = ["ARC Reference #", "ARC Type", "Requested By", "Date Requested", "Expected Completion", "Status", "Action"];

  const handleClickItem = async (item) => {
    // setLoadDetail(true)
    // try {
    //   const { data } = await useGetApiClient.get(`/admin/request/arc/get/${item.id}`);
    //   if (data?.error) throw new Error(data?.error);
    //   setDetails(data);
    //   setShowAddNew(true);
    // } catch (error) {
    //   toast.error(error?.message);
    // } finally {
    //   setLoadDetail(false);
    // }
    setShowAddNew(true);
    navigate(`/request-management?tab=arc&type=status&id=${item.id}`);
  }

  const handleUpdateClick = (id) => {
    navigate(`/request-management?tab=arc&type=timeline&id=${id}`);
    // navigate(`/request-management/arc/timeline?id=${id}`);
  };

  const arcRows = (arc) => {
    return (
      <>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{arc.referenceNumber}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{arc.name}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{arc.requestedBy}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{arc.createdAt}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{arc.endDate}</div></td>
        <td>
          <div className="d-flex align-items-center cursor-pointer" style={{ height: '50px' }} onClick={() => handleClickItem(arc)} >
            <div className={`${arc.status?.toLowerCase() === "disapproves" ? 'bg-danger' : arc.status?.toLowerCase() === "in-progress" ? 'bg-primary' : 'bg-success'} py-1 px-2 rounded-3 text-white`} style={{ fontSize: '12px', width: "fit-content" }}>{arc.status}</div>
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center justify-content-center cursor-pointer" style={{ height: '50px' }} onClick={() => handleUpdateClick(arc.id)} >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Update</Tooltip>}
            >
              <i className="material-symbols-outlined me-1 md-18 text-primary">
                assignment
              </i>
            </OverlayTrigger>
          </div>
        </td>
      </>
    )
  }

  useEffect(() => {
    const getArcList = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/request/arc/list/${!archived ? 0 : 1}?perPage=${perPage}&currentPage=${page}`);
        if (data?.error) throw new Error(data?.error);
        setArcList(data || [])
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }

    !params.get("type") && getArcList();
  }, [isListView, archived, page, perPage, params.get("type")]);

  return (
    <>
      {/* <div className="d-flex flex-row gap-2">
        <i className={`material-symbols-outlined me-1 md-18 cursor-pointer ${isListView && "text-primary"}`} onClick={() => setIsListView(true)}>format_list_numbered</i>
        <i className={`material-symbols-outlined me-1 md-18 cursor-pointer ${!isListView && "text-primary"}`} onClick={() => setIsListView(false)}>view_kanban</i>
      </div> */}
      {!params.get("id") &&
        <Card className="config-tasks-list mt-3">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="d-flex flex-row gap-4">
                <h5
                  className={`${!archived ? 'text-decoration-underline fw-bold' : ''} text-primary`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setArchived(false)}
                >
                  ARC
                </h5>
                <h5
                  className={`${archived ? 'text-decoration-underline fw-bold' : ''} text-primary ms-2`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setArchived(true)}
                >
                  Archive
                </h5>
              </div>
              {/* <Button
                  className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                  disabled={loading}
                // onClick={() => setShowAddNewArc(true)}
                >
                  <i className="material-symbols-outlined me-1 md-18">add</i>
                  Create ARC
                </Button> */}
            </div>
          </Card.Header>
          <Card.Body className="pt-0">
            <div className="table-responsive">
              <table className="config-tasks-list_table table">
                <thead>
                  <tr>
                    {columnHeader.map((item, index) => (
                      <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(loading || loadDetail)
                    ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                    : (arcList?.arcRequests?.length > 0)
                      ? arcList?.arcRequests?.map((arc, idx) => (
                        <tr key={idx}>
                          {arcRows(arc)}
                        </tr>
                      ))
                      : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="pt-4">
              <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} totalCount={arcList?.count ?? 0} />
            </div>
          </Card.Body>
        </Card>
      }
      {/* : <ArcStatus setShowAddNew={setShowAddNew} details={details} setDetails={setDetails} /> */}
      {params.get("type") === "status" &&
        <ARCStatus />
      }
      {params.get("type") === "timeline" &&
        <ARCTimeline />
      }

      {/* <Card className="config-tasks-list mt-3">
        <Card.Header>
          <h5 className="text-primary">Approved</h5>
        </Card.Header>
        <Card.Body className="pt-0">
          <div className="table-responsive">
            <table className="config-tasks-list_table table">
              <thead>
                <tr>
                  {columnHeader.map((item, index) => (
                    <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading
                  ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                  : arcList?.approved?.length > 0
                    ? arcList?.approved?.map((arc, idx) => (
                      <tr key={idx}>
                        {arcRows(arc)}

                      </tr>
                    ))
                    : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
                }
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>

      <Card className="config-tasks-list mt-3">
        <Card.Header>
          <h5 className="text-primary">Dis-approved</h5>
        </Card.Header>
        <Card.Body className="pt-0">
          <div className="table-responsive">
            <table className="config-tasks-list_table table">
              <thead>
                <tr>
                  {columnHeader.map((item, index) => (
                    <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading
                  ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                  : arcList?.disapproved?.length > 0
                    ? arcList?.disapproved?.map((arc, idx) => (
                      <tr key={idx}>
                        {arcRows(arc)}
                      </tr>
                    ))
                    : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
                }
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>

      <Card className="config-tasks-list mt-3">
        <Card.Header>
          <h5 className="text-primary">Done</h5>
        </Card.Header>
        <Card.Body className="pt-0">
          <div className="table-responsive">
            <table className="config-tasks-list_table table">
              <thead>
                <tr>
                  {columnHeader.map((item, index) => (
                    <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading
                  ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                  : arcList?.done?.length > 0
                    ? arcList?.done?.map((arc, idx) => (
                      <tr key={idx}>
                        {arcRows(arc)}
                      </tr>
                    ))
                    : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
                }
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card> */}
    </>
  )
}

export default ArcList;
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useGetApiClient } from '../../api/useApiClient';

const useGetUsersSubCommitteeListApi = (page, perPage) => {
    const [loading, setLoading] = useState(false);
    const [subCommitteeList, setSubCommitteeList] = useState(null);

    useEffect(() => {
        const getSubCommitteeList = async() => {
            setLoading(true);
            try {
                const { data } = await useGetApiClient.get(`/admin/user/list/sub-committee?perPage=${perPage}&currentPage=${page}`);
                if (data?.error) throw new Error(data?.error);
                setSubCommitteeList(data)
            } catch(error) { 
                toast.error(error?.message)
            } finally {
                setLoading(false);
            }
        }

        getSubCommitteeList();
    }, [page, perPage]);

    return {loading, subCommitteeList};
}

export default useGetUsersSubCommitteeListApi;
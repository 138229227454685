import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../components/common/loaders";
import * as moment from "moment";
import { useGetApiClient } from "../../api/useApiClient";
import { toast } from "react-toastify";

const BillingStatus = () => {

  const [loading, setLoading] = useState(false);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [list, setList] = useState();
  const [listPrevious, setListPrevious] = useState();

  const columnHeader = ["Account", "House", "Owner Name", "Date Sent", "Date Billed", "Description", "Previous Balance", "Amount", "Paid", "Balance", "Status"];

  const rows = (row) => {
    return (
      <>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.account}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.house}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.ownerName}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.dateSent}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.dateBilled}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.description}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.prevBalance}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.amount}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.paid}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.balance}</div></td>
        <td>
          <div className="d-flex align-items-center" style={{ height: '50px' }}>
            <div className={`${row.status?.toLowerCase() === "disapproves" ? 'bg-danger' : row.status?.toLowerCase() === "in-progress" ? 'bg-primary' : 'bg-success'} py-1 px-2 rounded-3 text-white`} style={{ fontSize: '12px', width: "fit-content" }}>{row.status}</div>
          </div>
        </td>
      </>
    )
  }

  const getlist = async (status) => {
    status === "CURRENT" ? setLoading(true) : setLoadingPrevious(true);
    try {
      const { data } = await useGetApiClient.get(`/admin/billing/list/${status}`);
      if (data?.error) throw new Error(data?.error);
      status === "CURRENT" ? setList(data || []) : setListPrevious(data || [])
    } catch (error) {
      toast.error(error?.message);
    } finally {
      status === "CURRENT" ? setLoading(false) : setLoadingPrevious(false);
    }
  }

  useEffect(() => {
    getlist("CURRENT");
    getlist("PREVIOUS");
  }, []);

  return (
    <Card className="config-tasks-list mt-3">
      <Card.Header>
        <div className="d-flex flex-row justify-content-between w-100">
          <div className="d-flex flex-row gap-4">
            <h5
              className="text-primary"
            >
              Billing
            </h5>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        <div style={{ backgroundColor: "#56bcfd", borderRadius: "5px 5px 0px 0px", padding: "10px", marginBottom: 10, color: "white" }}>Statements - {moment().format('MMMM')}</div>
        <div className="table-responsive">
          <table className="config-tasks-list_table table">
            <thead>
              <tr>
                {columnHeader.map((item, index) => (
                  <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading
                ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                : (list?.length > 0)
                  ? list?.map((item, idx) => (
                    <tr key={idx}>
                      {rows(item)}
                    </tr>
                  ))
                  : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
              }
            </tbody>
          </table>
        </div>

        <div style={{ backgroundColor: "#56bcfd", borderRadius: "5px 5px 0px 0px", padding: "10px", marginBottom: 10, marginTop: 20, color: "white" }}>Last Month Statements</div>
        <div className="table-responsive">
          <table className="config-tasks-list_table table">
            <thead>
              <tr>
                {columnHeader.map((item, index) => (
                  <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loadingPrevious
                ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                : (listPrevious?.length > 0)
                  ? listPrevious?.map((item, idx) => (
                    <tr key={idx}>
                      {rows(item)}
                    </tr>
                  ))
                  : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
              }
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
}

export default BillingStatus;
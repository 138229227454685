import { useEffect, useState } from "react";
import { Button, Card, Row } from "react-bootstrap";
import {
  SpinningBlueCircleLoader,
  SpinningWhiteCircleLoader,
} from "../../../components/common/loaders";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import Pagination from "../../../components/common/pagination";
import AddNewNews from "./addNewNews";
import NewsIcon from "../../../assets/images/icon/news.svg";
import * as moment from "moment";
import ConfirmModal from "../../../components/common/ConfirmModal";

const NewsList = ({
  showAddNew,
  setShowAddNew,
  details,
  setDetails,
  changesModal,
  setChangesModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [loadDetail, setLoadDetail] = useState(false);
  const [loadStatus, setLoadStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedNews, setSelectedNews] = useState(null);
  const [openModal, setOpenModal] = useState({
    confirmDelete: false,
    confirmDeleteId: null,
    confirmDeleteLoading: false,
  });

  const handleClickItem = async (item) => {
    setLoadDetail(true);
    try {
      setSelectedNews(item.id);
      const { data } = await useGetApiClient.get(`/admin/news/get/${item.id}`);
      if (data?.error) throw new Error(data?.error);
      setDetails(data);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDetail(false);
    }
  };

  const handleUpdateStatus = async (item) => {
    try {
      setLoadStatus(true);
      const { data } = await useGetApiClient.put(
        `/admin/news/update/status/${item.id}`,
        {
          status: item.status === "ONGOING" ? "CLOSED" : "SENT",
        }
      );
      if (data?.error) throw new Error(data?.error);
      getlist();
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadStatus(false);
    }
  };

  const getlist = async () => {
    setLoading(true);
    try {
      const { data } = await useGetApiClient.get(
        `/admin/news/list?perPage=${perPage}&currentPage=${page}`
      );
      if (data?.error) throw new Error(data?.error);
      setList(data || []);
      data?.news?.length > 0 && handleClickItem(data?.news?.[0]);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setOpenModal((prev) => ({ ...prev, confirmDeleteLoading: true }));

      const { data } = await useGetApiClient.delete(
        `/admin/news/delete/${openModal.confirmDeleteId}`
      );
      if (data?.error) throw new Error(data?.error);

      getlist();
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
    } finally {
      setOpenModal((prev) => ({
        ...prev,
        confirmDelete: false,
        confirmDeleteId: null,
        confirmDeleteLoading: false,
      }));
    }
  };

  useEffect(() => {
    getlist();
  }, [showAddNew, page, perPage]);

  return (
    <>
      {openModal.confirmDelete && (
        <ConfirmModal
          open={openModal.confirmDelete}
          onHide={() =>
            setOpenModal((prev) => ({
              ...prev,
              confirmDelete: false,
              confirmDeleteId: null,
            }))
          }
          titleText={<h5 className="text-danger">Delete</h5>}
          confirmText="Are you sure you want to delete?"
          isTwoActions={true}
          confirmButton={
            <button
              className="btn btn-danger w-50"
              onClick={openModal.confirmDeleteLoading ? () => {} : handleDelete}
            >
              {openModal.confirmDeleteLoading ? (
                <SpinningWhiteCircleLoader />
              ) : (
                "Yes"
              )}
            </button>
          }
          cancelButton={
            <button
              className="btn btn-primary w-50"
              onClick={
                openModal.confirmDeleteLoading
                  ? () => {}
                  : () =>
                      setOpenModal((prev) => ({
                        ...prev,
                        confirmDelete: false,
                        confirmDeleteId: null,
                        confirmDeleteLoading: false,
                      }))
              }
            >
              No
            </button>
          }
        />
      )}
      {!showAddNew ? (
        <>
          <Card className="config-tasks-list mt-3">
            <Card.Header>
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <div className="d-flex flex-row gap-4">
                  <h5
                    className="text-primary"
                    // onClick={() => setArchived(false)}
                  >
                    News
                  </h5>
                  {/* <h5
                    className={`${archived ? 'text-decoration-underline fw-bold' : ''} text-primary ms-2`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setArchived(true)}
                  >
                    Archive
                  </h5> */}
                </div>
                <Button
                  className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                  disabled={loading}
                  onClick={() => setShowAddNew(true)}
                >
                  <i className="material-symbols-outlined me-1 md-18">add</i>
                  Create News
                </Button>
              </div>
            </Card.Header>
          </Card>
          <div className="d-flex flex-row gap-4">
            <div style={{ width: "350px", paddingBottom: "1.5rem" }}>
              <div style={{ maxHeight: 500, overflowY: "auto" }}>
                {loading ? (
                  <Card style={{ padding: 20 }}>
                    <SpinningBlueCircleLoader />
                  </Card>
                ) : list?.news?.length > 0 ? (
                  list?.news?.map((item, index) => (
                    // <Card key={index} onClick={() => handleClickArc(item)} style={{ cursor: "pointer" }}>
                    <Card
                      key={index}
                      style={{
                        backgroundColor:
                          selectedNews === item.id
                            ? "var(--bs-primary-bg-subtle)"
                            : "white",
                      }}
                    >
                      <div className="d-flex flex-row align-items-center justify-content-between gap-1 p-4">
                        <div
                          className="d-flex flex-row align-items-center gap-3 cursor-pointer"
                          onClick={() => handleClickItem(item)}
                        >
                          <img
                            src={NewsIcon}
                            style={{
                              filter:
                                index % 3 === 0
                                  ? "unset"
                                  : index % 3 === 1
                                  ? "brightness(0) saturate(100%) invert(65%) sepia(39%) saturate(1152%) hue-rotate(214deg) brightness(99%) contrast(104%)"
                                  : "brightness(0) saturate(100%) invert(79%) sepia(14%) saturate(1790%) hue-rotate(334deg) brightness(103%) contrast(100%)",
                            }}
                          />
                          <div>
                            <div
                              className="me-1"
                              style={{
                                color:
                                  index % 3 === 0
                                    ? "#F7685B"
                                    : index % 3 === 1
                                    ? "#D592FF"
                                    : "#FFBA68",
                              }}
                            >
                              {item.title}
                            </div>
                            <div
                              className="me-1"
                              style={{
                                color:
                                  index % 3 === 0
                                    ? "#F7685B"
                                    : index % 3 === 1
                                    ? "#D592FF"
                                    : "#FFBA68",
                                fontSize: "10px",
                              }}
                            >
                              {moment.isMoment(item.sentAt)
                                ? moment(item.sentAt).format("MMMM DD, YYYY")
                                : item.sentAt}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                          }}
                        >
                          <i
                            className="material-symbols-outlined me-1 md-18 text-primary cursor-pointer"
                            onClick={() => handleUpdateStatus(item)}
                          >
                            {item.status === "ONGOING"
                              ? "cell_tower"
                              : item.status === "NEW"
                              ? "send"
                              : "door_front"}
                          </i>
                          <i
                            className="material-symbols-outlined me-1 md-18 text-danger cursor-pointer"
                            onClick={() =>
                              setOpenModal((prev) => ({
                                ...prev,
                                confirmDelete: true,
                                confirmDeleteId: item.id,
                              }))
                            }
                          >
                            delete
                          </i>
                        </div>
                      </div>
                    </Card>
                  ))
                ) : (
                  <Card style={{ padding: 20 }}>No record found</Card>
                )}
              </div>
              <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                showRowsPerPage={false}
                totalCount={list?.count ?? 0}
              />
            </div>
            <Card className="config-tasks-list" style={{ width: "100%" }}>
              <Card.Body>
                {loading || loadDetail ? (
                  <SpinningBlueCircleLoader />
                ) : (
                  <div className="col-sm-7 d-flex flex-column justify-content-center w-100">
                    <img
                      src={
                        details?.documets?.find((doc) => doc.type === "MAIN")
                          ?.file
                      }
                      className="img-fluid"
                      alt="user"
                      loading="lazy"
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
          <Card className="config-tasks-list" style={{ width: "100%" }}>
            <Card.Body>
              <h5 className="text-primary mb-3">Latest News</h5>
              {loading || loadDetail ? (
                <SpinningBlueCircleLoader />
              ) : (
                <>{details?.description}</>
              )}
            </Card.Body>
          </Card>
        </>
      ) : (
        <AddNewNews
          list={list}
          changesModal={changesModal}
          setChangesModal={setChangesModal}
          setShowAddNew={setShowAddNew}
        />
      )}
    </>
  );
};

export default NewsList;

import { useState } from "react";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Nav,
  Tab,
} from "react-bootstrap";
import Card from "../../components/Card";
import ConfirmModal from "../../components/common/ConfirmModal";
import NewsList from "./tabs/newsList";
import BlogsList from "./tabs/blogsList";
import EventsList from "./tabs/eventsList";

const EventsBlogsAndNewsManagement = () => {
  const [showAddNew, setShowAddNew] = useState(false);
  const [details, setDetails] = useState(null);
  const [changesModal, setChangesModal] = useState({ open: false, fields: [], targetTab: null });
  const [activeTab, setActiveTab] = useState("news");

  const configTabsMenu = [
    {
      title: "News",
      icon: "newspaper",
    },
    {
      title: "Blogs",
      icon: "edit_square",
    },
    {
      title: "Events",
      icon: "event_busy",
    },
  ]

  const handleTabBtn = (tab) => {
    setActiveTab(tab)

    if (showAddNew) {
      setShowAddNew(false);
      setDetails(null);
    }
  }

  const renderTabs = () => {
    switch (activeTab) {
      case "news":
        return <NewsList showAddNew={showAddNew} setShowAddNew={setShowAddNew} details={details} setDetails={setDetails} changesModal={changesModal} setChangesModal={setChangesModal} />;
      case "blogs":
        return <BlogsList showAddNew={showAddNew} setShowAddNew={setShowAddNew} details={details} setDetails={setDetails} changesModal={changesModal} setChangesModal={setChangesModal} />;
      case "events":
        return <EventsList showAddNew={showAddNew} setShowAddNew={setShowAddNew} details={details} setDetails={setDetails} changesModal={changesModal} setChangesModal={setChangesModal} />;
      default:
        return null;
    }
  }

  return (
    <>
      <Row className="gx-4 py-4">
        <Tab.Container
          id="system-configuration"
        >
          <Col lg={12}>
            <Card>
              <Card.Body className="p-0">
                <div className="config-tabs user-tabing item-list-tabs">
                  <Nav
                    as="ul"
                    variant="pills"
                    className="profile-feed-items d-flex align-items-center justify-content-center p-0 m-0 rounded"
                  >
                    {configTabsMenu.map((tab, idx) => (
                      <Nav.Item as="li" key={idx} className="col-12 col-sm-4">
                        <Link
                          role="button"
                          className={`${activeTab === tab?.title?.toLocaleLowerCase()
                            ? 'active'
                            : ''
                            } d-flex flex-md-column align-items-center flex-row justify-content-center gap-2`}
                          onClick={() => changesModal.fields.length > 0 ? setChangesModal({ ...changesModal, open: true, targetTab: tab?.title?.toLocaleLowerCase() }) : handleTabBtn(tab?.title?.toLocaleLowerCase())}
                        >
                          <span className="icon rounded-3">
                            <span className="material-symbols-outlined">
                              {tab.icon}
                            </span>
                          </span>
                          <p className="mb-0 mt-0 mt-md-3">{tab.title}</p>
                        </Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={12}>
            <Tab.Content>
              {renderTabs()}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>

      <ConfirmModal
        open={changesModal.open}
        onHide={() => setChangesModal({ open: false, fields: [], targetTab: null })}
        titleIcon={<i className="material-symbols-outlined text-warning" style={{ fontSize: "130px" }}>warning</i>}
        titleText={<h5 className="text-warning">Leave</h5>}
        confirmText="Are you sure you want to leave? You have unsaved changes."
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-warning w-50" onClick={() => {
            handleTabBtn(changesModal.targetTab);
            setChangesModal({ open: false, fields: [], targetTab: null })
          }}>
            Yes
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setChangesModal({ ...changesModal, open: false, targetTab: null })}>
            No
          </button>
        }
      />
    </>
  )
}

export default EventsBlogsAndNewsManagement;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.house-list-container {
  padding: 1rem 0;
  width: 90%;
  margin: auto;
}
.house-list-container .slick-track {
  margin: 0 !important;
}
.house-list-container .house-list-item {
  text-align: center;
}
.house-list-container .house-list-item .house-list-img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 8px solid #E0E0E0;
  object-fit: cover;
  margin: 0 auto;
}
.house-list-container .house-list-item .house-list-name {
  color: #7E8B9A;
  margin-top: 10px;
}
.house-list-container .slick-prev, .house-list-container .slick-next {
  font-size: 0;
  z-index: 1;
}
.house-list-container .slick-prev:before, .house-list-container .slick-next:before {
  font-size: 30px;
  color: #E0E0E0;
}
.house-list-container .slick-prev {
  left: -35px;
}
.house-list-container .slick-next {
  right: -35px;
}
.house-list-container .slick-prev:before {
  content: "";
}
.house-list-container .slick-next:before {
  content: "";
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/screens/dashboard/house-list.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,UAAA;EACA,YAAA;AACJ;AACI;EACI,oBAAA;AACR;AAEI;EACI,kBAAA;AAAR;AAGQ;EACI,YAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,cAAA;AADZ;AAIQ;EACI,cAAA;EACA,gBAAA;AAFZ;AAMI;EACI,YAAA;EACA,UAAA;AAJR;AAOI;EACI,eAAA;EACA,cAAA;AALR;AAQI;EACI,WAAA;AANR;AASI;EACI,YAAA;AAPR;AAUI;EACI,WAAA;AARR;AAWI;EACI,WAAA;AATR","sourcesContent":[".house-list-container {\n    padding: 1rem 0;\n    width: 90%; \n    margin: auto;\n\n    .slick-track {\n        margin: 0 !important;\n    }\n\n    .house-list-item {\n        text-align: center;\n        // width: 180px !important;\n\n        .house-list-img {\n            width: 125px;\n            height: 125px;\n            border-radius: 50%;\n            border: 8px solid #E0E0E0;\n            object-fit: cover;\n            margin: 0 auto;\n        }\n\n        .house-list-name {\n            color: #7E8B9A;\n            margin-top: 10px;\n        }\n    }\n\n    .slick-prev, .slick-next {\n        font-size: 0; \n        z-index: 1;\n    }\n    \n    .slick-prev:before, .slick-next:before {\n        font-size: 30px;\n        color: #E0E0E0;\n    }\n    \n    .slick-prev {\n        left: -35px;\n    }\n    \n    .slick-next {\n        right: -35px;\n    }\n    \n    .slick-prev:before {\n        content: '';\n    }\n    \n    .slick-next:before {\n        content: '';\n    }\n\n    .slick-slide {\n        // width: 170px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

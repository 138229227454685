import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import useGetRolesApi from "../../../../hooks/role/useGetRolesApi";
import { SpinningBlueCircleLoader } from "../../../../components/common/loaders";
import { useEffect, useState } from "react";
import { stringToURL } from "../../../../utilities/helpers";

const RolesList = () => {
    const [rolesWithSub, setRolesWithSub] = useState([]);
    const [rolesWithoutSub, setRolesWithoutSub] = useState([]);
    const [roles, setRoles] = useState([]);

    // api calls
    const {loading, rolesMenus} = useGetRolesApi();

    useEffect(() => {
        if (rolesMenus) {
            setRolesWithSub(rolesMenus?.roles?.filter((role) => role?.sub !== null));
            setRolesWithoutSub(rolesMenus?.roles?.filter((role) => role?.sub === null));
        }
    }, [setRolesWithSub, setRolesWithoutSub, rolesMenus]);

    // console.log(rolesMenus);

    const handleSelectRoles = ({event, menuId}) => {
        let permission = event.target.value;
        let roleMenu = roles.find((role) => role?.menuId === menuId);

        if (roleMenu) {
            setRoles((prevRoles) =>
                prevRoles.map((role) => {
                  if (role.menuId === menuId) {
                    if (!role.permissions.includes(permission)) {
                      return { ...role, permissions: [...role.permissions, permission] };
                    } else {
                        return {
                            ...role,
                            permissions: role.permissions.filter((perm) => perm !== permission),
                        };
                    }
                  }
                  return role;
                })
                .filter((role) => role.permissions.length > 0)
            );
        } else {
            setRoles([...roles, {
                menuId,
                permissions: [permission]
            }])
        }
    }

    // console.log(roles)

    if (loading) return <SpinningBlueCircleLoader />;
    return (
        <Card className="config-tasks-list">
             <div className="config-tasks-list_header">
                <div className="d-flex flex-row justify-content-between w-100">
                    <h5 className="text-primary">
                        Roles
                    </h5>
                </div>
            </div>
            <Card.Body className="pt-0">
                <Form>                
                    <Row>
                        <Col lg={6} className='d-flex flex-row gap-4'>
                            {rolesWithoutSub?.map((role, idx) => (
                                <div key={idx} className="form-check col-sm px-5 py-3 border border-light d-flex justify-content-center align-items-center rounded-3">
                                    <input 
                                        className="border border-primary" 
                                        type="checkbox" 
                                        name="role" 
                                        // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                                        value={role?.id}
                                        // onChange={(e) => handleOnRolesChecked(e)}
                                        id="role" 
                                    />
                                    <label className="form-check-label ms-2 d-flex flex-column gap-2 align-items-center" htmlFor="role">
                                        <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_2342_8352)">
                                            <path d="M6.20898 7.5C6.20898 9.48912 7.05184 11.3968 8.55213 12.8033C10.0524 14.2098 12.0873 15 14.209 15C16.3307 15 18.3655 14.2098 19.8658 12.8033C21.3661 11.3968 22.209 9.48912 22.209 7.5C22.209 5.51088 21.3661 3.60322 19.8658 2.1967C18.3655 0.790176 16.3307 0 14.209 0C12.0873 0 10.0524 0.790176 8.55213 2.1967C7.05184 3.60322 6.20898 5.51088 6.20898 7.5ZM12.1152 19.2305L13.2777 21.0469L11.1965 28.3066L8.94648 19.6992C8.82148 19.2246 8.33398 18.9141 7.82773 19.0371C3.45273 20.0625 0.208984 23.7773 0.208984 28.2012C0.208984 29.1973 1.07148 30 2.12773 30H10.359C10.359 30 10.359 30 10.3652 30H10.709H17.709H18.0527C18.0527 30 18.0527 30 18.059 30H26.2902C27.3527 30 28.209 29.1914 28.209 28.2012C28.209 23.7773 24.9652 20.0625 20.5902 19.0371C20.084 18.9199 19.5965 19.2305 19.4715 19.6992L17.2215 28.3066L15.1402 21.0469L16.3027 19.2305C16.7027 18.6035 16.2215 17.8125 15.4465 17.8125H14.209H12.9777C12.2027 17.8125 11.7215 18.6094 12.1215 19.2305H12.1152Z" fill="#6C757D"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_2342_8352">
                                            <rect width="28" height="30" fill="white" transform="translate(0.208984)"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                        <span>{role?.name}</span>
                                    </label>
                                </div>
                            ))}
                        </Col>
                        <Col lg={6} className='d-flex flex-row gap-4 align-items-center'>
                            <label className="control-label mb-0 col-sm-3" htmlFor="subCommittee">Sub-committee</label>
                            <select
                                className="form-select col-sm"
                                id="subCommittee"
                                name='subCommittee'
                                // onChange={(e) => handleOnSubRolesChanged(e)}
                                // defaultValue={(e) => homeownerDetails?.roles?.includes(e.target.value)}
                            >
                                <option>-</option>
                                {rolesWithSub?.map((role, idx) => (
                                    <option key={idx} value={role?.id}>{role?.sub}</option>
                                ))}
                            </select> 
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <div className="d-flex flex-row justify-content-between w-100 pb-4">
                            <h5 className="text-primary">
                                Admin menus
                            </h5>
                        </div>
                        {rolesMenus?.adminMenus?.map((adminMenu, idx) => (
                            <Accordion id={`adminMenu-${idx}`} defaultActiveKey={idx}>
                                <Accordion.Item key={idx} className="mb-3 bg-primary" eventKey={idx}>
                                        <Accordion.Header id={stringToURL(adminMenu?.name)}>
                                            {adminMenu?.name}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {adminMenu?.subLevel?.length !== 0 ? (
                                                adminMenu?.subLevel?.map((subMenu, idx) => (
                                                    <div key={subMenu?.id} className="row gap-4 pb-4">
                                                        <div className="col-sm">{subMenu?.name}</div>
                                                        {rolesMenus?.permissions?.map((permission, idx) => (
                                                            <div key={idx} className="form-check col-sm d-flex justify-content-center align-items-center">
                                                                <label className="form-check-label me-2" htmlFor="role">
                                                                    {
                                                                        permission === "canRead" ? 'View'
                                                                        :  permission === "canCreate" ? 'Create'
                                                                        :  permission === "canDelete" ? 'Delete'
                                                                        :  permission === "canUpdate" ? 'Update'
                                                                        : 'Generate'
                                                                    }
                                                                </label>
                                                                <input 
                                                                    className="border border-primary" 
                                                                    type="checkbox" 
                                                                    name={permission} 
                                                                    // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                                                                    value={permission}
                                                                    onChange={(event) => handleSelectRoles({event, menuId: subMenu?.id})}
                                                                    id={permission} 
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))
                                            ) : (
                                                <div key={idx} className="row gap-4 pb-4">
                                                    <div className="col-sm">{adminMenu?.name}</div>
                                                    {rolesMenus?.permissions?.map((permission, idx) => (
                                                        <div key={idx} className="form-check col-sm d-flex justify-content-center align-items-center">
                                                            <label className="form-check-label me-2" htmlFor="role">
                                                                {
                                                                    permission === "canRead" ? 'View'
                                                                    :  permission === "canCreate" ? 'Create'
                                                                    :  permission === "canDelete" ? 'Delete'
                                                                    :  permission === "canUpdate" ? 'Update'
                                                                    : 'Generate'
                                                                }
                                                            </label>
                                                            <input 
                                                                className="border border-primary" 
                                                                type="checkbox" 
                                                                name={permission} 
                                                                // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                                                                value={permission}
                                                                onChange={(event) => handleSelectRoles({event, menuId: adminMenu?.id})}
                                                                id={permission} 
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> 
                        ))}
                    </Row>
                    <Row className="pt-4">
                        <div className="d-flex flex-row justify-content-between w-100 pb-4">
                            <h5 className="text-primary">
                                Homeowner menus
                            </h5>
                        </div>
                        {rolesMenus?.homeownerMenus?.map((homeownerMenu, idx) => (
                            <Accordion id={`homeownerMenu-${idx}`} defaultActiveKey={idx}>
                                <Accordion.Item key={idx} className="mb-3 bg-primary" eventKey={idx}>
                                        <Accordion.Header id={stringToURL(homeownerMenu?.name)}>
                                            {homeownerMenu?.name}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {homeownerMenu?.subLevel?.length !== 0 ? (
                                                homeownerMenu?.subLevel?.map((subMenu, idx) => (
                                                    <div key={subMenu?.id} className="row gap-4 pb-4">
                                                        <div className="col-sm">{subMenu?.name}</div>
                                                        {rolesMenus?.permissions?.map((permission, idx) => (
                                                            <div key={idx} className="form-check col-sm d-flex justify-content-center align-items-center">
                                                                <label className="form-check-label me-2" htmlFor="role">
                                                                    {
                                                                        permission === "canRead" ? 'View'
                                                                        :  permission === "canCreate" ? 'Create'
                                                                        :  permission === "canDelete" ? 'Delete'
                                                                        :  permission === "canUpdate" ? 'Update'
                                                                        : 'Generate'
                                                                    }
                                                                </label>
                                                                <input 
                                                                    className="border border-primary" 
                                                                    type="checkbox" 
                                                                    name={permission} 
                                                                    // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                                                                    value={permission}
                                                                    onChange={(event) => handleSelectRoles({event, menuId: subMenu?.id})}
                                                                    id={permission} 
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))
                                            ) : (
                                                <div key={idx} className="row gap-4 pb-4">
                                                    <div className="col-sm">{homeownerMenu?.name}</div>
                                                    {rolesMenus?.permissions?.map((permission, idx) => (
                                                        <div key={idx} className="form-check col-sm d-flex justify-content-center align-items-center">
                                                            <label className="form-check-label me-2" htmlFor="role">
                                                                {
                                                                    permission === "canRead" ? 'View'
                                                                    :  permission === "canCreate" ? 'Create'
                                                                    :  permission === "canDelete" ? 'Delete'
                                                                    :  permission === "canUpdate" ? 'Update'
                                                                    : 'Generate'
                                                                }
                                                            </label>
                                                            <input 
                                                                className="border border-primary" 
                                                                type="checkbox" 
                                                                name={permission} 
                                                                // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                                                                value={permission}
                                                                onChange={(event) => handleSelectRoles({event, menuId: homeownerMenu?.id})}
                                                                id={permission} 
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> 
                        ))}
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default RolesList;
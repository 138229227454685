import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetApiClient } from "../../../api/useApiClient";


const ARCTimeline = () => {
  const [timelineData, setTimelineData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const fetchTimelineData = async () => {
      // const id = localStorage.getItem("selectedArcRequestId");

      try {
        const response = await useGetApiClient.get(
          `/admin/request/arc/get-timeline/${params.get("id")}`
        );
        if (response.status === 200) {
          setTimelineData(response.data);
        } else {
          console.error("Error fetching timeline data:", response);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchTimelineData();
  }, []);

  const getDotClass = (description) => {
    if (description.toLowerCase().includes("in-progress")) {
      return "timeline-dots border-primary";
    }
    if (description.toLowerCase().includes("comment")) {
      return "timeline-dots border-danger";
    }
    if (description.toLowerCase().includes("created")) {
      return "timeline-dots border-info";
    }
    return "timeline-dots border-primary"; // Default
  };

  return (
    <div id="content-page">
      {/* <Container> */}
      {timelineData ? (
        <Row className="justify-content-center">
          {/* <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">
                    Timeline Update for ARC Request
                  </h3>
                  <p className="text-white">{timelineData?.description}</p>
                </div>
              </Card>
            </Col> */}
          {/* <Col sm="12">
              <Card
                className="position-relative inner-page-bg"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3>{timelineData?.name}</h3>
                  <br />
                  <Link to="/request-management?tab=arc">
                    <Button className="my-2">Back</Button>
                  </Link>
                </div>
              </Card>
            </Col> */}
          <Col>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title text-primary" style={{ textTransform: "unset" }}>Timeline for {timelineData?.name}</h4>
                </div>
                <button
                  type="button"
                  className="bg-primary py-1 d-flex align-items-center border-0 text-white rounded-3"
                  onClick={() => navigate(`/request-management?tab=arc`)}
                >
                  <i className="material-symbols-outlined">arrow_back</i>
                </button>
              </Card.Header>
              <Card.Body className="pt-0">
                <ul className="iq-timeline ms-1">
                  {timelineData?.timeline.map((item, index) => (
                    <li key={index}>
                      <Link to={`/request-management?tab=arc&type=status&id=${params.get("id")}`}>
                        <div className={getDotClass(item.description)}></div>
                      </Link>
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="mb-1">{item.title}</h6>
                        <small>{new Date(item.date).toLocaleString()}</small>
                      </div>
                      <div className="d-inline-block w-100">
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <p>Loading timeline data...</p>
      )}
      {/* </Container> */}
    </div>
  );
};

export default ARCTimeline;
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
  Nav,
  Tab,
} from "react-bootstrap";
import Card from "../../components/Card";
import { useEffect, useState } from "react";
import HouseList from "./tabs/houseList";
import AddNewHouse from "./tabs/addNewHouse";
import "../../assets/scss/screens/system-configuration/config-tabs.scss";
import "../../assets/scss/screens/system-configuration/config-tasks-list.scss";
import useDashboardStore from "../../zustand/useDashboardStore";
import { Link } from "react-router-dom";
import NotificationsList from "./tabs/notificationsList";
import ArcList from "./tabs/arcList";
import ConfirmModal from "../../components/common/ConfirmModal";
import ViolationList from "./tabs/violationList";

const SystemConfiguration = () => {
  const [showAddNew, setShowAddNew] = useState(false);
  const [details, setDetails] = useState(null);
  const [changesModal, setChangesModal] = useState({ open: false, fields: [], targetTab: null });

  const { activeTab, setActiveTab } = useDashboardStore();

  // useEffect(() => {
  //   //unmount when activeTab is changed
  //   return () => {
  //     setShowAddNew(false);
  //     setDetails(null);
  //     setShowAddNewNotification(false);
  //     setNotificationDetails(null);
  //     setShowAddNewArc(false);
  //     setArcDetails(null);
  //   };
  // }, [activeTab]);

  const configTabsMenu = [
    {
      title: "House",
      icon: "home",
    },
    {
      title: "Billing",
      icon: "attach_money",
    },
    {
      title: "ARC",
      icon: "newspaper",
    },
    {
      title: "Violation",
      icon: "report",
    },
    // {
    //   title: "Payments",
    //   icon: "wallet"
    // },
    // {
    //   title: "Charges",
    //   icon: "receipt"
    // },
    {
      title: "Notifications",
      icon: "notifications"
    },
    {
      title: "Vendors",
      icon: "diversity_3"
    }
  ]

  const handleTabBtn = (tab) => {

    if (showAddNew) {
      setShowAddNew(false);
      setActiveTab(tab);
      setDetails(null);
    } else {
      setActiveTab(tab)
    }
  }

  const renderTabs = () => {
    switch (activeTab) {
      case "house":
        return showAddNew ? <AddNewHouse setShowAddNewHouse={setShowAddNew} houseDetails={details} /> : <HouseList setShowAddNewHouse={setShowAddNew} setHouseDetails={setDetails} />;
      case "billing":
        return <h1>Billing</h1>;
      case "arc":
        return <ArcList showAddNewArc={showAddNew} setShowAddNewArc={setShowAddNew} arcDetails={details} setArcDetails={setDetails} changesModal={changesModal} setChangesModal={setChangesModal} />;
      case "violation":
        return <ViolationList showAddNew={showAddNew} setShowAddNew={setShowAddNew} details={details} setDetails={setDetails} changesModal={changesModal} setChangesModal={setChangesModal} />;
      case "payments":
        return <h1>Payments</h1>;
      case "charges":
        return <h1>Charges</h1>;
      case "notifications":
        return <NotificationsList showAddNewNotification={showAddNew} setShowAddNewNotification={setShowAddNew} notificationDetails={details} setNotificationDetails={setDetails} />;
      case "vendors":
        return <h1>Vendors</h1>;
      default:
        return null;
    }
  }

  return (
    <>
      <Row className="gx-4 py-4">
        <Tab.Container
          id="system-configuration"
        >
          <Col lg={12}>
            <Card>
              <Card.Body className="p-0">
                <div className="config-tabs user-tabing item-list-tabs">
                  <Nav
                    as="ul"
                    variant="pills"
                    className="profile-feed-items d-flex align-items-center justify-content-center p-0 m-0 rounded"
                  >
                    {configTabsMenu.map((tab, idx) => (
                      <Nav.Item as="li" key={idx} className="col-12 col-sm-1">
                        <Link
                          role="button"
                          className={`${activeTab === tab?.title?.toLocaleLowerCase()
                            ? 'active'
                            : ''
                            } d-flex flex-md-column align-items-center flex-row justify-content-center gap-2`}
                          onClick={() => changesModal.fields.length > 0 ? setChangesModal({ ...changesModal, open: true, targetTab: tab?.title?.toLocaleLowerCase() }) : handleTabBtn(tab?.title?.toLocaleLowerCase())}
                        >
                          <span className="icon rounded-3">
                            <span className="material-symbols-outlined">
                              {tab.icon}
                            </span>
                          </span>
                          <p className="mb-0 mt-0 mt-md-3">{tab.title}</p>
                        </Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={12}>
            <Tab.Content>
              {renderTabs()}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>

      <ConfirmModal
        open={changesModal.open}
        onHide={() => setChangesModal({ open: false, fields: [], targetTab: null })}
        titleIcon={<i className="material-symbols-outlined text-warning" style={{ fontSize: "130px" }}>warning</i>}
        titleText={<h5 className="text-warning">Leave</h5>}
        confirmText="Are you sure you want to leave? You have unsaved changes."
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-warning w-50" onClick={() => {
            handleTabBtn(changesModal.targetTab);
            setChangesModal({ open: false, fields: [], targetTab: null })
          }}>
            Yes
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setChangesModal({ ...changesModal, open: false, targetTab: null })}>
            No
          </button>
        }
      />
    </>
  )
}

export default SystemConfiguration;
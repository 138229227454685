import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import FileViewer from "react-file-viewer";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const FileViewerComp = (props) => {
  const { toggleView, setToggleView, fileView } = props;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <Modal
      show={toggleView}
      onHide={() => { setToggleView(false); setPageNumber(null); }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ height: "fit-content", padding: 0, }}
    >
      <ModalBody>
        <div style={{ backgroundColor: "#f7f7f8", borderRadius: "10px", border: "2px solid #f2f2f3", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", minHeight: "70vh" }}>
          {fileView?.path?.slice(fileView?.path?.lastIndexOf(".") + 1, fileView?.path?.length) !== "pdf" ?
            <FileViewer
              key={fileView ? fileView.path : 1}
              fileType={
                fileView
                  ? fileView?.fileType
                    ? fileView.fileType
                    : fileView.path.slice(
                      fileView.path.lastIndexOf(".") + 1,
                      fileView.path.length
                    )
                  : ""
              }
              filePath={fileView === null ? "" : fileView.path}
              unsupportedComponent={() =>
                fileView ? (
                  <p
                    fontSize=".95rem"
                    fontWeight={400}
                    color="warning.main"
                    textAlign="center"
                    sx={{
                      padding: "40px 20px",
                    }}
                  >
                    Selected document is not supported by our file viewer.
                  </p>
                ) : (
                  <p
                    fontSize=".95rem"
                    fontWeight={400}
                    color="brandColor.600"
                    textAlign="center"
                    sx={{
                      padding: "40px 20px",
                    }}
                  >
                    Select a document to view.
                  </p>
                )
              }
            />
            : (
              <Document
                className="file-viewer-comp"
                file={fileView === null ? "" : fileView.path}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} scale={5} />
              </Document>
            )}
        </div>
      </ModalBody>
      {(fileView?.path?.slice(fileView?.path?.lastIndexOf(".") + 1, fileView?.path?.length) === "pdf" && pageNumber) &&
        <ModalFooter>
          <div className="d-flex gap-2 justify-content-center align-items-center">
            <i className="material-symbols-outlined text-primary" onClick={() => setPageNumber((prevPageNumber) => prevPageNumber + -1)}>attach_file</i>
            <p>Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}</p>
            <i className="material-symbols-outlined text-primary" onClick={() => setPageNumber((prevPageNumber) => prevPageNumber + 1)}>attach_file</i>
          </div>
        </ModalFooter>
      }
    </Modal>
  );
}

export default FileViewerComp;
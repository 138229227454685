import React, { useState } from 'react';
import useGetUserListApi from '../../../../hooks/user-management/useGetUsersListApi';
import { Button, Card, Form } from 'react-bootstrap';
import Pagination from '../../../../components/common/pagination';
import { SpinningBlueCircleLoader } from '../../../../components/common/loaders';
import useGetUsersSubCommitteeListApi from '../../../../hooks/user-management/useGetUsersSubCommitteeListApi';

const UsersList = () => {
    const [usersPage, setUsersPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(10);
    const [subCommPage, setSubCommPage] = useState(0);
    const [subCommPerPage, setSubCommPerPage] = useState(10);

    const { loading: isUsersListLoading, usersList } = useGetUserListApi(usersPage, usersPerPage);
    const { loading: isSubCommListLoading, subCommitteeList } = useGetUsersSubCommitteeListApi(subCommPage, subCommPerPage);

    const renderContentContainer = (content) => {
        return <div className="d-flex align-items-center" style={{height: '50px'}}>{content}</div>
    }

    const renderText = (text) => {
        if (!text) return renderContentContainer("-");
        return renderContentContainer(text);
    }

    const renderStatus = (status) => {
        return (
            <div className="d-flex align-items-center" style={{height: '50px'}}>
                <div 
                    className={`${status === "ENABLED" ? 'bg-success' : 'bg-danger'} py-1 px-2 rounded-3 text-white`} 
                    style={{fontSize: '12px'}}
                >
                    {status}
                </div>
            </div>
        )
    }

    const renderUsersListRows = (datas) => {
        return (
            datas?.map((user, idx) => (
                <tr key={idx}>
                    <td
                        name="Username"
                    >
                        {renderText(user?.username)}
                    </td>
                    <td
                        name="Name"
                    >
                        {renderText(user?.firstName + ' ' + user?.lastName)}
                    </td>
                    <td
                        name="Contact Number"
                    >
                        {renderText(user?.contactNumber)}
                    </td>
                    <td
                        name="Email"
                    >
                        {renderText(user?.email)}
                    </td>
                    <td
                        name="Role"
                    >
                        {renderText(user?.roles || user?.sub)}
                    </td>
                    <td
                        name="Status"
                    >
                        {renderStatus(user?.status)}
                    </td>
                    <td
                        name="Action"
                        className="d-flex flex-row gap-2 align-items-center"
                    >
                        <div className="d-flex align-items-center" style={{height: '50px'}}>
                            <button
                                type="button"
                                className="bg-primary py-1 d-flex align-items-center border-0 text-white rounded-3"
                            >
                                <i className="material-symbols-outlined">edit_square</i>
                            </button>
                        </div>
                        
                        <Form.Check 
                            className="d-flex align-items-center form-switch form-check-inline"
                            style={{height: '50px'}}
                        >
                            <Form.Check.Input
                                type="checkbox"
                                className="bg-primary"
                                id="homeownerArchiveToggle"
                                checked={user?.status}
                                // onChange={() => setShowArchivePrompt((show) => !show, setToggledArchive(user?.id))}
                            />
                        </Form.Check>
                    </td>
                </tr>
            ))
        )
    }
    
    const renderUsersList = (datas) => {
        if (datas?.length < 1) return <tr><td colSpan={8} className="text-center">No record found</td></tr>;
        return renderUsersListRows(datas);
    }

    const renderUsersListTable = (datas) => {
        if (isUsersListLoading || isSubCommListLoading) return <SpinningBlueCircleLoader />;
        
        return (
            <table className="config-tasks-list_table table">
                <thead>
                    <tr>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Username</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Name</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Contact Number</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Email</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">{!datas?.find(({ sub }) => sub) ? "Role" : "Sub"}</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Status</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {renderUsersList(datas)}
                </tbody>
            </table>
        );
    }

    return (
        <Card className="config-tasks-list">
            <div className="config-tasks-list_header">
                <div className="d-flex flex-row justify-content-between w-100">
                    <h5 className="text-primary">
                        Users
                    </h5>
                    <span className="table-add float-end mb-3 me-2">
                        <Button 
                            className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                            // onClick={() => setShowCreateUpdateModal(true)}
                        >
                            <i className="material-symbols-outlined me-1 md-18">add</i>
                            Create User
                        </Button>
                    </span>
                </div>
            </div>
            <Card.Body className="pt-0">
                <div className="table-responsive">
                    {renderUsersListTable(usersList?.users)}
                </div>
                <div className="pt-4">
                    <Pagination page={usersPage} setPage={setUsersPage} perPage={usersPerPage} setPerPage={setUsersPerPage}/>
                </div>
            </Card.Body>

            <div className="config-tasks-list_header">
                <div className="d-flex flex-row justify-content-between w-100">
                    <h5 className="text-primary">
                        Sub-committee
                    </h5>
                </div>
            </div>
            <Card.Body className="pt-0">
                <div className="table-responsive">
                    {renderUsersListTable(subCommitteeList?.users)}
                </div>
                <div className="pt-4">
                    <Pagination page={subCommPage} setPage={setSubCommPage} perPage={subCommPerPage} setPerPage={setSubCommPerPage}/>
                </div>
            </Card.Body>
        </Card>
    )
}

export default UsersList;
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useGetApiClient } from "../../api/useApiClient";
import Card from "../../components/Card";
import { toast } from "react-toastify";
import { SpinningBlueCircleLoader } from "../../components/common/loaders";

const TaskList = () => {
  const [showTaskHistory, setShowTaskHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [listCount, setListCount] = useState(0);

  const tableTasksLists = [
    {
      statusColor: "red",
      status: "Cancelled",
      task: "Approved",
      datePosted: "2024-03-26",
      initiatedBy: "Board member",
      dateDue: "2024-03-26",
    },
    {
      statusColor: "orange",
      status: "On-going",
      task: "Validate",
      datePosted: "2024-03-26",
      initiatedBy: "Board member",
      dateDue: "2024-03-26",
    },
    {
      statusColor: "blue",
      status: "New",
      task: "Submitted",
      datePosted: "2024-03-26",
      initiatedBy: "Board member",
      dateDue: "2024-03-26",
    },
  ];

  const renderTaskListHistory = (idx) => {
    const taskHistory = list[idx].logs;

    if (showTaskHistory !== idx) return null;
    return (
      <tr>
        <td colSpan={6}>
          {taskHistory?.map((taskList, idx) => (
            <div
              key={idx}
              className="d-flex flex-row gap-4 align-items-center w-100 ms-5 my-4"
            >
              <div>{taskList.date}</div>
              <div>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="5" cy="5" r="5" fill="#FE9B89" />
                </svg>
              </div>
              <div>{taskList.log}</div>
            </div>
          ))}
        </td>
      </tr>
    );
  };

  const getList = async () => {
    try {
      setLoading(true);
      const { data } = await useGetApiClient.get(`/admin/dashboard/get/task`);
      if (data?.error) throw new Error(data?.error);

      setList(
        data.tasks.map((item) => ({
          statusColor: "blue",
          status: "new",
          ...item,
        }))
      );
      setListCount(data.count);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Card className="tasks-list">
      <Card.Header className="tasks-list_header d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title text-primary fw-bold">My Tasks</h4>
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        <div className="table-responsive">
          <table className="tasks-list_table table">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="tasks-list_table--header text-primary"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="tasks-list_table--header text-primary"
                >
                  Tasks
                </th>
                <th
                  scope="col"
                  className="tasks-list_table--header text-primary"
                >
                  Date Posted
                </th>
                <th
                  scope="col"
                  className="tasks-list_table--header text-primary"
                >
                  Initiated By
                </th>
                <th
                  scope="col"
                  className="tasks-list_table--header text-primary"
                >
                  Date Due
                </th>
                <th
                  scope="col"
                  className="tasks-list_table--header text-primary"
                >
                  Actions
                </th>
                <th
                  scope="col"
                  className="tasks-list_table--header text-primary"
                ></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6}>
                    <SpinningBlueCircleLoader />
                  </td>
                </tr>
              ) : list.length > 0 ? (
                list.map((item, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <td>
                        <div className="d-flex flex-row align-items-center gap-3">
                          <div>
                            {item.statusColor === "red" ? (
                              <svg
                                width="16"
                                height="15"
                                viewBox="0 0 16 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="8"
                                  cy="7.5"
                                  r="7.5"
                                  fill="#CF2A27"
                                />
                              </svg>
                            ) : item.statusColor === "orange" ? (
                              <svg
                                width="16"
                                height="15"
                                viewBox="0 0 16 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="8"
                                  cy="7.5"
                                  r="7.5"
                                  fill="#FF9900"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="16"
                                height="15"
                                viewBox="0 0 16 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="8"
                                  cy="7.5"
                                  r="7.5"
                                  fill="#097ad4"
                                />
                              </svg>
                            )}
                          </div>
                          {item.status}
                        </div>
                      </td>
                      <td>{item.task}</td>
                      <td>{item.datePosted}</td>
                      <td>{item.initiatedBy}</td>
                      <td>{item.dateDue}</td>
                      <td>
                        <Button
                          variant="primary"
                          className="btn-sm d-inline-flex rounded-3"
                        >
                          <i className="material-symbols-outlined me-0">
                            arrow_forward
                          </i>
                        </Button>{" "}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="border-0 bg-transparent"
                          onClick={() =>
                            showTaskHistory === idx
                              ? setShowTaskHistory(null)
                              : setShowTaskHistory(idx)
                          }
                        >
                          {showTaskHistory !== idx ? (
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.54311 4.35561C1.58665 4.31196 1.63838 4.27732 1.69533 4.25369C1.75228 4.23006 1.81333 4.2179 1.87498 4.2179C1.93664 4.2179 1.99769 4.23006 2.05464 4.25369C2.11159 4.27732 2.16332 4.31196 2.20686 4.35561L7.49998 9.64967L12.7931 4.35561C12.8367 4.31203 12.8884 4.27745 12.9454 4.25387C13.0023 4.23028 13.0633 4.21814 13.125 4.21814C13.1866 4.21814 13.2477 4.23028 13.3046 4.25387C13.3615 4.27745 13.4133 4.31203 13.4569 4.35561C13.5004 4.39919 13.535 4.45093 13.5586 4.50787C13.5822 4.56482 13.5943 4.62585 13.5943 4.68748C13.5943 4.74912 13.5822 4.81015 13.5586 4.86709C13.535 4.92404 13.5004 4.97578 13.4569 5.01936L7.83186 10.6444C7.78832 10.688 7.73659 10.7226 7.67964 10.7463C7.62269 10.7699 7.56164 10.7821 7.49998 10.7821C7.43833 10.7821 7.37728 10.7699 7.32033 10.7463C7.26338 10.7226 7.21165 10.688 7.16811 10.6444L1.54311 5.01936C1.49946 4.97582 1.46482 4.92409 1.44119 4.86714C1.41756 4.81019 1.4054 4.74914 1.4054 4.68748C1.4054 4.62583 1.41756 4.56478 1.44119 4.50783C1.46482 4.45088 1.49946 4.39915 1.54311 4.35561Z"
                                fill="#93929B"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.4569 10.6444C13.4133 10.688 13.3616 10.7227 13.3047 10.7463C13.2477 10.7699 13.1867 10.7821 13.125 10.7821C13.0634 10.7821 13.0023 10.7699 12.9454 10.7463C12.8884 10.7227 12.8367 10.688 12.7931 10.6444L7.50002 5.35033L2.20689 10.6444C2.16331 10.688 2.11157 10.7225 2.05463 10.7461C1.99768 10.7697 1.93665 10.7819 1.87502 10.7819C1.81338 10.7819 1.75235 10.7697 1.69541 10.7461C1.63846 10.7225 1.58672 10.688 1.54314 10.6444C1.49956 10.6008 1.46499 10.5491 1.4414 10.4921C1.41781 10.4352 1.40567 10.3742 1.40567 10.3125C1.40567 10.2509 1.41781 10.1899 1.4414 10.1329C1.46499 10.076 1.49956 10.0242 1.54314 9.98064L7.16814 4.35564C7.21168 4.31199 7.26341 4.27736 7.32036 4.25372C7.37731 4.23009 7.43836 4.21793 7.50002 4.21793C7.56167 4.21793 7.62272 4.23009 7.67967 4.25372C7.73662 4.27736 7.78835 4.31199 7.83189 4.35564L13.4569 9.98064C13.5005 10.0242 13.5352 10.0759 13.5588 10.1329C13.5824 10.1898 13.5946 10.2509 13.5946 10.3125C13.5946 10.3742 13.5824 10.4352 13.5588 10.4922C13.5352 10.5491 13.5005 10.6008 13.4569 10.6444Z"
                                fill="#93929B"
                              />
                            </svg>
                          )}
                        </button>
                      </td>
                    </tr>
                    {renderTaskListHistory(idx)}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No record found
                  </td>
                </tr>
              )}
              {/* {tableTasksLists.map((item, idx) => (
                <React.Fragment key={idx}>
                  <tr>
                    <td>
                      <div className="d-flex flex-row align-items-center gap-3">
                        <div>
                          {item.statusColor === "red" ? (
                            <svg
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="8" cy="7.5" r="7.5" fill="#CF2A27" />
                            </svg>
                          ) : item.statusColor === "orange" ? (
                            <svg
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="8" cy="7.5" r="7.5" fill="#FF9900" />
                            </svg>
                          ) : (
                            <svg
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="8" cy="7.5" r="7.5" fill="#097ad4" />
                            </svg>
                          )}
                        </div>
                        {item.status}
                      </div>
                    </td>
                    <td>{item.task}</td>
                    <td>{item.datePosted}</td>
                    <td>{item.initiatedBy}</td>
                    <td>{item.dateDue}</td>
                    <td>
                      <Button
                        variant="primary"
                        className="btn-sm d-inline-flex rounded-3"
                      >
                        <i className="material-symbols-outlined me-0">
                          arrow_forward
                        </i>
                      </Button>{" "}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="border-0 bg-transparent"
                        onClick={() =>
                          showTaskHistory === idx
                            ? setShowTaskHistory(null)
                            : setShowTaskHistory(idx)
                        }
                      >
                        {showTaskHistory !== idx ? (
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.54311 4.35561C1.58665 4.31196 1.63838 4.27732 1.69533 4.25369C1.75228 4.23006 1.81333 4.2179 1.87498 4.2179C1.93664 4.2179 1.99769 4.23006 2.05464 4.25369C2.11159 4.27732 2.16332 4.31196 2.20686 4.35561L7.49998 9.64967L12.7931 4.35561C12.8367 4.31203 12.8884 4.27745 12.9454 4.25387C13.0023 4.23028 13.0633 4.21814 13.125 4.21814C13.1866 4.21814 13.2477 4.23028 13.3046 4.25387C13.3615 4.27745 13.4133 4.31203 13.4569 4.35561C13.5004 4.39919 13.535 4.45093 13.5586 4.50787C13.5822 4.56482 13.5943 4.62585 13.5943 4.68748C13.5943 4.74912 13.5822 4.81015 13.5586 4.86709C13.535 4.92404 13.5004 4.97578 13.4569 5.01936L7.83186 10.6444C7.78832 10.688 7.73659 10.7226 7.67964 10.7463C7.62269 10.7699 7.56164 10.7821 7.49998 10.7821C7.43833 10.7821 7.37728 10.7699 7.32033 10.7463C7.26338 10.7226 7.21165 10.688 7.16811 10.6444L1.54311 5.01936C1.49946 4.97582 1.46482 4.92409 1.44119 4.86714C1.41756 4.81019 1.4054 4.74914 1.4054 4.68748C1.4054 4.62583 1.41756 4.56478 1.44119 4.50783C1.46482 4.45088 1.49946 4.39915 1.54311 4.35561Z"
                              fill="#93929B"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.4569 10.6444C13.4133 10.688 13.3616 10.7227 13.3047 10.7463C13.2477 10.7699 13.1867 10.7821 13.125 10.7821C13.0634 10.7821 13.0023 10.7699 12.9454 10.7463C12.8884 10.7227 12.8367 10.688 12.7931 10.6444L7.50002 5.35033L2.20689 10.6444C2.16331 10.688 2.11157 10.7225 2.05463 10.7461C1.99768 10.7697 1.93665 10.7819 1.87502 10.7819C1.81338 10.7819 1.75235 10.7697 1.69541 10.7461C1.63846 10.7225 1.58672 10.688 1.54314 10.6444C1.49956 10.6008 1.46499 10.5491 1.4414 10.4921C1.41781 10.4352 1.40567 10.3742 1.40567 10.3125C1.40567 10.2509 1.41781 10.1899 1.4414 10.1329C1.46499 10.076 1.49956 10.0242 1.54314 9.98064L7.16814 4.35564C7.21168 4.31199 7.26341 4.27736 7.32036 4.25372C7.37731 4.23009 7.43836 4.21793 7.50002 4.21793C7.56167 4.21793 7.62272 4.23009 7.67967 4.25372C7.73662 4.27736 7.78835 4.31199 7.83189 4.35564L13.4569 9.98064C13.5005 10.0242 13.5352 10.0759 13.5588 10.1329C13.5824 10.1898 13.5946 10.2509 13.5946 10.3125C13.5946 10.3742 13.5824 10.4352 13.5588 10.4922C13.5352 10.5491 13.5005 10.6008 13.4569 10.6444Z"
                              fill="#93929B"
                            />
                          </svg>
                        )}
                      </button>
                    </td>
                  </tr>
                  {renderTaskListHistory(idx)}
                </React.Fragment>
              ))} */}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TaskList;

import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetApiClient } from "../../api/useApiClient";

const useGetHouseListApi = (page, perPage, search) => {
  const [loading, setLoading] = useState(false);
  const [houseList, setHouseList] = useState([]);

  useEffect(() => {
    const getHouseList = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get(
          `/admin/configuration/house/list?perPage=${perPage}&currentPage=${page}&search=${search}`
        );
        if (data?.error) throw new Error(data?.error);
        setHouseList(data);
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    };

    getHouseList();
  }, [page, perPage, search]);

  return { loading, houseList };
};

export default useGetHouseListApi;

import React, { useEffect, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader, SpinningDynamicCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient, useUploadPictureApi } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../components/common/CustomSelect";
import ConfirmModal from "../../../components/common/ConfirmModal";
import defaultUser from "../../../assets/images/user/default_user-gray.png";
import { prodApiUrl, stageApiUrl } from "../../../utilities/constants";

const AddNewEvent = ({ details, setDetails, list, changesModal, setChangesModal, setShowAddNew }) => {

  const postingEvery = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const subCommitteeOptions = [{ value: "EVENT" }, { value: "ARC" }];
  const [loading, setLoading] = useState(false);
  const [pictureLoading, setPictureLoading] = useState(false);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });
  const [loadDelete, setLoadDelete] = useState(false);
  const [form, setForm] = useState({
    name: "",
    location: "",
    description: "",
    subCommittee: "",
    when: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    posting: "DAILY",
    postingDay: [],
    emailTemplateId: "",
    picture: null,
    volunteers: "",
    cancelEmailTemplateId: "",
    itemsNeeded: [""],
    servicesNeeded: [""],
  })

  const handleFormChange = (e, name, id) => {

    if (!changesModal.fields.find(f => f === name)) setChangesModal({ ...changesModal, fields: [...changesModal.fields, name] })

    if (name === "posting_daily") {
      setForm({
        ...form,
        posting: "DAILY",
        postingDay: [],
      })
    }
    else if (name === "posting_every") {
      setForm({
        ...form,
        posting: "EVERY"
      })
    }
    else if (name === "posting_day") {
      if (e.target.checked) {
        setForm({ ...form, postingDay: [...form.postingDay, id] })
      } else {
        setForm({ ...form, postingDay: form.postingDay.filter(item => item !== id) })
      }
    }
    else if (name === "subCommittee" || name === "emailTemplateId" || name === "cancelEmailTemplateId") {
      setForm({ ...form, [name]: e })
    }
    else if (name === "volunteers") {
      if (e.target.checked) {
        setForm({ ...form, volunteers: [...form.volunteers, id] })
      } else {
        setForm({ ...form, volunteers: form.volunteers.filter(item => item !== id) })
      }
    }
    else if (name === "servicesNeeded") {
      setForm({
        ...form,
        servicesNeeded: form.servicesNeeded.map((item, i) => {
          if (i === id) {
            if (e.target.name.includes("name")) return { ...item, name: e.target.value }
            else return { ...item, quantity: e.target.value }
          }
          else return item
        })
      })
    }
    else if (name === "itemsNeeded") {
      setForm({
        ...form,
        itemsNeeded: form.itemsNeeded.map((item, i) => {
          if (i === id) {
            if (e.target.name.includes("name")) return { ...item, name: e.target.value }
            else return { ...item, quantity: e.target.value }
          }
          else return item
        })
      })
    }
    else {
      setForm({ ...form, [name]: e.target.value })
    }
  }

  const handleAttachPicture = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    try {
      setPictureLoading(true);
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/event/upload/picture', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({ ...form, picture: { name: e.target.files[0].name, file: uploadedPicture?.directoryPath } });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      const inputfile = document.getElementsByName("picture")?.[0];
      inputfile.value = "";
      setPictureLoading(false);
    }
  }

  const handleDelete = async () => {
    setLoadDelete(true)
    try {
      const { data } = await useGetApiClient.delete(`/admin/configuration/voting/delete/${details?.id}`);
      if (data?.error) throw new Error(data?.error);
      // toast.success(data?.message);
      setOpenModal({ ...openModal, confirmDelete: false, delete: true })
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDelete(false);
    }
  }

  const handleSubmit = async ({ event, type }) => {
    event.preventDefault();

    if (form.posting === "EVERY" && form.postingDay.length === 0) {
      toast.error("Select at least 1 posting day.");
      return;
    }

    if (form.picture === null) {
      toast.error("Main picture is required.");
      return;
    }

    try {
      setLoading(true);
      const params = {
        name: form.name,
        location: form.location,
        description: form.description,
        subCommittee: form.subCommittee.value,
        when: form.when,
        start: `${form.startDate} ${form.startTime}:00`,
        end: `${form.endDate} ${form.endTime}:00`,
        posting: form.posting,
        postingDay: form.postingDay,
        emailTemplateId: form.emailTemplateId.id,
        volunteers: form.volunteers,
        cancelEmailTemplateId: form.cancelEmailTemplateId.id,
        picture: form.picture?.file,
        itemsNeeded: form.itemsNeeded?.map(item => ({ name: item.name, quantity: item.quantity })),
        servicesNeeded: form.servicesNeeded?.map(item => ({ name: item.name, quantity: item.quantity })),
      };

      // if (type === "add") {
      const { data } = await useGetApiClient.post('/admin/event/save',
        params
      );

      if (data?.error) throw new Error(data?.error);
      setOpenModal({ ...openModal, save: true })

      // } else {
      //   const { data } = await useGetApiClient.put(`admin/configuration/voting/update/${details?.id}`,
      //     params
      //   );
      //   if (data?.error) throw new Error(data?.error);
      //   setOpenModal({ ...openModal, save: true })

      // }
      setLoading(false);
      setChangesModal({ open: false, fields: [], targetTab: null })
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (details) {
      setForm({
        ...form,
        name: details?.name,
        description: details?.description,
        picture: details.picture?.includes(stageApiUrl)
          ? details.picture?.replaceAll(stageApiUrl, "")
          : details.picture?.includes(prodApiUrl)
            ? details.picture?.replaceAll(prodApiUrl, "")
            : defaultUser,
        posting: details.posting,
        postingDay: details.postingDay,
        postingEmailId: details.postingEmail,
      })
    }
  }, [details, list])

  return (
    <>
      <Form
        onSubmit={(event) => {
          handleSubmit({
            event,
            type: details ? "update" : "add"
          })
        }}
      >
        <Card className="config-tasks-list">
          <div className="config-tasks-list_header">
            <div className="d-flex flex-row justify-content-between w-100">
              <h5 className="text-primary">Events</h5>
              {details?.id &&
                <Button
                  className="btn btn-sm btn-danger-subtle d-flex align-items-center rounded-3"
                  style={{ border: "unset" }}
                  onClick={loadDelete ? () => { } : () => setOpenModal({ ...openModal, confirmDelete: true })}
                >
                  {loadDelete
                    ? <SpinningDynamicCircleLoader color="var(--bs-danger)" />
                    : <i className="material-symbols-outlined md-18">delete</i>
                  }
                </Button>
              }
            </div>
          </div>
          <Card.Body className="pt-0">
            <Row className="form-group align-self-center">
              <div className="d-flex flex-column justify-content-center">
                {!pictureLoading ? (
                  form.picture === null
                    ? <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "lightgray", width: "100%", height: "450px" }}>
                      <i className="material-symbols-outlined">photo_camera</i>
                    </div>
                    : <img
                      src={form.picture.file}
                      className="img-fluid"
                      alt="user"
                      loading="lazy"
                    />
                ) : <SpinningBlueCircleLoader />}
                <div className="d-flex flex-row gap-3 mt-4" style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <div className="d-flex align-items-center">
                    <label htmlFor="picture" className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                      <i className="material-symbols-outlined">attach_file</i>
                    </label>
                    <input
                      id="picture"
                      name="picture"
                      type="file"
                      accept="image/*"
                      className="d-none"
                      onChange={(e) => handleAttachPicture(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="border-0 bg-transparent d-flex align-items-center text-danger"
                    onClick={() => setForm({ ...form, picture: null })}
                  >
                    <i className="material-symbols-outlined">delete</i>
                  </button>
                </div>
              </div>

              <div style={{ width: 500, maxWidth: "100%" }}>
                <label className="control-label mb-2" htmlFor="form.title">Event Name</label>
                <input type="text" value={form.name} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />

                <label className="control-label mb-2" htmlFor="form.title">Event Location</label>
                <input type="text" value={form.location} className="form-control" id="location" name="location" placeholder="-" onChange={(e) => handleFormChange(e, "location")} required />

                <label className="control-label mb-2 mt-3" htmlFor="form.description">Description</label>
                <textarea
                  type="text"
                  value={form?.description ?? ""}
                  onChange={(e) => handleFormChange(e, "description")}
                  className="form-control"
                  id="description"
                  name="description"
                  placeholder="-"
                  style={{ minHeight: "100px" }}
                />

                <div className="d-flex gap-2 align-items-center mt-3">
                  <h6 className="text-primary" style={{ width: 160 }}>Sub-committee</h6>
                  <div className="w-100">
                    <CustomSelect
                      value={form.subCommittee}
                      onChange={(e) => handleFormChange(e, "subCommittee")}
                      options={subCommitteeOptions || []}
                      optLabelKey="value"
                      optValueKey="value"
                      placeholder="-"
                      required
                    />
                  </div>
                </div>

                <div className="d-flex gap-2 align-items-center mt-3">
                  <label style={{ width: 160 }} htmlFor="form.description">When</label>
                  <div style={{ width: "100%" }}>
                    <input
                      type="date"
                      value={form.when}
                      className="form-control"
                      id="startDate"
                      name="startDate"
                      placeholder="-"
                      onChange={e => handleFormChange(e, "when")}
                      required
                    />
                  </div>
                </div>
              </div>



              <h6 className="text-primary mb-3 mt-5">Events News Feed</h6>
              <div className="d-flex flex-row gap-5">
                <div>
                  <div className="d-flex gap-2 align-items-center">
                    <label style={{ width: 100 }} htmlFor="form.description">Start Date</label>
                    <div style={{ width: "100%" }}>
                      <input
                        type="date"
                        value={form.startDate}
                        className="form-control"
                        id="startDate"
                        name="startDate"
                        placeholder="-"
                        onChange={e => handleFormChange(e, "startDate")}
                        required
                      />
                    </div>
                  </div>

                  <div className="d-flex gap-2 align-items-center mt-3">
                    <label style={{ width: 100 }} htmlFor="form.description">Start Time</label>
                    <div style={{ width: "100%" }}>
                      <input
                        type="time"
                        value={form.startTime}
                        className="form-control"
                        id="startTime"
                        name="startTime"
                        placeholder="-"
                        onChange={e => handleFormChange(e, "startTime")}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex gap-2 align-items-center">
                    <label style={{ width: 100 }} htmlFor="form.description">End Date</label>
                    <div style={{ width: "100%" }}>
                      <input
                        type="date"
                        value={form.endDate}
                        className="form-control"
                        id="endDate"
                        name="endDate"
                        placeholder="-"
                        onChange={e => handleFormChange(e, "endDate")}
                        required
                      />
                    </div>
                  </div>

                  <div className="d-flex gap-2 align-items-center mt-3">
                    <label style={{ width: 100 }} htmlFor="form.description">End Time</label>
                    <div style={{ width: "100%" }}>
                      <input
                        type="time"
                        value={form.endTime}
                        className="form-control"
                        id="endTime"
                        name="endTime"
                        placeholder="-"
                        onChange={e => handleFormChange(e, "endTime")}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Form.Check className="d-block mb-0">
                    <Form.Check.Input
                      type="radio"
                      name="posting_daily"
                      id="posting_daily"
                      checked={form.posting === "DAILY"}
                      onChange={(e) => handleFormChange(e, "posting_daily")}
                    />{" "}
                    <Form.Check.Label>
                      Repost daily
                    </Form.Check.Label>
                  </Form.Check>

                  <Form.Check className="d-block mb-0 mt-2">
                    <Form.Check.Input
                      type="radio"
                      name="posting_every"
                      id="posting_every"
                      checked={form.posting === "EVERY"}
                      onChange={(e) => handleFormChange(e, "posting_every")}
                    />{" "}
                    <Form.Check.Label>
                      Repost every
                    </Form.Check.Label>
                  </Form.Check>

                  <div className="d-flex align-items-center gap-3 mt-2 mb-2">
                    {postingEvery.map((item, index) => (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        label={item}
                        name={`postingDay_${item}`}
                        checked={form.postingDay.includes(item)}
                        onChange={(e) => handleFormChange(e, "posting_day", item)}
                        disabled={form.posting === "DAILY"}
                      />
                    ))}
                  </div>


                  <CustomSelect
                    value={form.emailTemplateId}
                    onChange={(e) => handleFormChange(e, "emailTemplateId")}
                    options={list?.emailTemplates || []}
                    optLabelKey="notificationId"
                    optValueKey="id"
                    placeholder="Email template"
                    required
                  />
                </div>
              </div>

              <h6 className="text-primary mb-3 mt-5">Volunteers</h6>
              <Form.Check
                type="checkbox"
                label="Homeowner"
                name="volunteer_homeowner"
                checked={form.volunteers.includes("HOMEOWNER")}
                onChange={(e) => handleFormChange(e, "volunteers", "HOMEOWNER")}
              />
              <Form.Check
                type="checkbox"
                label="Board Member"
                name="volunteer_board"
                checked={form.volunteers.includes("BOARD")}
                onChange={(e) => handleFormChange(e, "volunteers", "BOARD")}
              />
              <Form.Check
                type="checkbox"
                label="Property Managers"
                name="volunteer_property"
                checked={form.volunteers.includes("PROPERTY")}
                onChange={(e) => handleFormChange(e, "volunteers", "PROPERTY")}
              />

              <h6 className="text-primary mb-3 mt-5">Items Needed</h6>
              {form.itemsNeeded.map((item, index) => (
                <Row key={index} className="form-group align-items-end">
                  {index === 0
                    ? <i className="material-symbols-outlined filled text-primary w-auto mb-2 cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, itemsNeeded: [...form.itemsNeeded, { name: null, quantity: null }] })}>add_box</i>
                    : <div style={{ width: "41px" }} />
                  }
                  <div className="col-sm-8 col-md-5">
                    <label className="control-label mb-2" htmlFor={`form.itemsNeeded[${index}].name`}>Name</label>
                    <input type="text" value={form.itemsNeeded[index].name} className="form-control" id={`form.itemsNeeded[${index}].name`} name={`form.itemsNeeded[${index}].name`} placeholder="-" onChange={(e) => handleFormChange(e, "itemsNeeded", index)} required={form.itemsNeeded[index].quantity} />
                  </div>
                  <div className="col-sm-8 col-md-5">
                    <label className="control-label mb-2" htmlFor={`form.itemsNeeded[${index}].quantity`}>Quantity</label>
                    <input type="number" value={form.itemsNeeded[index].quantity} className="form-control" id={`form.itemsNeeded[${index}].quantity`} name={`form.itemsNeeded[${index}].quantity`} placeholder="-" onChange={(e) => handleFormChange(e, "itemsNeeded", index)} required={form.itemsNeeded[index].name} />
                  </div>
                  {form.itemsNeeded.length > 1 &&
                    <i className="material-symbols-outlined text-danger w-auto mb-2 cursor-pointer" onClick={() => setForm({ ...form, itemsNeeded: form.itemsNeeded.filter((_, i) => i !== index) })}>delete</i>
                  }
                </Row>
              ))}

              <h6 className="text-primary mb-3 mt-5">Services Needed</h6>
              {form.servicesNeeded.map((item, index) => (
                <Row key={index} className="form-group align-items-end">
                  {index === 0
                    ? <i className="material-symbols-outlined filled text-primary w-auto mb-2 cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, servicesNeeded: [...form.servicesNeeded, { name: null, quantity: null }] })}>add_box</i>
                    : <div style={{ width: "41px" }} />
                  }
                  <div className="col-sm-8 col-md-5">
                    <label className="control-label mb-2" htmlFor={`form.servicesNeeded[${index}].name`}>Name</label>
                    <input type="text" value={form.servicesNeeded[index].name} className="form-control" id={`form.servicesNeeded[${index}].name`} name={`form.servicesNeeded[${index}].name`} placeholder="-" onChange={(e) => handleFormChange(e, "servicesNeeded", index)} required={form.servicesNeeded[index].quantity} />
                  </div>
                  <div className="col-sm-8 col-md-5">
                    <label className="control-label mb-2" htmlFor={`form.servicesNeeded[${index}].quantity`}>Quantity</label>
                    <input type="number" value={form.servicesNeeded[index].quantity} className="form-control" id={`form.servicesNeeded[${index}].quantity`} name={`form.servicesNeeded[${index}].quantity`} placeholder="-" onChange={(e) => handleFormChange(e, "servicesNeeded", index)} required={form.servicesNeeded[index].name} />
                  </div>
                  {form.servicesNeeded.length > 1 &&
                    <i className="material-symbols-outlined text-danger w-auto mb-2 cursor-pointer" onClick={() => setForm({ ...form, servicesNeeded: form.servicesNeeded.filter((_, i) => i !== index) })}>delete</i>
                  }
                </Row>
              ))}

              <div className="d-flex gap-2 align-items-center mt-3">
                <label style={{ width: 150 }}>Cancel this event</label>
                <div className="w-100">
                  <CustomSelect
                    value={form.cancelEmailTemplateId}
                    onChange={(e) => handleFormChange(e, "cancelEmailTemplateId")}
                    options={list?.emailTemplates || []}
                    optLabelKey="notificationId"
                    optValueKey="id"
                    placeholder="Email template"
                    required
                  />
                </div>
                {/* {details?.id &&
                  <button type="button" className="btn btn-primary" onClick={handleCancel} style={{ width: 150 }}>
                    {!cancelLoading ? 'Cancel' : <SpinningWhiteCircleLoader />}
                  </button>
                } */}
              </div>
            </Row>
          </Card.Body>
        </Card>

        <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2">
          <button type="submit" className="btn btn-primary" style={{ width: "30%" }}>
            {!loading ? 'Save' : <SpinningWhiteCircleLoader />}
          </button>
        </Form.Check>

      </Form >

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.delete}
        onHide={() => setOpenModal({ ...openModal, delete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Successfully deleted."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-danger w-100" onClick={() => {
            setOpenModal({ ...openModal, delete: false });
            setShowAddNew(false);
            setDetails(null);
          }}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.confirmDelete}
        onHide={() => setOpenModal({ ...openModal, confirmDelete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Are you sure you want to delete?"
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-danger w-50" disabled={loadDelete} onClick={handleDelete}>
            {loadDelete ? <SpinningWhiteCircleLoader /> : "Yes"}
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setOpenModal({ ...openModal, confirmDelete: false })}>
            No
          </button>
        }
      />
    </>
  );
}

export default AddNewEvent;
import { Card, Button, Form, Stack } from "react-bootstrap";
import { useDebounce } from "use-debounce";
import useGetHouseListApi from "../../../hooks/system-configuration/useGetHouseListApi";
import { SpinningBlueCircleLoader } from "../../../components/common/loaders";
import Pagination from "../../../components/common/pagination";
import { toast } from "react-toastify";
import { useState } from "react";
import { useGetApiClient } from "../../../api/useApiClient";

const HouseList = ({ setShowAddNewHouse, setHouseDetails }) => {
  const [loadNewTab, setLoadNewTab] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [debouncedValue] = useDebounce(searchInput, 500);
  const { loading, houseList } = useGetHouseListApi(
    page,
    perPage,
    debouncedValue
  );

  const handleTableRowClick = async (id) => {
    setLoadNewTab(true);
    try {
      const { data } = await useGetApiClient.get(
        `/admin/configuration/house/get/${id}`
      );
      if (data?.error) throw new Error(data?.error);
      setHouseDetails(data);
      setShowAddNewHouse(true);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadNewTab(false);
    }
  };

  const renderContentContainer = (content) => {
    return (
      <div className="d-flex align-items-center" style={{ height: "50px" }}>
        {content}
      </div>
    );
  };

  const renderText = (text) => {
    if (!text) return renderContentContainer("-");
    return renderContentContainer(text);
  };

  const renderColorCode = (colorCode) => {
    if (!colorCode) return renderContentContainer("-");
    return (
      <div className="d-flex align-items-center" style={{ height: "50px" }}>
        <div
          className="py-1 px-2 rounded-2 text-white"
          style={{ backgroundColor: colorCode, fontSize: "12px" }}
        >
          {colorCode}
        </div>
      </div>
    );
  };

  const renderImage = (imageUrl) => {
    if (!imageUrl) return renderContentContainer("-");
    return (
      <img
        src={imageUrl}
        className="d-flex align-items-center rounded-circle"
        style={{ height: "50px", width: "50px" }}
      />
    );
  };

  const renderHouseListRows = () => {
    if (loadNewTab)
      return (
        <tr>
          <td colSpan={6}>
            <SpinningBlueCircleLoader />
          </td>
        </tr>
      );
    return houseList?.houses?.map((house, idx) => (
      <tr
        key={idx}
        onClick={() => handleTableRowClick(house?.id)}
        style={{ cursor: "pointer" }}
      >
        <td name="picture">{renderImage(house?.picture)}</td>
        <td name="houseName">{renderText(house?.houseName)}</td>
        <td name="status">{renderText(house?.status)}</td>
        <td name="colorCode" className="d-flex">
          {renderColorCode(house?.colorCode)}
        </td>
        <td name="longLat">{renderText(house?.longLat)}</td>
        <td naxme="action">
          {/* <div className="d-flex align-items-center" style={{height: '50px'}}>
                            <button
                                type="button"
                                className="bg-danger-subtle py-1 d-flex align-items-center border-0 text-danger rounded-3"
                                onClick={() => handleDeleteBtn(house?.id)}
                            >
                                <i className="material-symbols-outlined">delete</i>
                            </button>
                        </div> */}
          <Form.Check
            className="d-flex align-items-center form-switch form-check-inline"
            style={{ height: "50px" }}
          >
            <Form.Check.Input
              type="checkbox"
              className="bg-primary"
              id="customSwitch01"
              defaultChecked
            />
          </Form.Check>
        </td>
      </tr>
    ));
  };

  const renderHouseList = () => {
    if (houseList?.houses?.length < 1)
      return (
        <tr>
          <td colSpan={6} className="text-center">
            No record found
          </td>
        </tr>
      );
    return renderHouseListRows();
  };

  const renderHouseListTable = () => {
    if (loading) return <SpinningBlueCircleLoader />;
    return (
      <table className="config-tasks-list_table table">
        <thead>
          <tr>
            <th
              scope="col"
              className="config-tasks-list_table--header text-primary"
            >
              -
            </th>
            <th
              scope="col"
              className="config-tasks-list_table--header text-primary"
            >
              House Name
            </th>
            <th
              scope="col"
              className="config-tasks-list_table--header text-primary"
            >
              Status
            </th>
            <th
              scope="col"
              className="config-tasks-list_table--header text-primary"
            >
              Color Code
            </th>
            <th
              scope="col"
              className="config-tasks-list_table--header text-primary"
            >
              Long-Lat
            </th>
            <th
              scope="col"
              className="config-tasks-list_table--header text-primary"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>{renderHouseList()}</tbody>
      </table>
    );
  };

  return (
    <Card className="config-tasks-list">
      <div className="config-tasks-list_header">
        <div className="d-flex flex-row justify-content-between w-100">
          <h5 className="text-primary">House</h5>
          <span className="table-add float-end mb-3 me-2">
            <Button
              className="btn btn-sm btn-success d-flex align-items-center rounded-3"
              onClick={() => setShowAddNewHouse(true)}
            >
              <i className="material-symbols-outlined me-1 md-18">add</i>
              Add House
            </Button>
          </span>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <form
            className="config-tasks-list_header--input"
            style={{ width: "45%" }}
          >
            <div
              className="d-flex flex-row align-items-center gap-2"
              style={{ width: "100%" }}
            >
              <div className="" style={{ marginRight: "1rem", width: "100px" }}>
                HOA Name
              </div>
              <input
                type="text"
                placeholder="-"
                className=" w-100 border border-light rounded-3 px-2 py-1"
                style={{ width: "100% !important" }}
              />
              <button
                type="submit"
                className="bg-primary rounded-3 text-white border-0 d-flex align-items-center py-1"
              >
                <i className="material-symbols-outlined">save</i>
              </button>
            </div>
          </form>
          <div style={{ position: "relative", width: 250 }}>
            <svg
              style={{
                position: "absolute",
                left: 16,
                top: "50%",
                transform: "translateY(-50%)",
              }}
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="7.82491"
                cy="7.82495"
                r="6.74142"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></circle>
              <path
                d="M12.5137 12.8638L15.1567 15.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <input
              style={{ paddingLeft: 40 }}
              placeholder="Search for..."
              onChange={(e) => setSearchInput(e.target.value)}
              type="text"
              class="text search-input form-control bg-light-subtle form-control"
            />
          </div>
        </div>
      </div>
      <Card.Body className="pt-0">
        <div className="table-responsive">{renderHouseListTable()}</div>
        <div className="pt-4">
          <Pagination
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            totalCount={houseList?.count ?? 0}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default HouseList;

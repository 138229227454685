import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container, Card } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGetApiClient } from "../../../api/useApiClient";
import { useLocation } from "react-router-dom";
import { CustomSelect } from "../../../components/common/CustomSelect";
import ConfirmModal from "../../../components/common/ConfirmModal";

const IssuesAndOtherRequestsStatus = () => {
  const [detail, setDetails] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [comments, setComments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [status, setStatus] = useState({ value: detail?.status || "" });
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const requestId = params.get("id");

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response = await useGetApiClient.get(
          `/admin/request/other-request/get/${requestId}`
        );
        if (response.status === 200) {
          setDetails(response.data);
          setStatus({ value: response.data.status })
        } else {
          console.error("Error fetching request details:", response);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getDetails();
  }, [requestId]);

  const getComment = async (page = 0) => {
    try {
      const response = await useGetApiClient.get(
        `/admin/request/other-request/comment/get/${requestId}?perPage=10&currentPage=${page}`
      );
      if (response.status === 200) {
        setComments(response.data.comments);
        setTotalPages(Math.ceil(response.data.count / 10));
      } else {
        console.error("Error fetching comments data:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getComment(currentPage);
  }, [currentPage]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleChangeStatus = async (e) => {
    if (e.value === "RESOLVED" || (e.value === "IN-PROGRESS" && detail?.status !== "IN-PROGRESS")) {
      try {
        const params = {
          status: e.value
        }
        const { data } = await useGetApiClient.put(`/admin/request/other-request/update/${detail?.id}`,
          params
        );

        if (data?.error) throw new Error(data?.error);
        // toast.success(savedNotification?.message);
        setStatus(e);
        setOpenModal({ ...openModal, save: true })
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error?.message);
      }
    }
  }


  const handleSubmit = async () => {
    if (isSubmitting) return; // Prevent multiple clicks
    setIsSubmitting(true);

    try {
      if (!editorContent.trim()) {
        toast.error("Please add a comment before submitting.");
        setIsSubmitting(false);
        return;
      }

      if (!selectedFile) {
        toast.error("Please upload a file before submitting.");
        setIsSubmitting(false);
        return;
      }

      // Upload the file
      const formData = new FormData();
      formData.append("file", selectedFile);
      const uploadResponse = await useGetApiClient.post(
        "/admin/request/other-request/upload/document",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // Save the comment
      const cleanComment = editorContent.replace(/<\/?[^>]+(>|$)/g, "").trim();
      const requestBody = {
        otherRequestId: requestId,
        comment: cleanComment,
        documents: [
          {
            name: selectedFile.name,
            file: uploadResponse.data.directoryPath,
          },
        ],
      };
      await useGetApiClient.post("/admin/request/other-request/comment/save", requestBody);

      toast.success("Comment and file saved successfully!");
      setEditorContent(""); // Clear editor
      setSelectedFile(null); // Clear file selection

      setFileInputKey(Date.now()); // Reset file input
      getComment(currentPage); // Refresh comments
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to submit the comment. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setEditorContent("");
    setSelectedFile(null);
    setFileInputKey(Date.now());
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  return (
    <>
      <div id="content-page">
        {
          (detail,
            comments ? (
              <Row>
                {/* <Col sm="12">
                    <Card
                      className="position-relative inner-page-bg bg-primary"
                      style={{ height: "150px" }}
                    >
                      <div className="inner-page-title">
                        <h3 className="text-white">Issues & Other Requests</h3>
                        <p className="text-white">{detail?.name || ""}</p>
                      </div>
                    </Card>
                  </Col> */}
                <Col sm="12" lg="6">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title text-primary">
                          Tracking Number: {detail?.referenceNumber || ""}
                        </h4>
                      </div>
                    </Card.Header>
                    <Card.Body className="pt-0">
                      <Row className="form-group">
                        <Col>
                          <Form.Group className="form-group">
                            <Form.Label>Detailed Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              disabled
                              value={detail?.description || ""}
                            />
                          </Form.Group>

                          <Form.Group className="form-group">
                            <Form.Label>Material Needed</Form.Label>
                            <Form.Control
                              as="textarea"
                              disabled
                              value={detail?.materials || ""}
                            />
                          </Form.Group>
                        </Col>
                        <hr />
                        <Form.Group className="form-group">
                          <Form.Label>Activity & Comment</Form.Label>
                          <ReactQuill
                            theme="snow"
                            placeholder="Add comments here"
                            value={editorContent}
                            onChange={setEditorContent}
                          />
                        </Form.Group>

                        <Form.Group className="form-group">
                          <Form.Label>Choose File</Form.Label>
                          <Form.Control
                            type="file"
                            key={fileInputKey}
                            onChange={handleFileChange}
                          />
                        </Form.Group>

                        <Col className="mb-2">
                          <Button
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </Button>{" "}
                          <Button variant="danger" onClick={handleCancel}>
                            Cancel
                          </Button>
                        </Col>
                        <hr />
                        <Col>
                          <p>History</p>
                          {comments?.length > 0
                            ? <>
                              <Row>
                                <ul className="list-inline ms-1">
                                  {comments.map((comment) => (
                                    <li key={comment.id} className="pt-4">
                                      <div className="d-flex align-items-center ">
                                        <div className="avatar px-2">
                                          <img
                                            src={comment.picture}
                                            alt={`${comment.name}'s profile`}
                                            className="avatar-circle bg-warning text-white"
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          />{" "}
                                        </div>
                                        <div className="ms-1 w-100">
                                          <div className="d-flex text-nowrap flex-row justify-content-between w-100">
                                            <h6>{comment.name}</h6>
                                            <small className="">
                                              {new Date(
                                                comment.createdAt
                                              ).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit",
                                              })}
                                            </small>
                                          </div>
                                          <p className="pt-1">{comment.comment}</p>
                                          {comment.documents &&
                                            comment.documents.length > 0 && (
                                              <ul className="list-inline">
                                                {comment.documents.map((doc) => (
                                                  <li key={doc.id}>
                                                    <a
                                                      href={doc.file}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {doc.name}
                                                    </a>
                                                  </li>
                                                ))}
                                              </ul>
                                            )}
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </Row>
                              <div className="d-flex justify-content-between align-items-center mt-3">
                                <Button
                                  onClick={handlePreviousPage}
                                  disabled={currentPage === 0}
                                >
                                  Previous
                                </Button>
                                <span>
                                  Page {currentPage + 1} of {totalPages}
                                </span>
                                <Button
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages - 1}
                                >
                                  Next
                                </Button>
                              </div>
                            </>
                            : <div className="d-flex justify-content-center">No record found</div>
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="12" lg="6">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title text-primary">Details</h4>
                      </div>
                    </Card.Header>
                    <Card.Body className="pt-0">
                      <Form.Group className="form-group">
                        <Form.Label>Assigned</Form.Label>
                        <div className="form-control text-primary">{detail?.assigned}</div>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Status</Form.Label>
                        {(detail?.status !== "IN-PROGRESS" && detail?.status !== "NEW")
                          ? <input type="text" value={detail?.status ?? ""} className="form-control" id="status" name="status" placeholder="-" readOnly />
                          : <CustomSelect
                            value={status}
                            onChange={(e) => {
                              handleChangeStatus(e);
                            }}
                            options={detail?.status === "NEW"
                              ? [{ value: "NEW" }, { value: "IN-PROGRESS" }, { value: "RESOLVED" }]
                              : [{ value: "IN-PROGRESS" }, { value: "RESOLVED" }]
                            }
                            optLabelKey="value"
                            optValueKey="value"
                            placeholder="-"
                          />
                        }
                        {/* <Form.Select disabled>
                            <option>{detail?.status || ""}</option>
                          </Form.Select> */}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Tracking</Form.Label>
                        <Form.Control
                          type="text"
                          value={detail?.referenceNumber || ""}
                          disabled
                        ></Form.Control>
                      </Form.Group>
                      {/* <Form.Group className="form-group">
                          <Form.Label>Homeowner</Form.Label>
                          <Form.Control
                            type="text"
                            value={detail?.homeowner || ""}
                            disabled
                          ></Form.Control>
                        </Form.Group> */}
                      <Form.Group className="form-group">
                        <Form.Label>Date Submitted</Form.Label>
                        <Form.Control
                          type="date"
                          value={detail?.createdAt || ""}
                          disabled
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={detail?.startDate || ""}
                          disabled
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={detail?.endDate || ""}
                          disabled
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Documents</Form.Label>
                        {(detail?.documents?.length > 0)
                          ? <div className="form-control" style={{ width: "100%", display: "flex", flexFlow: "wrap", }}>
                            {detail.documents?.map((doc, idx) => (
                              <div
                                key={idx}
                                className="rounded-3 bg-danger"
                                style={{ width: "fit-content", color: "white", padding: "5px 8px", margin: "0 5px 5px 0px", overflow: "auto" }}
                              // onClick={() => handleSelectView(doc)}
                              >
                                <a
                                  style={{ color: "white" }}
                                  href={doc.file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {doc.name}
                                </a>
                              </div>
                            ))}
                          </div>
                          : <div className="d-flex justify-content-center">No record found</div>
                        }
                      </Form.Group>
                      {/* <Form.Group className="form-group">
                          <Form.Label>Watchers</Form.Label>
                          {detail?.watchers?.map((watcher, index) => (
                            <Form.Check
                              key={index}
                              label={watcher}
                              type="checkbox"
                              checked
                              disabled
                            ></Form.Check>
                          ))}
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>File Input</Form.Label>
                          <Form.Control type="file"></Form.Control>
                        </Form.Group> */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              <p>Loading...</p>
            ))
        }
        <ToastContainer />
      </div>
      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />
    </>
  );
};

export default IssuesAndOtherRequestsStatus;

import React, { useState, useEffect } from "react";
import { useGetApiClient } from "../../api/useApiClient";
import Card from "../../components/Card";
import { toast } from "react-toastify";
import { SpinningBlueCircleLoader } from "../../components/common/loaders";

const UpcomingEvents = () => {
  const [loading, setLoading] = useState(false);
  const [eventList, setEventList] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await useGetApiClient.get(
        "/admin/dashboard/get/upcoming-event"
      );
      if (data?.error) throw new Error(data?.error);

      const newDate = new Date();
      let newData = [];

      data.forEach((item, key) => {
        if (newData.length < 4) {
          const itemDate = new Date(item.startDate);
          if (itemDate > newDate) {
            const month = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            newData.push({
              ...item,
              dateDay: itemDate.getDate(),
              dateMonth: month[itemDate.getMonth()],
            });
          }
        }
      });

      setEventList(newData);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title fw-bold text-black">Upcoming Events</h4>
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        <ul className="m-0 p-0 job-classification list-inline d-flex flex-column gap-3">
          {loading ? (
            <li className="d-flex flex-row align-items-center gap-4">
              <SpinningBlueCircleLoader />
            </li>
          ) : eventList.length > 0 ? (
            eventList.map((item, key) => (
              <li
                className="d-flex flex-row align-items-center gap-4"
                key={key}
              >
                <div className="d-flex flex-column bg-primary rounded-3 py-2 px-3">
                  <p className="text-white m-0 fw-bold">{item.dateDay}</p>
                  <span className="text-white m-0 fw-bold">
                    {item.dateMonth}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <h5 className="fw-bold">{item.name}</h5>
                  <span className="">{item.otherDesc}</span>
                </div>
              </li>
            ))
          ) : (
            <li className="d-flex flex-row align-items-center gap-4">
              No Upcoming Events
            </li>
          )}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default UpcomingEvents;

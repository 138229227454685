export const stringToURL = (str) => {
  return str
    .toLowerCase()                // Convert to lowercase
    .replace(/\s+/g, '-')          // Replace spaces with hyphens
    .replace(/[^a-z0-9\-]/g, '');  // Remove any other special characters
}

export const isValidJson = (string) => {
  try {
    JSON.parse(string);
    return true;
  } catch (e) {
    if (e instanceof SyntaxError) {
      return false;
    }
    console.log(e);
    return false;
  }
};
import { useEffect, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import Pagination from "../../../components/common/pagination";
import NewsIcon from "../../../assets/images/icon/news.svg";
import * as moment from "moment";
import AddNewBlog from "./addNewBlog";

const BlogsList = ({ showAddNew, setShowAddNew, details, setDetails, changesModal, setChangesModal }) => {

  const [loading, setLoading] = useState(false);
  const [submitCommentLoading, setSubmitCommentLoading] = useState(false);
  const [list, setList] = useState([]);
  const [loadDetail, setLoadDetail] = useState(false);
  const [loadStatus, setLoadStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedNews, setSelectedNews] = useState(null);

  const handleClickItem = async (item) => {
    setLoadDetail(true)
    try {
      setSelectedNews(item.id);
      const { data } = await useGetApiClient.get(`/admin/blog/get/${item.id}`);
      if (data?.error) throw new Error(data?.error);
      setDetails(data);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDetail(false);
    }
  }

  const handleUpdateStatus = async (item) => {
    try {
      setLoadStatus(true);
      const { data } = await useGetApiClient.put(`/admin/blog/update/status/${item.id}`,
        {
          status: item.status === "ONGOING" ? "CLOSED" : "SENT",
        }
      );
      if (data?.error) throw new Error(data?.error);
      getlist();
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadStatus(false);
    }
  }

  const handleSubmitComment = async (e) => {
    e.preventDefault();

    try {
      setSubmitCommentLoading(true);
      console.log(e);

      const { data: dataComment } = await useGetApiClient.post('/admin/blog/comment/save',
        {
          blogId: details?.id,
          comment: e.target.comment.value
        }
      );

      if (dataComment?.error) throw new Error(data?.error);
      const { data } = await useGetApiClient.get(`/admin/blog/get/${details?.id}`);
      if (data?.error) throw new Error(data?.error);
      setDetails(data);
      toast.success(dataComment?.message);
      const inputfile = document.getElementsByName("comment")?.[0];
      inputfile.value = "";
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setSubmitCommentLoading(false);
    }
  }

  const getlist = async () => {
    setLoading(true);
    try {
      const { data } = await useGetApiClient.get(`/admin/blog/list?perPage=${perPage}&currentPage=${page}`);
      if (data?.error) throw new Error(data?.error);
      setList(data || [])
      data?.blogs?.length > 0 && handleClickItem(data?.blogs?.[0]);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getlist();
  }, [showAddNew, page, perPage]);

  return (
    <>
      {!showAddNew
        ? <>
          <Card className="config-tasks-list mt-3">
            <Card.Header>
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <div className="d-flex flex-row gap-4">
                  <h5
                    className="text-primary"
                  // onClick={() => setArchived(false)}
                  >
                    Blogs
                  </h5>
                  {/* <h5
                    className={`${archived ? 'text-decoration-underline fw-bold' : ''} text-primary ms-2`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setArchived(true)}
                  >
                    Archive
                  </h5> */}
                </div>
                <Button
                  className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                  disabled={loading}
                  onClick={() => setShowAddNew(true)}
                >
                  <i className="material-symbols-outlined me-1 md-18">add</i>
                  Create Blogs
                </Button>
              </div>
            </Card.Header>
          </Card>
          <div className="d-flex flex-row gap-4">
            <div style={{ width: "350px", paddingBottom: "1.5rem" }}>
              <div style={{ maxHeight: 500, overflowY: "auto" }}>
                {(loading)
                  ? <Card style={{ padding: 20 }}><SpinningBlueCircleLoader /></Card>
                  : (list?.blogs?.length > 0)
                    ? list?.blogs?.map((item, index) => (
                      // <Card key={index} onClick={() => handleClickArc(item)} style={{ cursor: "pointer" }}>
                      <Card key={index} style={{ backgroundColor: selectedNews === item.id ? "var(--bs-primary-bg-subtle)" : "white" }}>
                        <div className="d-flex flex-row align-items-center justify-content-between gap-1 p-4">
                          <div className="d-flex flex-row align-items-center gap-3 cursor-pointer" onClick={() => handleClickItem(item)}>
                            <img src={NewsIcon} style={{ filter: index % 3 === 0 ? "unset" : index % 3 === 1 ? "brightness(0) saturate(100%) invert(65%) sepia(39%) saturate(1152%) hue-rotate(214deg) brightness(99%) contrast(104%)" : "brightness(0) saturate(100%) invert(79%) sepia(14%) saturate(1790%) hue-rotate(334deg) brightness(103%) contrast(100%)" }} />
                            <div>
                              <div className="me-1" style={{ color: index % 3 === 0 ? "#F7685B" : index % 3 === 1 ? "#D592FF" : "#FFBA68" }}>{item.name}</div>
                              <div className="me-1" style={{ color: index % 3 === 0 ? "#F7685B" : index % 3 === 1 ? "#D592FF" : "#FFBA68", fontSize: "10px" }}>{moment.isMoment(item.sentAt) ? moment(item.sentAt).format("MMMM DD, YYYY") : item.sentAt}</div>
                            </div>
                          </div>
                          <i className="material-symbols-outlined me-1 md-18 text-primary cursor-pointer" onClick={() => handleUpdateStatus(item)}>{item.status === "ONGOING" ? "cell_tower" : item.status === "NEW" ? "send" : "door_front"}</i>
                        </div>
                      </Card>
                    ))
                    : <Card style={{ padding: 20 }}>No record found</Card>
                }
              </div>
              <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} showRowsPerPage={false} totalCount={list?.count ?? 0} />
            </div>
            <Card className="config-tasks-list" style={{ width: "100%" }}>
              <Card.Body>
                {(loading || loadDetail)
                  ? <SpinningBlueCircleLoader />
                  : <div className="col-sm-7 d-flex flex-column justify-content-center w-100">
                    <img
                      src={details?.documents?.find(doc => doc.type === "MAIN")?.file}
                      className="img-fluid"
                      alt="user"
                      loading="lazy"
                    />
                  </div>
                }
              </Card.Body>
            </Card>
          </div>
          <Card className="config-tasks-list" style={{ width: "100%" }}>
            <Card.Body>
              <h5 className="text-primary mb-3">Latest Blogs</h5>
              {(loading || loadDetail)
                ? <SpinningBlueCircleLoader />
                : <>
                  <div className="d-flex flex-row gap-4 pb-2 mb-4" style={{ borderBottom: "1px solid #ccc" }}>
                    <div className="d-flex flex-row gap-2">
                      <i className="material-symbols-outlined me-1 md-18">calendar_month</i>
                      <div>{details?.sentDayCount} days</div>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <i className="material-symbols-outlined me-1 md-18">thumb_up</i>
                      <div>{details?.likeCount} likes</div>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <i className="material-symbols-outlined me-1 md-18">chat_bubble</i>
                      <div>{details?.commentCount} comments</div>
                    </div>
                  </div>
                  <div>{details?.description}</div>
                </>
              }
            </Card.Body>
          </Card>
          <Card className="config-tasks-list" style={{ width: "100%" }}>
            <Card.Body>
              <h5 className="text-primary mb-3">User Comments</h5>
              {(loading || loadDetail)
                ? <SpinningBlueCircleLoader />
                : details?.comments?.length > 0
                  ? <>
                    {details?.comments?.map((comment, index) => (
                      <div key={index} className="mb-5">
                        <div className="d-flex flex-row gap-3">
                          <div
                            className="position-relative rounded-circle bg-light"
                            style={{
                              height: '60px',
                              width: '60px',
                              backgroundImage: `url(${comment?.user?.picture})`,
                              backgroundSize: 'cover',
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                          <div className="d-flex flex-column justify-content-center">
                            <div className="fw-bold">{comment?.user?.house?.houseName}</div>
                            <div className="fw-bold">{comment?.user?.name}</div>
                          </div>
                        </div>
                        <div className="d-flex flex-row gap-3">
                          <div
                            className="position-relative"
                            style={{
                              width: '60px',
                            }}
                          />
                          <div className="ml-2">{comment?.comment}</div>
                        </div>
                      </div>
                    ))}
                    {submitCommentLoading && <SpinningBlueCircleLoader />}
                  </>
                  : <div className="text-center">No record found</div>
              }
              <Form onSubmit={handleSubmitComment}>
                <h5 className="text-primary mb-3">Your Comments</h5>
                <textarea
                  type="text"
                  className="form-control"
                  id="comment"
                  name="comment"
                  placeholder="-"
                  style={{ minHeight: "100px" }}
                  required
                />
                <div style={{ textAlign: "right" }}>
                  <button type="submit" className="btn btn-primary mt-3" style={{ width: "200px" }}>
                    {!submitCommentLoading ? 'Submit' : <SpinningWhiteCircleLoader />}
                  </button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </>
        : <AddNewBlog list={list} changesModal={changesModal} setChangesModal={setChangesModal} setShowAddNew={setShowAddNew} />
      }
    </>
  )
}

export default BlogsList;
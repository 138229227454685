import React, { useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

// Images
import Logo from "../../../assets/images/BE-logo.png";
import login1 from "../../../assets/images/login/login_image.png";
import { useGetApiClient } from "../../../api/useApiClient";
import { SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { toast } from "react-toastify";

const Recoverpw = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { data: forgotPassword } = await useGetApiClient.put(`/general/user/forgot-password`,
        {
          username: e.target.email.value,
          portal: "ADMIN"
        }
      )
      if (forgotPassword?.error) throw new Error(forgotPassword?.error);
      toast.success(forgotPassword?.message);
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-success w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1"></div>
              <div className="sign-in-detail container-inside-top">
                <div className="list-inline m-0 p-0">
                  <img
                    src={login1}
                    className="signin-img img-fluid mb-5 rounded-3 w-75 h-75"
                    alt="images"
                  />
                  <h2 className="mb-3 text-white fw-semibold">
                    Welcome to Benevento East
                  </h2>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <img src={Logo} fluid className="w-50" alt="logo" />
                <Form className="mt-5" onSubmit={handleSubmit}>
                  <p style={{ textTransform: "none", textAlign: "left", marginTop: 70 }}>
                    Enter your email address and we'll send you an email with instructions to reset your password.
                  </p>
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Email Address</h6>
                    <Form.Control
                      type="text"
                      className="form-control mb-0"
                      placeholder="Email Address"
                      required
                      name="email"
                    />
                  </Form.Group>
                  <Button
                    variant="success"
                    type="submit"
                    className="btn btn-success mt-4 fw-semibold text-uppercase w-100"
                  >
                    {loading ?
                      <SpinningWhiteCircleLoader />
                      : 'Reset Password'}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Recoverpw;

import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Modal, ModalHeader, ModalTitle, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader, SpinningDynamicCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient, useUploadPictureApi } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../components/common/CustomSelect";
import ConfirmModal from "../../../components/common/ConfirmModal";

const AddNewArc = ({ arcDetails, setArcDetails, arcList, changesModal, setChangesModal, setShowAddNewArc }) => {

  const fileInputRefs = useRef([]);
  const watcherOptions = arcList?.users?.map(item => ({ ...item, name: `${item.firstName} ${item.lastName}` }))
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [flowModal, setFlowModal] = useState({ open: false, index: null });
  const [step, setStep] = useState(1);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });
  const [loadDelete, setLoadDelete] = useState(false);
  const [form, setForm] = useState({
    name: "",
    form_requirements: [{ name: null, file: null }],
    reconsiderations: [""],
    service: [{ name: null, rate: null }],
    emailTemplateId: "",
    watcher: [],
    reFile: "",
    assessmentDay: "",
    sendEmail: false,
    sendEmailTemplateId: "",
    flow: [{
      name: "New",
      state: { value: "NEW" },
      emailTemplateId: null,
    }, {
      name: "Approved",
      state: { value: "APPROVED" },
      emailTemplateId: "",
    }, {
      name: "Disapproved",
      state: { value: "DISAPPROVED" },
      emailTemplateId: "",
    }],
  })

  const handleFileChange = async (e, index) => {
    setFileLoading(true);
    const formData = new FormData();
    const file = e.target.files[0]
    formData.append('file', file);

    try {
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/configuration/arc/upload/document', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({
        ...form,
        form_requirements: form.form_requirements.map((item, i) => {
          if (i === index) return { name: file.name, file: uploadedPicture?.directoryPath }
          else return item
        })
      })
      setFileLoading(false);
      const inputfile = document.getElementsByName(`form_requirements[${index}]`)?.[0];
      inputfile.value = "";
    } catch (error) {
      toast.error(error?.message);
      setFileLoading(false);
    }
  }

  const handleFormChange = (e, name, index) => {

    if (!changesModal.fields.find(f => f === name)) setChangesModal({ ...changesModal, fields: [...changesModal.fields, name] })

    // if (name === "form_requirements") {
    //   const file = e.target.files[0];
    //   if (file) {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       setForm({
    //         ...form,
    //         form_requirements: form.form_requirements.map((item, i) => {
    //           if (i === index) return { name: file.name, file: reader.result }
    //           else return item
    //         })
    //       })
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // }

    if (name === "reconsiderations") {
      setForm({
        ...form,
        reconsiderations: form.reconsiderations.map((item, i) => {
          if (i === index) return e.target.value
          else return item
        })
      })
    }

    else if (name === "service") {
      setForm({
        ...form,
        service: form.service.map((item, i) => {
          if (i === index) {
            if (e.target.name.includes("name")) return { ...item, name: e.target.value }
            else return { ...item, rate: e.target.value }
          }
          else return item
        })
      })
    }

    else if (name === "watcher" || name === "sendEmailTemplateId" || name === "emailTemplateId") {
      setForm({ ...form, [name]: e })
    }

    else if (name === "sendEmail") {
      setForm({ ...form, [name]: e.target.checked })
    }

    else {
      setForm({ ...form, [name]: e.target.value })
    }
  }

  const FlowModal = (props) => {
    const flowIndex = flowModal.index;
    const [flowForm, setFlowForm] = useState({
      name: flowIndex !== null ? form.flow[flowIndex].name : "",
      state: flowIndex !== null ? form.flow[flowIndex].state : "",
      approvers: (flowIndex !== null && form.flow[flowIndex]) ? form.flow[flowIndex]?.approvers : arcList?.userRoles?.map(item => ({ ...item, check: false, users: "" })),
      percentage: flowIndex !== null ? form.flow[flowIndex].percentage : "",
      reNotify: flowIndex !== null ? form.flow[flowIndex].reNotify : "",
      sendEmail: flowIndex !== null ? (form.flow[flowIndex].sendEmail ?? false) : false,
      emailTemplateId: flowIndex !== null ? form.flow[flowIndex].emailTemplateId : "",
    });

    const handleFlowFormOnChange = (e, index, type) => {
      const name = e?.target?.name;
      const value = e?.target?.value;

      if (type === "emailTemplateId" || type === "state") {
        setFlowForm({ ...flowForm, [type]: e })
      }

      else if (type === "check" || type === "users") {
        setFlowForm({
          ...flowForm,
          approvers: flowForm.approvers.map((item, i) => {
            if (i === index) {
              if (type === "check") return { ...item, users: "", check: e.target.checked }
              else return { ...item, users: e }
            }
            else return item
          })
        })
      }

      else if (type === "sendEmail") {
        setFlowForm({ ...flowForm, [type]: e.target.checked, emailTemplateId: "" })
      }

      else setFlowForm({ ...flowForm, [name]: value });
    }

    const handleFlowSubmit = async (event) => {
      event.preventDefault();

      var array = form.flow;
      array[flowIndex] = flowForm;
      setForm({ ...form, flow: array });
      setFlowModal({ open: false, index: null });
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form
          onSubmit={handleFlowSubmit}
        >
          {flowForm.name &&
            <ModalHeader>
              <ModalTitle className="text-primary">{flowForm.name}</ModalTitle>
            </ModalHeader>
          }

          <Modal.Body className="mt-3">
            {(flowIndex === 0 || flowIndex === form.flow.length - 1 || flowIndex === form.flow.length - 2)
              ? <Row className="form-group">
                <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="mailer">State</label>
                <div className="col-sm-3">
                  <CustomSelect
                    name="state"
                    value={flowForm.state}
                    onChange={(e) => handleFlowFormOnChange(e, null, "state")}
                    options={[{ value: "NEW" }, { value: "APPROVED" }, { value: "DISAPPROVED" }]}
                    optLabelKey="value"
                    optValueKey="value"
                    placeholder="-"
                    required
                  />
                </div>
                <div className="col-sm-1" />
                <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="mailer">Email</label>
                <div className="col-sm-4">
                  <CustomSelect
                    value={flowForm.emailTemplateId}
                    onChange={(e) => handleFlowFormOnChange(e, null, "emailTemplateId")}
                    options={arcList?.emailTemplates || []}
                    optLabelKey="notificationId"
                    optValueKey="id"
                    placeholder="Select Email Template..."
                    required
                  />
                </div>
              </Row>
              : <>
                <Row className="form-group">
                  <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="mailer">Process Flow Name</label>
                  <div className="col-sm-8">
                    <input type="text" value={flowForm.name} className="form-control" id="name" name="name" placeholder="-" onChange={handleFlowFormOnChange} required />
                  </div>
                </Row>
                <Row className="form-group">
                  {arcList.userRoles.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="col-sm-4 align-self-center mb-5" style={{ marginTop: index === 0 ? 40 : "unset" }}>
                        <Form.Check
                          type="checkbox"
                          label={item.name}
                          name="approvers"
                          checked={flowForm.approvers[index].check}
                          onChange={(e) => handleFlowFormOnChange(e, index, "check")}
                        />
                      </div>
                      <div className="col-sm-8 align-self-center mb-5" style={{ marginTop: index === 0 ? 40 : "unset" }}>
                        <CustomSelect
                          name="approvers"
                          value={flowForm.approvers[index].users}
                          onChange={(e) => handleFlowFormOnChange(e, index, "users")}
                          options={item?.users?.map(u => ({ ...u, name: `${u.firstName} ${u.lastName}` })) || []}
                          optLabelKey="name"
                          optValueKey="userId"
                          placeholder="-"
                          isDisabled={!flowForm.approvers[index].check}
                          required={flowForm.approvers[index].check}
                        />
                        <input
                          type="text"
                          defaultValue={flowForm.approvers?.filter(appr => appr.users)}
                          style={{
                            position: "absolute",
                            opacity: 0,
                            pointerEvents: 'none',
                            height: 0,
                            width: 0,
                          }}
                          required
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </Row>
                <Row className="form-group">
                  <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="">Percentage of Approvers (Set as approved)</label>
                  <div className="col-sm-2">
                    <input type="number" value={flowForm.percentage} onChange={handleFlowFormOnChange} className="form-control" id="percentage" name="percentage" placeholder="-" required />
                  </div>
                  {/* <div className="col-sm-1" /> */}
                  <div className="col-sm-2 align-self-center">
                    <Form.Check
                      type="checkbox"
                      label="Send email"
                      name="sendEmail"
                      checked={flowForm.sendEmail}
                      onChange={(e) => handleFlowFormOnChange(e, null, "sendEmail")}
                    />
                  </div>
                  <div className="col-sm-4">
                    <CustomSelect
                      value={flowForm.emailTemplateId}
                      onChange={(e) => handleFlowFormOnChange(e, null, "emailTemplateId")}
                      options={arcList?.emailTemplates || []}
                      optLabelKey="notificationId"
                      optValueKey="id"
                      placeholder="Select Email Template..."
                      isDisabled={!flowForm.sendEmail}
                      required={flowForm.sendEmail}
                    />
                  </div>
                </Row>
                <Row className="form-group">
                  <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="">Days to re-notify approver (If no response)</label>
                  <div className="col-sm-2">
                    <input type="number" value={flowForm.reNotify} onChange={handleFlowFormOnChange} className="form-control" id="reNotify" name="reNotify" placeholder="-" required />
                  </div>
                </Row>
              </>
            }
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2">
              <Button variant="primary" type="submit">
                Save
              </Button>
              {(flowIndex !== 0 && flowIndex !== form.flow.length - 1 && flowIndex !== form.flow.length - 2) &&
                <Button variant="danger" onClick={() => {
                  setFlowModal({ open: false, index: null });
                  setForm({ ...form, flow: form.flow.filter((_, fIndex) => fIndex !== flowIndex) })
                }}>
                  Delete
                </Button>
              }
            </Form.Check>
          </Modal.Footer>
        </Form>
      </Modal >
    );
  }

  const handleDelete = async () => {
    setLoadDelete(true)
    try {
      const { data } = await useGetApiClient.delete(`/admin/configuration/arc/delete/${arcDetails?.id}`);
      if (data?.error) throw new Error(data?.error);
      // toast.success(data?.message);
      setOpenModal({ ...openModal, confirmDelete: false, delete: true })
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDelete(false);
    }
  }

  const handleSubmitStep1 = async (event) => {
    event.preventDefault();
    setStep(step + 1);
  }

  const handleSubmit = async ({ event, type }) => {
    event.preventDefault();

    try {
      setLoading(true);
      const params = {
        name: form.name,
        forms: form.form_requirements.filter(form => form.name),
        reconsiderations: form.reconsiderations.filter(rec => rec),
        services: form.service.filter(ser => ser.name),
        emailTemplateId: form.emailTemplateId.id,
        process: {
          reFile: form.reFile,
          assessmentDay: form.assessmentDay,
          sendEmail: form.sendEmail ?? false,
          emailTemplateId: form.sendEmail ? form.sendEmailTemplateId.id : undefined,
          watchers: form.watcher.map(fl => fl.userId),
          flows: form.flow.filter((_, index) => (index !== 0 && index !== form.flow.length - 1 && index !== form.flow.length - 2)).map(fl => ({
            name: fl.name,
            percentage: fl.percentage,
            reNotify: fl.reNotify,
            sendEmail: fl.sendEmail,
            emailTemplateId: fl.sendEmail ? fl.emailTemplateId.id : undefined,
            // approvers: fl.approvers.filter(appr => appr.users.length > 0).flatMap(item => item.users.map(user => user.userId)),
            approvers: fl.approvers.filter(appr => appr.users).map(appr => ({ roleId: appr.roleId, userId: appr.users.userId })),
          })),
          fixedFlows: form.flow.filter((_, index) => (index === 0 || index === form.flow.length - 1 || index === form.flow.length - 2)).map(fl => ({
            status: fl.state.value,
            emailTemplateId: fl?.emailTemplateId?.id
          })),
        }
      };

      if (type === "add") {
        const { data: savedArc } = await useGetApiClient.post('/admin/configuration/arc/save',
          params
        );

        if (savedArc?.error) throw new Error(savedArc?.error);
        // toast.success(savedArc?.message);
        setOpenModal({ ...openModal, save: true })

      } else {
        const { data: updatedNotification } = await useGetApiClient.put(`admin/configuration/arc/update/${arcDetails?.id}`,
          params
        );
        if (updatedNotification?.error) throw new Error(updatedNotification?.error);
        // toast.success(updatedNotification?.message);
        setOpenModal({ ...openModal, save: true })

      }
      setLoading(false);
      setChangesModal({ open: false, fields: [], targetTab: null })
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (arcDetails) {
      setForm({
        ...form,
        name: arcDetails?.name,
        form_requirements: arcDetails?.forms,
        reconsiderations: arcDetails?.reconsiderations,
        service: arcDetails?.services,
        emailTemplateId: arcDetails?.emailTemplate,
        watcher: arcDetails?.process?.watchers?.map(item => ({ ...item, userId: item.id, name: `${item.firstName} ${item.lastName}` })),
        reFile: arcDetails?.process?.reFile,
        assessmentDay: arcDetails?.process?.assessmentDay,
        sendEmail: arcDetails?.process?.sendEmail,
        sendEmailTemplateId: arcDetails?.process?.emailTemplate,
        flow: arcDetails?.process?.flows?.map((fl, flIndex) => {
          const isFix = flIndex === 0 || flIndex === arcDetails?.process?.flows.length - 1 || flIndex === arcDetails?.process?.flows.length - 2;
          return {
            ...fl,
            approvers: !isFix
              ? arcList?.userRoles?.map(item => {
                const roleExists = fl.approvers.find(appr => appr.role.id === item.roleId);
                if (roleExists) return { ...item, check: true, users: { ...roleExists.user, userId: roleExists.user.id, name: `${roleExists.user.firstName} ${roleExists.user.lastName}` } }
                return { ...item, check: false, users: "" }
              })
              : arcList?.userRoles?.map(item => ({ ...item, check: false, users: [] })),
            state: isFix ? { value: fl.name.toUpperCase() } : "",
            emailTemplateId: fl.emailTemplate,
          }
        })
      })
    }
  }, [arcDetails, arcList])

  return (
    <>
      <Form
        onSubmit={(event) => {
          if (step === 1) {
            handleSubmitStep1(event);
          } else {
            handleSubmit({
              event,
              type: arcDetails ? "update" : "add"
            })
          }
        }}
      >
        <Card className="config-tasks-list">
          <div className="config-tasks-list_header">
            <div className="d-flex flex-row justify-content-between w-100">
              <h5 className="text-primary">ARC</h5>
              <Button
                className="btn btn-sm btn-danger-subtle d-flex align-items-center rounded-3"
                style={{ border: "unset" }}
                onClick={loadDelete ? () => { } : () => setOpenModal({ ...openModal, confirmDelete: true })}
              >
                {loadDelete
                  ? <SpinningDynamicCircleLoader color="var(--bs-danger)" />
                  : <i className="material-symbols-outlined md-18">delete</i>
                }
              </Button>
            </div>
          </div>
          <Card.Body className="pt-0">

            {step === 1
              ? <>
                <Row className="form-group align-items-center">
                  <div className="col-sm-8 col-md-5">
                    <label className="control-label mb-2" htmlFor="name">ARC Project Name</label>
                    <input type="text" value={form.name} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>

                <h6 className="text-primary mb-3">Form Requirements</h6>
                {form.form_requirements.map((item, index) => (
                  <Row className="form-group align-items-center" key={index}>
                    {index === 0
                      ? <i className="material-symbols-outlined filled text-primary w-auto cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, form_requirements: [...form.form_requirements, { name: null, file: null }] })}>add_box</i>
                      : <div style={{ width: "41px" }} />
                    }
                    <div className="col-sm-8 col-md-5" style={{ height: 41 }}>
                      <div className="form-control" style={{ minHeight: "41px" }}>
                        {item.name &&
                          <div className="d-flex flex-row align-items-center justify-content-between gap-1 rounded-3 bg-danger" style={{ width: "fit-content", color: "white", padding: "5px 8px" }}>
                            <span>{item.name}</span>
                            {/* {form.form_requirements.length > 1 && */}
                            <i className="material-symbols-outlined w-auto cursor-pointer" onClick={() => setForm({
                              ...form, form_requirements: form.form_requirements.map((item, i) => {
                                if (i === index) return { name: null, file: null }
                                else return item
                              })
                            })}>close</i>
                            {/* } */}
                          </div>
                        }
                      </div>
                      <input
                        type="file"
                        disabled={fileLoading}
                        ref={(el) => (fileInputRefs.current[index] = el)}
                        style={{ opacity: 0, pointerEvents: 'none', height: 0, width: 0 }}
                        name={`form_requirements[${index}]`}
                        onChange={(e) => handleFileChange(e, index)}
                      />
                    </div>
                    {fileLoading ?
                      <div style={{ width: 42 }}>
                        <SpinningBlueCircleLoader />
                      </div> :
                      <i className="material-symbols-outlined text-primary w-auto cursor-pointer" onClick={() => fileInputRefs.current[index].click()}>upload</i>
                    }
                  </Row>
                ))}

                <h6 className="text-primary mb-3 mt-5">Notes for Reconsideration</h6>
                {form.reconsiderations.map((item, index) => (
                  <Row key={index} className="form-group align-items-center">
                    {index === 0
                      ? <i className="material-symbols-outlined filled text-primary w-auto cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, reconsiderations: [...form.reconsiderations, ""] })}>add_box</i>
                      : <div style={{ width: "41px" }} />
                    }
                    <div className="col-sm-8 col-md-5">
                      <input type="text" value={form.reconsiderations[index]} className="form-control" id={`form.reconsiderations[${index}]`} name={`form.reconsiderations[${index}]`} placeholder="-" onChange={(e) => handleFormChange(e, "reconsiderations", index)} />
                    </div>
                    {form.reconsiderations.length > 1 &&
                      <i className="material-symbols-outlined text-danger w-auto cursor-pointer" onClick={() => setForm({ ...form, reconsiderations: form.reconsiderations.filter((_, i) => i !== index) })}>delete</i>
                    }
                  </Row>
                ))}

                <h6 className="text-primary mb-3 mt-5">Service</h6>
                {form.service.map((item, index) => (
                  <Row key={index} className="form-group align-items-end">
                    {index === 0
                      ? <i className="material-symbols-outlined filled text-primary w-auto mb-2 cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, service: [...form.service, { name: null, rate: null }] })}>add_box</i>
                      : <div style={{ width: "41px" }} />
                    }
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.service[${index}].name`}>Name</label>
                      <input type="text" value={form.service[index].name} className="form-control" id={`form.service[${index}].name`} name={`form.service[${index}].name`} placeholder="-" onChange={(e) => handleFormChange(e, "service", index)} required={form.service[index].rate} />
                    </div>
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.service[${index}].rate`}>Rate</label>
                      <input type="number" value={form.service[index].rate} className="form-control" id={`form.service[${index}].rate`} name={`form.service[${index}].rate`} placeholder="-" onChange={(e) => handleFormChange(e, "service", index)} required={form.service[index].name} />
                    </div>
                    {form.service.length > 1 &&
                      <i className="material-symbols-outlined text-danger w-auto mb-2 cursor-pointer" onClick={() => setForm({ ...form, service: form.service.filter((_, i) => i !== index) })}>delete</i>
                    }
                  </Row>
                ))}

                <h6 className="text-primary mb-3 mt-5">Email Notification</h6>
                <div className="col-sm-8 col-md-5">
                  <label className="control-label mb-2" htmlFor="form.emailTemplateId">Email Template</label>
                  <CustomSelect
                    value={form.emailTemplateId}
                    onChange={(e) => handleFormChange(e, "emailTemplateId")}
                    options={arcList?.emailTemplates || []}
                    optLabelKey="notificationId"
                    optValueKey="id"
                    placeholder="-"
                    required
                  />
                </div>
              </>
              : <>
                <Row className="form-group">
                  <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="">Watchers</label>
                  <div className="col-sm-4">
                    <CustomSelect
                      value={form.watcher}
                      isMulti
                      onChange={(e) => handleFormChange(e, "watcher")}
                      options={watcherOptions || []}
                      optLabelKey="name"
                      optValueKey="userId"
                      placeholder="-"
                    />
                  </div>
                  <div className="col-sm-1" />
                  <label className="control-label col-sm-3 align-self-center mb-0" htmlFor="">Days to re-file disapproved ARC</label>
                  <div className="col-sm-2 align-self-center">
                    <input type="number" value={form.reFile} onChange={(e) => handleFormChange(e, "reFile")} className="form-control" id="reFile" name="reFile" placeholder="-" required />
                  </div>
                </Row>
                <Row className="form-group">
                  <div className="col-sm-2 align-self-center">
                    <Form.Check
                      type="checkbox"
                      label="Send email"
                      checked={form.sendEmail}
                      onChange={(e) => handleFormChange(e, "sendEmail")}
                    />
                  </div>
                  <div className="col-sm-4">
                    <CustomSelect
                      value={form.sendEmailTemplateId}
                      onChange={(e) => handleFormChange(e, "sendEmailTemplateId")}
                      options={arcList?.emailTemplates || []}
                      optLabelKey="notificationId"
                      optValueKey="id"
                      placeholder="Select Email Template..."
                      isDisabled={!form.sendEmail}
                      required={form.sendEmail}
                    />
                  </div>
                  <div className="col-sm-1" />
                  <label className="control-label col-sm-3 align-self-center mb-0" htmlFor="">Total assessment days (notify)</label>
                  <div className="col-sm-2">
                    <input type="number" value={form.assessmentDay} onChange={(e) => handleFormChange(e, "assessmentDay")} className="form-control" id="assessmentDay" name="assessmentDay" placeholder="-" required />
                  </div>
                </Row>

                <h6 className="text-primary mb-3 mt-5">Create Process Flow</h6>
                <div className="form-control">
                  <Row className="form-group pt-5">
                    {form.flow.map((item, index) => (
                      <React.Fragment key={index}>
                        <div className="col-sm-3 mb-4 align-self-center">
                          <Card className="bg-warning mb-0">
                            <div className="d-flex flex-row align-items-center justify-content-between gap-1 p-4">
                              <div className="me-1 text-white" style={{ whiteSpace: "pre-wrap", maxWidth: "80%" }}>{item.name}</div>
                              <div>
                                <i
                                  className="material-symbols-outlined filled text-white w-auto cursor-pointer"
                                  style={{ fontSize: "27px" }}
                                  onClick={() => setFlowModal({ open: true, index: index })}
                                >
                                  settings
                                </i>
                                <input
                                  type="text"
                                  defaultValue={(index === 0 || index === form.flow.length - 1 || index === form.flow.length - 2) ? form.flow[index]?.emailTemplateId : form.flow[index]}
                                  style={{
                                    position: "absolute",
                                    marginLeft: -17,
                                    marginTop: 22,
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    height: 0,
                                    width: 0,
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </Card>
                        </div>
                        {(index !== form.flow.length - 1 && index !== form.flow.length - 2) &&
                          <div className="w-auto align-self-center">
                            <i
                              className="material-symbols-outlined filled align-self-center text-primary cursor-pointer mb-4"
                              style={{ fontSize: "27px" }}
                              onClick={() => {
                                var array = form.flow;
                                array.splice(index + 1, 0, "");
                                setForm({ ...form, flow: array })
                              }}
                            >
                              add_box
                            </i>
                            {/* <input
                              type="text"
                              defaultValue={form.flow.length > 3 ? true : ""}
                              style={{
                                position: "absolute",
                                marginLeft: -17,
                                marginTop: 22,
                                opacity: 0,
                                pointerEvents: 'none',
                                height: 0,
                                width: 0,
                              }}
                              required
                            /> */}
                          </div>
                        }
                        {index !== form.flow.length - 1 &&
                          <i
                            className="material-symbols-outlined col-sm-1 text-gray align-self-center w-auto mb-4"
                          >
                            arrow_right_alt
                          </i>
                        }
                      </React.Fragment>
                    ))}
                  </Row>
                </div>

              </>
            }
          </Card.Body>
        </Card>

        <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2">
          {step > 1 &&
            <>
              <button className="btn btn-primary" style={{ width: "30%" }} onClick={() => setStep(step - 1)}>
                {!loading ? 'Previous' : <SpinningWhiteCircleLoader />}
              </button>
              <button type="submit" className="btn btn-primary" style={{ width: "30%" }}>
                {!loading ? 'Save' : <SpinningWhiteCircleLoader />}
              </button>
            </>
          }
          {step === 1 &&
            <button type="submit" className="btn btn-primary" style={{ width: "30%" }}>
              {!loading ? 'Next' : <SpinningWhiteCircleLoader />}
            </button>
          }
        </Form.Check>

      </Form >

      <FlowModal
        show={flowModal.open}
        onHide={() => setFlowModal({ open: false, index: null })}
      />

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.delete}
        onHide={() => setOpenModal({ ...openModal, delete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Successfully deleted."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-danger w-100" onClick={() => {
            setOpenModal({ ...openModal, delete: false });
            setShowAddNewArc(false);
            setArcDetails(null);
          }}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.confirmDelete}
        onHide={() => setOpenModal({ ...openModal, confirmDelete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Are you sure you want to delete?"
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-danger w-50" disabled={loadDelete} onClick={handleDelete}>
            {loadDelete ? <SpinningWhiteCircleLoader /> : "Yes"}
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setOpenModal({ ...openModal, confirmDelete: false })}>
            No
          </button>
        }
      />
    </>
  );
}

export default AddNewArc;
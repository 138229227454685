import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";

// images
import card1 from "../../assets/images/small/img-1.jpg";
import house from "../../assets/images/user/1.jpg";

// react-slick
import Slider from "react-slick";
import "../../assets/scss/screens/dashboard/house-list.scss";
import "../../assets/scss/screens/dashboard/tasks-list.scss";

//full calender
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import * as moment from "moment";
import useDashboardStore from "../../zustand/useDashboardStore";
import useGetDashboardHomeownersApi from "../../hooks/dashboard/useGetDashboardHomeownersApi";
import { SpinningBlueCircleLoader } from "../../components/common/loaders";
import TaskList from "./TaskList";
import UpcomingEvents from "./UpcomingEvents";
import BadgesComponent from "./BadgesComponent";
import Calendar from "./Calendar";

const Index = () => {
  const { setActiveTab } = useDashboardStore();
  const [open, setOpen] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();

  const [loadContent, setLoadContent] = useState(true);

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  const { loading, homeOwners, badges } = useGetDashboardHomeownersApi();

  useEffect(() => {
    function handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setTimeout(() => {
          setLoadContent(false);
        }, 2000);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow:
      homeOwners?.homeowners.length > 6 ? 6 : homeOwners?.homeowners.length,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleOnClickedThumbnail = (homeOwner) => {
    setActiveTab("homeowners");
    navigate(`/user-management?tab=homeowners`, {
      state: { homeOwner },
    });
    // window.location.href = "/user-management?tab=homeowners";
  };

  if (loading)
    return (
      <div className="pt-5">
        <SpinningBlueCircleLoader />
      </div>
    );
  return (
    <>
      <Row className="gx-4">
        <Col lg={12}>
          <div className="house-list-container">
            <Slider {...settings}>
              {homeOwners?.homeowners?.map((homeowner) => (
                <div
                  key={homeowner.id}
                  className="house-list-item"
                  onClick={() => handleOnClickedThumbnail(homeowner)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={homeowner?.house?.picture}
                    alt={homeowner?.house?.streetName}
                    className="house-list-img"
                  />
                  <div className="house-list-name">
                    {homeowner?.house?.streetNumber}{" "}
                    {homeowner?.house?.streetName}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Col>

        <Col md="8" lg="8">
          <Card>
            <Card.Body>
              <Calendar />
            </Card.Body>
          </Card>
          {/* insert here */}
          <TaskList />
        </Col>

        <Col md="4" lg="4">
          <UpcomingEvents />
          <BadgesComponent setActiveTab={setActiveTab} badges={badges} />
        </Col>
      </Row>
    </>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        right: "-25px",
      }}
      onClick={onClick}
    >
      <svg
        width="8"
        height="18"
        viewBox="0 0 8 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.307396 1.0575C0.365453 0.999296 0.434423 0.953117 0.510354 0.921609C0.586286 0.890101 0.667687 0.873882 0.749896 0.873882C0.832105 0.873882 0.913507 0.890101 0.989438 0.921609C1.06537 0.953117 1.13434 0.999296 1.1924 1.0575L8.6924 8.5575C8.7506 8.61556 8.79678 8.68453 8.82829 8.76046C8.85979 8.83639 8.87601 8.91779 8.87601 9C8.87601 9.08221 8.85979 9.16361 8.82829 9.23954C8.79678 9.31547 8.7506 9.38444 8.6924 9.4425L1.1924 16.9425C1.07504 17.0599 0.915866 17.1258 0.749896 17.1258C0.583927 17.1258 0.424754 17.0599 0.307396 16.9425C0.190038 16.8251 0.124107 16.666 0.124107 16.5C0.124107 16.334 0.190038 16.1749 0.307396 16.0575L7.36615 9L0.307396 1.9425C0.249192 1.88444 0.203013 1.81547 0.171505 1.73954C0.139997 1.66361 0.123779 1.58221 0.123779 1.5C0.123779 1.41779 0.139997 1.33639 0.171505 1.26046C0.203013 1.18453 0.249192 1.11556 0.307396 1.0575Z"
          fill="#E5E5E5"
        />
      </svg>
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        left: "-25px",
      }}
      onClick={onClick}
    >
      <svg
        width="8"
        height="18"
        viewBox="0 0 8 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.69252 1.0575C7.75072 1.11556 7.7969 1.18453 7.82841 1.26046C7.85992 1.33639 7.87613 1.41779 7.87613 1.5C7.87613 1.58221 7.85992 1.66361 7.82841 1.73954C7.7969 1.81547 7.75072 1.88444 7.69252 1.9425L0.633768 9L7.69252 16.0575C7.80988 16.1749 7.87581 16.334 7.87581 16.5C7.87581 16.666 7.80988 16.8251 7.69252 16.9425C7.57516 17.0599 7.41599 17.1258 7.25002 17.1258C7.08405 17.1258 6.92488 17.0599 6.80752 16.9425L-0.692482 9.4425C-0.750686 9.38444 -0.796865 9.31547 -0.828373 9.23954C-0.859881 9.16361 -0.876099 9.08221 -0.876099 9C-0.876099 8.91779 -0.859881 8.83639 -0.828373 8.76046C-0.796865 8.68453 -0.750686 8.61556 -0.692482 8.5575L6.80752 1.0575C6.86557 0.999296 6.93454 0.953117 7.01048 0.921609C7.08641 0.890101 7.16781 0.873882 7.25002 0.873882C7.33223 0.873882 7.41363 0.890101 7.48956 0.921609C7.56549 0.953117 7.63446 0.999296 7.69252 1.0575Z"
          fill="#E5E5E5"
        />
      </svg>
    </div>
  );
};

export default Index;

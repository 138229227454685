import React, { useState } from "react";
import {
  Card,
  Button,
  Col,
  Row,
} from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../../components/common/loaders";
import useGetNotificationListApi from "../../../hooks/system-configuration/useGetNotificationListApi";
import AddNewNotification from "./addNewNotification";
import EmailIcon from "../../../assets/images/icon/mail.png";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";

const NotificationsList = ({ showAddNewNotification, setShowAddNewNotification, notificationDetails, setNotificationDetails }) => {

  const [searchInput, setSearchInput] = useState("");
  const [search, setSearch] = useState(false);
  const { loading, notificationList } = useGetNotificationListApi(search, searchInput, showAddNewNotification);
  const [loadNotifDetail, setLoadNotifDetail] = useState(false);

  const handleClickNotif = async (item) => {
    setLoadNotifDetail(true)
    try {
      const { data } = await useGetApiClient.get(`/admin/configuration/notification/get/${item.id}`);
      if (data?.error) throw new Error(data?.error);
      setNotificationDetails({ ...data, canDelete: item.canDelete });
      setShowAddNewNotification(true);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadNotifDetail(false);
    }
  }

  return (
    <React.Fragment>
      {!showAddNewNotification
        ? <>
          <Card className="config-tasks-list">
            <div className="config-tasks-list_header">
              <div className="d-flex flex-row justify-content-between w-100">
                <h5 className="text-primary">Notifications</h5>
                <span className="table-add float-end mb-3 me-2">
                  <Button
                    className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                    onClick={() => setShowAddNewNotification(true)}
                  >
                    <i className="material-symbols-outlined me-1 md-18">add</i>
                    Create Notification
                  </Button>
                </span>
              </div>
              <div className="d-flex flex-row align-items-center gap-2">
                <div className="" style={{ marginRight: '1rem' }}>Notification Template</div>
                <input
                  type="text"
                  placeholder="-"
                  className=" w-25 border border-light rounded-3 px-2 py-1"
                  onChange={e => setSearchInput(e.target.value ?? "")}
                />
                <button
                  onClick={() => setSearch(!search)}
                  className="bg-primary rounded-3 text-white border-0 d-flex align-items-center py-1"
                >
                  <i className="material-symbols-outlined">search</i>
                </button>
              </div>
            </div>
          </Card>
          {(loading || loadNotifDetail)
            ? <SpinningBlueCircleLoader />
            : <>
              <Row>
                {(notificationList.notifications ?? []).map((item, index) => (
                  <Col xs lg="3" key={index} onClick={() => handleClickNotif(item)} style={{ cursor: "pointer" }}>
                    <Card>
                      <div className="d-flex flex-row align-items-center gap-3 p-4">
                        <img src={EmailIcon} />
                        <div className="me-1 text-danger">{item.notificationId}</div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          }
        </>
        : <AddNewNotification setShowAddNewNotification={setShowAddNewNotification} notificationDetails={notificationDetails} setNotificationDetails={setNotificationDetails} />
      }
    </React.Fragment>
  );
}

export default NotificationsList;
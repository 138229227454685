import { useEffect, useState } from "react";
import { Button, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  SpinningBlueCircleLoader,
  SpinningWhiteCircleLoader,
} from "../../../components/common/loaders";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
// import Pagination from "../../../components/common/pagination";
import NewsIcon from "../../../assets/images/icon/news.svg";
import * as moment from "moment";
import { CustomSelect } from "../../../components/common/CustomSelect";
import ConfirmModal from "../../../components/common/ConfirmModal";
import AddNewEvent from "./addNewEvent";

const EventsList = ({
  showAddNew,
  setShowAddNew,
  details,
  setDetails,
  changesModal,
  setChangesModal,
}) => {
  const postingEvery = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const subCommitteeOptions = [{ value: "EVENT" }, { value: "ARC" }];
  const columnHeader = ["", "Volunteers", "Pledge", "Total Expected", "Needed"];
  const columnHeaderServices = ["", "Volunteers", "Total Expected", "Needed"];

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [list, setList] = useState([]);
  const [loadDetail, setLoadDetail] = useState(false);
  // const [loadStatus, setLoadStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openModal, setOpenModal] = useState({
    save: false,
    delete: false,
    confirmDelete: false,
  });
  const [form, setForm] = useState({
    subCommittee: "",
    posting: "DAILY",
    postingDay: [],
    postingEmailId: "",
  });
  const [cancelLoading, setCancelLoading] = useState(false);

  const handleClickItem = async (item) => {
    setLoadDetail(true);
    try {
      setSelectedEvent(item.id);
      const { data } = await useGetApiClient.get(`/admin/event/get/${item.id}`);
      if (data?.error) throw new Error(data?.error);
      setDetails(data);
      setForm({
        subCommittee: { value: data.subCommittee },
        posting: data.posting,
        postingDay: data.postingDay,
        postingEmailId: data.emailTemplate,
      });
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDetail(false);
    }
  };

  // const handleUpdateStatus = async (item) => {
  //   try {
  //     setLoadStatus(true);
  //     const { data } = await useGetApiClient.put(`/admin/news/update/status/${item.id}`,
  //       {
  //         status: item.status === "ONGOING" ? "CLOSED" : "SENT",
  //       }
  //     );
  //     if (data?.error) throw new Error(data?.error);
  //     getlist();
  //   } catch (error) {
  //     toast.error(error?.message);
  //   } finally {
  //     setLoadStatus(false);
  //   }
  // }

  const handleFormChange = (e, name, id) => {
    if (!changesModal.fields.find((f) => f === name))
      setChangesModal({
        ...changesModal,
        fields: [...changesModal.fields, name],
      });

    if (name === "posting_daily") {
      setForm({
        ...form,
        posting: "DAILY",
        postingDay: [],
      });
    } else if (name === "posting_every") {
      setForm({
        ...form,
        posting: "EVERY",
      });
    } else if (name === "posting_day") {
      if (e.target.checked) {
        setForm({ ...form, postingDay: [...form.postingDay, id] });
      } else {
        setForm({
          ...form,
          postingDay: form.postingDay.filter((item) => item !== id),
        });
      }
    } else if (name === "subCommittee" || name === "postingEmailId") {
      setForm({ ...form, [name]: e });
    } else {
      setForm({ ...form, [name]: e.target.value });
    }
  };

  const handleCancel = async (item) => {
    try {
      // if (!form.cancelEmailTemplateId?.id) {
      //   toast.error("Cancel email template is required.");
      //   return;
      // }

      setCancelLoading(true);
      const { data } = await useGetApiClient.put(
        `/admin/event/cancel/${item?.id}`
      );
      setCancelLoading(false);
      if (data?.error) throw new Error(data?.error);
      toast.success(data?.message);
      getlist();
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setCancelLoading(false);
    }
  };

  const rows = (row) => {
    return (
      <>
        <td>
          <div className="d-flex" style={{ minHeight: "50px" }}>
            {row.name}
          </div>
        </td>
        <td>
          <div className="d-flex flex-column" style={{ minHeight: "50px" }}>
            {row?.pledges?.map((volunteer) => (
              <div>{volunteer?.name}</div>
            ))}
          </div>
        </td>
        <td>
          <div className="d-flex flex-column" style={{ minHeight: "50px" }}>
            {row?.pledges?.map((pledge) => (
              <div>{pledge?.quantity}</div>
            ))}
          </div>
        </td>
        <td>
          <div className="d-flex" style={{ minHeight: "50px" }}>
            {row.expected}
          </div>
        </td>
        <td>
          <div className="d-flex" style={{ minHeight: "50px" }}>
            {row.quantity}
          </div>
        </td>
      </>
    );
  };

  const rowsServices = (row) => {
    return (
      <>
        <td>
          <div className="d-flex" style={{ minHeight: "50px" }}>
            {row.name}
          </div>
        </td>
        <td>
          <div className="d-flex flex-column" style={{ minHeight: "50px" }}>
            {row?.pledges?.map((volunteer) => (
              <div>{volunteer?.name}</div>
            ))}
          </div>
        </td>
        <td>
          <div className="d-flex" style={{ minHeight: "50px" }}>
            {row.expected}
          </div>
        </td>
        <td>
          <div className="d-flex" style={{ minHeight: "50px" }}>
            {row.quantity}
          </div>
        </td>
      </>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.posting === "EVERY" && form.postingDay.length === 0) {
      toast.error("Select at least 1 posting day.");
      return;
    }

    try {
      setSubmitLoading(true);
      const params = {
        subCommittee: form.subCommittee.value,
        posting: form.posting,
        postingDay: form.postingDay,
        emailTemplateId: form.postingEmailId.id,
      };

      // if (type === "add") {
      const { data } = await useGetApiClient.put(
        `/admin/event/update/${details?.id}`,
        params
      );

      if (data?.error) throw new Error(data?.error);
      setOpenModal({ ...openModal, save: true });

      // } else {
      //   const { data } = await useGetApiClient.put(`admin/configuration/voting/update/${details?.id}`,
      //     params
      //   );
      //   if (data?.error) throw new Error(data?.error);
      //   setOpenModal({ ...openModal, save: true })

      // }
      setSubmitLoading(false);
      setChangesModal({ open: false, fields: [], targetTab: null });
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setSubmitLoading(false);
    }
  };

  const getlist = async () => {
    setLoading(true);
    try {
      const { data } = await useGetApiClient.get(
        `/admin/event/list?perPage=${perPage}&currentPage=${page}`
      );
      if (data?.error) throw new Error(data?.error);
      setList(data || []);
      data?.events?.length > 0 && handleClickItem(data?.events?.[0]);
      // if (data?.events?.length > 0) {
      //   if (location?.state) {
      //     const findData = data?.events.find(
      //       (item) => item.id === location.state
      //     );

      //     handleClickItem(findData);
      //   } else {
      //     handleClickItem(data?.events?.[0]);
      //   }
      // }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getlist();
  }, [showAddNew, page, perPage, location]);

  return (
    <>
      {!showAddNew ? (
        <>
          <Card className="config-tasks-list mt-3">
            <Card.Header>
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <div className="d-flex flex-row gap-4">
                  <h5
                    className="text-primary"
                    // onClick={() => setArchived(false)}
                  >
                    Events
                  </h5>
                  {/* <h5
                    className={`${archived ? 'text-decoration-underline fw-bold' : ''} text-primary ms-2`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setArchived(true)}
                  >
                    Archive
                  </h5> */}
                </div>
                <Button
                  className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                  disabled={loading}
                  onClick={() => setShowAddNew(true)}
                >
                  <i className="material-symbols-outlined me-1 md-18">add</i>
                  Create Event
                </Button>
              </div>
            </Card.Header>
          </Card>
          <div className="d-flex flex-row gap-4">
            <div style={{ width: "350px", paddingBottom: "1.5rem" }}>
              <div style={{ maxHeight: 500, overflowY: "auto" }}>
                {loading ? (
                  <Card style={{ padding: 20 }}>
                    <SpinningBlueCircleLoader />
                  </Card>
                ) : list?.events?.length > 0 ? (
                  list?.events?.map((item, index) => (
                    // <Card key={index} onClick={() => handleClickArc(item)} style={{ cursor: "pointer" }}>
                    <Card
                      key={index}
                      style={{
                        backgroundColor:
                          selectedEvent === item.id
                            ? "var(--bs-primary-bg-subtle)"
                            : "white",
                      }}
                    >
                      <div className="d-flex flex-row align-items-center justify-content-between gap-1 p-4">
                        <div
                          className="d-flex flex-row align-items-center gap-3 cursor-pointer"
                          onClick={() => handleClickItem(item)}
                        >
                          <img
                            src={NewsIcon}
                            style={{
                              filter:
                                index % 3 === 0
                                  ? "unset"
                                  : index % 3 === 1
                                  ? "brightness(0) saturate(100%) invert(65%) sepia(39%) saturate(1152%) hue-rotate(214deg) brightness(99%) contrast(104%)"
                                  : "brightness(0) saturate(100%) invert(79%) sepia(14%) saturate(1790%) hue-rotate(334deg) brightness(103%) contrast(100%)",
                            }}
                          />
                          <div>
                            <div
                              className="me-1"
                              style={{
                                color:
                                  index % 3 === 0
                                    ? "#F7685B"
                                    : index % 3 === 1
                                    ? "#D592FF"
                                    : "#FFBA68",
                              }}
                            >
                              {item.name}
                            </div>
                            <div
                              className="me-1"
                              style={{
                                color:
                                  index % 3 === 0
                                    ? "#F7685B"
                                    : index % 3 === 1
                                    ? "#D592FF"
                                    : "#FFBA68",
                                fontSize: "10px",
                              }}
                            >
                              {moment.isMoment(item.when)
                                ? moment(item.when).format("MMMM DD, YYYY")
                                : item.when}
                            </div>
                          </div>
                        </div>
                        {cancelLoading ? (
                          <SpinningBlueCircleLoader />
                        ) : (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Cancel this event</Tooltip>}
                          >
                            <i
                              className="material-symbols-outlined me-1 md-18 text-primary cursor-pointer"
                              onClick={() => handleCancel(item)}
                            >
                              cancel
                            </i>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Card>
                  ))
                ) : (
                  <Card style={{ padding: 20 }}>No record found</Card>
                )}
              </div>
              {/* <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} showRowsPerPage={false} totalCount={list?.count ?? 0} /> */}
            </div>
            <div style={{ width: "100%" }}>
              <Card className="config-tasks-list">
                <Card.Body>
                  {loading || loadDetail ? (
                    <SpinningBlueCircleLoader />
                  ) : (
                    <div className=" d-flex flex-row justify-content-center w-100 gap-4">
                      <div className="col-sm-8 d-flex flex-row justify-content-between">
                        <div>
                          <p>
                            Event:{" "}
                            <span className="text-primary">
                              {details?.name}
                            </span>
                          </p>
                          <p>
                            Location:{" "}
                            <span className="text-primary">
                              {details?.location}
                            </span>
                          </p>
                          <p>
                            About:{" "}
                            <span className="text-primary">
                              {details?.description}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Date Posted:{" "}
                            <span className="text-primary">
                              {moment(details?.datePosted).format(
                                "MMMM DD, YYYY hh:mm A"
                              )}
                            </span>
                          </p>
                          <p>
                            Event Date:{" "}
                            <span className="text-primary">
                              {moment(details?.when).format("MMMM DD, YYYY")}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <img
                          src={details?.picture}
                          className="img-fluid"
                          alt="user"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>

              {!loading && !loadDetail && (
                <>
                  <Card className="config-tasks-list" style={{ width: "100%" }}>
                    <Card.Body>
                      <Form onSubmit={handleSubmit}>
                        <h6 className="text-primary mb-3">Sub-committee</h6>
                        <div style={{ width: 300 }}>
                          <CustomSelect
                            value={form.subCommittee}
                            onChange={(e) =>
                              handleFormChange(e, "subCommittee")
                            }
                            options={subCommitteeOptions || []}
                            optLabelKey="value"
                            optValueKey="value"
                            placeholder="-"
                            required
                          />
                        </div>

                        <h6 className="text-primary mb-3 mt-5">Posting</h6>
                        <div className="col-sm-12">
                          <Form.Check className="d-block mb-0">
                            <Form.Check.Input
                              type="radio"
                              name="posting_daily"
                              id="posting_daily"
                              checked={form.posting === "DAILY"}
                              onChange={(e) =>
                                handleFormChange(e, "posting_daily")
                              }
                            />{" "}
                            <Form.Check.Label>Repost daily</Form.Check.Label>
                          </Form.Check>
                        </div>
                        <div className="col-sm-12 mt-2">
                          <Form.Check className="d-block mb-0">
                            <Form.Check.Input
                              type="radio"
                              name="posting_every"
                              id="posting_every"
                              checked={form.posting === "EVERY"}
                              onChange={(e) =>
                                handleFormChange(e, "posting_every")
                              }
                            />{" "}
                            <Form.Check.Label>Repost every</Form.Check.Label>
                          </Form.Check>
                        </div>
                        <div className="col-sm-12 d-flex align-items-center gap-4 mt-2 mb-2">
                          {postingEvery.map((item, index) => (
                            <Form.Check
                              key={index}
                              type="checkbox"
                              label={item}
                              name={`postingDay_${item}`}
                              checked={form.postingDay.includes(item)}
                              onChange={(e) =>
                                handleFormChange(e, "posting_day", item)
                              }
                              disabled={form.posting === "DAILY"}
                            />
                          ))}
                        </div>

                        <div style={{ width: 300 }}>
                          <CustomSelect
                            value={form.postingEmailId}
                            onChange={(e) =>
                              handleFormChange(e, "postingEmailId")
                            }
                            options={list?.emailTemplates || []}
                            optLabelKey="notificationId"
                            optValueKey="id"
                            placeholder="Email template"
                            required
                          />
                        </div>

                        <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2 mt-3">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ width: "30%" }}
                          >
                            {!loading && !submitLoading ? (
                              "Save"
                            ) : (
                              <SpinningWhiteCircleLoader />
                            )}
                          </button>
                        </Form.Check>
                      </Form>
                    </Card.Body>
                  </Card>

                  <Card className="config-tasks-list" style={{ width: "100%" }}>
                    <Card.Body>
                      <div
                        style={{
                          backgroundColor: "#56bcfd",
                          borderRadius: "5px 5px 0px 0px",
                          padding: "10px",
                          marginBottom: 10,
                          color: "white",
                        }}
                      >
                        Volunteered Items
                      </div>
                      <div className="table-responsive">
                        <table className="config-tasks-list_table table">
                          <thead>
                            <tr>
                              {columnHeader.map((item, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  className="config-tasks-list_table--header text-primary"
                                >
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {details?.itemsNeeded?.length > 0 ? (
                              details?.itemsNeeded?.map((item, idx) => (
                                <tr key={idx}>{rows(item)}</tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={columnHeader.length}
                                  className="text-center"
                                >
                                  No record found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>

                  <Card className="config-tasks-list" style={{ width: "100%" }}>
                    <Card.Body>
                      <div
                        style={{
                          backgroundColor: "#56bcfd",
                          borderRadius: "5px 5px 0px 0px",
                          padding: "10px",
                          marginBottom: 10,
                          color: "white",
                        }}
                      >
                        Services Volunteers
                      </div>
                      <div className="table-responsive">
                        <table className="config-tasks-list_table table">
                          <thead>
                            <tr>
                              {columnHeaderServices.map((item, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  className="config-tasks-list_table--header text-primary"
                                >
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {details?.servicesNeeded?.length > 0 ? (
                              details?.servicesNeeded?.map((item, idx) => (
                                <tr key={idx}>{rowsServices(item)}</tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={columnHeader.length}
                                  className="text-center"
                                >
                                  No record found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                </>
              )}
            </div>
          </div>
          {/* <Card className="config-tasks-list" style={{ width: "100%" }}>
            <Card.Body>
              <h5 className="text-primary mb-3">Latest Event</h5>
              {(loading || loadDetail)
                ? <SpinningBlueCircleLoader />
                : <>{details?.description}
                </>
              }
            </Card.Body>
          </Card> */}
        </>
      ) : (
        <AddNewEvent
          list={list}
          changesModal={changesModal}
          setChangesModal={setChangesModal}
          setShowAddNew={setShowAddNew}
        />
      )}

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={
          <i
            className="material-symbols-outlined text-success"
            style={{ fontSize: "130px" }}
          >
            check_circle
          </i>
        }
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button
            className="btn btn-success w-100"
            onClick={() => setOpenModal({ ...openModal, save: false })}
          >
            Okay
          </button>
        }
      />
    </>
  );
};

export default EventsList;

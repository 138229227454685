import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../components/common/loaders";
import { useGetApiClient } from "../../api/useApiClient";
import { toast } from "react-toastify";

// chart imports
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import * as moment from "moment";
import VotingDetails from "./votingDetails";

ChartJS.register(ArcElement, Tooltip, Legend);

const VotingManagement = () => {

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [archived, setArchived] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [search, setSearch] = useState(false);
  const [details, setDetails] = useState();
  const [tableDetails, setTableDetails] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });

  const handleClickItem = async (item) => {
    setLoading(true)
    try {
      const { data } = await useGetApiClient.get(`/admin/voting/get/${item.id}`);
      if (data?.error) throw new Error(data?.error);
      setDetails(data);
      setTableDetails(item);
      setShowDetails(true);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async (item) => {
    setLoadDelete(true)
    try {
      if (archived) {
        const { data } = await useGetApiClient.delete(`/admin/voting/delete/${item?.id}`);
        if (data?.error) throw new Error(data?.error);
        toast.success(data?.message);
        setOpenModal({ ...openModal, confirmDelete: false, delete: true })
      } else {
        const { data } = await useGetApiClient.put(`/admin/voting/archive/${item?.id}`);
        if (data?.error) throw new Error(data?.error);
        toast.success(data?.message);
        setOpenModal({ ...openModal, confirmDelete: false, delete: true })
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDelete(false);
    }
  }


  const getlist = async () => {
    setLoading(true);
    try {
      const { data } = await useGetApiClient.get(`/admin/voting/list/${archived ? 1 : 0}?search=${searchInput}`);
      if (data?.error) throw new Error(data?.error);
      setList(data || []);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getlist();
  }, [archived, search]);

  return (
    <>
      {!showDetails
        ? <Card className="config-tasks-list mt-3">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="d-flex flex-row gap-4">
                <h5
                  className={`${!archived ? 'text-decoration-underline fw-bold' : ''} text-primary`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setArchived(false)}
                >
                  Voting
                </h5>
                <h5
                  className={`${archived ? 'text-decoration-underline fw-bold' : ''} text-primary ms-2`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setArchived(true)}
                >
                  Archive
                </h5>
              </div>
              {/* <Button
                  className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                  disabled={loading}
                // onClick={() => setShowAddNewArc(true)}
                >
                  <i className="material-symbols-outlined me-1 md-18">add</i>
                  Create ARC
                </Button> */}
            </div>
          </Card.Header>
          <Card.Body className="pt-0">
            <div className="d-flex flex-row align-items-center gap-2" style={{ marginBottom: 60 }}>
              {/* <div className="" style={{ marginRight: '1rem' }}>Notification Template</div> */}
              <input
                type="text"
                placeholder="-"
                className=" w-25 border border-light rounded-3 px-2 py-1"
                onChange={e => setSearchInput(e.target.value ?? "")}
              />
              <button
                onClick={() => setSearch(!search)}
                className="bg-primary rounded-3 text-white border-0 d-flex align-items-center py-1"
              >
                <i className="material-symbols-outlined">search</i>
              </button>
            </div>
            {(loading)
              ? <div><SpinningBlueCircleLoader /></div>
              : (list?.length > 0)
                ? <Row>
                  {/* <div style={{ backgroundColor: "#56bcfd", borderRadius: "5px 5px 0px 0px", padding: "10px", marginBottom: 10, color: "white" }}>Statements - {moment().format('MMMM')}</div> */}
                  {list.map((item, index) => (
                    <Col xs lg="3" key={index} className="mb-5">
                      <div className="d-flex justify-content-end">
                        {item.status === "DONE"
                          ? <i className="material-symbols-outlined me-1 md-18 text-danger cursor-pointer" onClick={() => handleDelete(item)}>backspace</i>
                          : <i style={{ color: "transparent" }}>backspace</i>
                        }
                      </div>
                      <Pie
                        onClick={() => handleClickItem(item)}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          layout: {
                            autoPadding: false,
                          },
                          plugins: {
                            legend: {
                              display: false,
                              position: "bottom",
                              align: "start",
                              // fullSize: false,
                            },
                          },
                        }}
                        data={{
                          labels: item.candidates?.map(candidate => `${candidate?.ownerFirstName} ${candidate?.ownerLastName}`),
                          datasets: [
                            {
                              data: item.candidates?.map(candidate => Number(candidate.tally)),
                              backgroundColor: ["#2dcdb2", "#d592ff", "#ffba68", "#ff9b8a", "#50b5ff", "#24a48e", "#aa75cc", "#cc9553", "#cc7c6e", "#4091cc"],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                      />
                      <h6 className="text-center mt-3 text-primary" onClick={() => handleClickItem(item)}>{item.name}</h6>
                      <h6 className="text-center mt-1 text-primary" onClick={() => handleClickItem(item)}>{moment(item.startDate).format("MMMM DD, YYYY")}</h6>
                    </Col>
                  ))}
                </Row>
                : <div className="text-center">No record found</div>
            }
          </Card.Body>
        </Card>
        : <VotingDetails setShowDetails={setShowDetails} details={details} tableDetails={tableDetails} />
      }
    </>
  );
}

export default VotingManagement;
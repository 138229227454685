import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useGetApiClient } from "../../api/useApiClient";

const useGetHomeownersListApi = (
  page,
  perPage,
  listType,
  reloadData,
  searchInput = ""
) => {
  const [loading, setLoading] = useState(false);
  const [homeOwnersList, setHomeOwnersList] = useState([]);

  useEffect(() => {
    const getHomeOwnersList = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get(
          `/admin/homeowner/list/${listType}?perPage=${perPage}&currentPage=${page}&search=${searchInput}`
        );
        if (data?.error) throw new Error(data?.error);
        setHomeOwnersList(data);
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    };

    getHomeOwnersList();
  }, [page, perPage, listType, reloadData]);

  return { loading, homeOwnersList };
};

export default useGetHomeownersListApi;

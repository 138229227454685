import React, { useState, useEffect } from "react";
import {
  Nav,
  Form,
  Card,
  Container,
  Image,
  Dropdown,
  Navbar,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//image
import Logo from "../../../../assets/images/BE-logo.png";
import user1 from "../../../../assets/images/user/1.jpg";
import user2 from "../../../../assets/images/user/02.jpg";
import user3 from "../../../../assets/images/user/03.jpg";
import user4 from "../../../../assets/images/user/04.jpg";
import user5 from "../../../../assets/images/user/01.jpg";
import user13 from "../../../../assets/images/user/07.jpg";

import defaultUser from "../../../../assets/images/user/default_user.png";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";
import SearchModal from "../../../search-modal";
import useDashboardStore from "../../../../zustand/useDashboardStore";
import { useGetApiClient } from "../../../../api/useApiClient";

const Header = ({}) => {
  const navigate = useNavigate();
  const { authUser, role, setRole } = useDashboardStore();

  const [active, setActive] = useState("home");

  const minisidebar = () => {
    const sidebarMini = document.getElementsByTagName("ASIDE")[0];
    if (sidebarMini.classList.contains("sidebar-mini")) {
      sidebarMini.classList.remove("sidebar-mini");
    } else {
      sidebarMini.classList.add("sidebar-mini");
    }
  };
 
  const handleSwitchRoles = async (id) => {
    setRole(role);
    // localStorage.clear();
    try {
      const {data} = await useGetApiClient.get(`/general/role/switch/${id}`);
      if (data?.error) throw new Error(data?.error);
      if (id === 5) {
        window.open(`${data?.redirectTo}/dashboard/?token=${data?.token}`, "_blank");
        // window.open(`http://localhost:3000/dashboard/?token=${data?.token}`, "_blank");
      } else {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  }

  const dropdownContent = document.querySelectorAll(".sub-drop");

  if (dropdownContent) {
    dropdownContent.forEach((element) => {
      setTimeout(() => {
        element.style = "";
      }, 100);
    });
  }

  useEffect(() => {
    if (!localStorage.getItem("token", "firstName", "lastName")) {
      // navigate("/auth/sign-in");
      toast.success("Sign out success");
    }
  });

  return (
    <>
      {/* <div className="position-relative"> */}
      <div className="iq-top-navbar border-bottom">
        <Navbar
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center pb-2 pb-lg-0 d-xl-none">
              <Link
                to="/"
                className="d-flex align-items-center iq-header-logo navbar-brand d-block d-xl-none"
              >
                <img src={Logo} width="57" />
              </Link>
              <Link
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={minisidebar}
                to="#"
              >
                <div className="icon material-symbols-outlined iq-burger-menu">
                  {" "}
                  menu{" "}
                </div>
              </Link>
            </div>

            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-between product-offcanvas">
                <div
                  className="offcanvas offcanvas-end shadow-none iq-product-menu-responsive d-none d-xl-block"
                  tabIndex="-1"
                  id="offcanvasBottomNav"
                >
                  <div className="offcanvas-body">
                    <ul className="iq-nav-menu list-unstyled">
                      <li className="nav-item">
                        <Link
                          className={`nav-link menu-arrow justify-content-start ${
                            active === "home" ? "active" : ""
                          }`}
                          to="/"
                          onClick={() => setActive("home")}
                        >
                          <span className="nav-text">Home</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link menu-arrow justify-content-start ${
                            active === "Blog Detail" ? "active" : ""
                          }`}
                          to="/dashboard/blog/blog-detail"
                          onClick={() => setActive("Blog Detail")}
                        >
                          <span className="nav-text">Blog</span>
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link
                          className={`nav-link menu-arrow justify-content-start ${
                            active === "blog" ? "active" : ""
                          }`}
                          onClick={() => setActive("blog")}
                          data-bs-toggle="collapse"
                          to="#blogData"
                          role="button"
                          aria-expanded="false"
                          aria-controls="blogData"
                        >
                          <span className="nav-text">Blog</span>
                        </Link>
                        <ul
                          className="iq-header-sub-menu list-unstyled collapse shadow"
                          id="blogData"
                        >
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                active === "Blog Grid" ? "active" : ""
                              }`}
                              to="/dashboard/blog/blog-grid"
                              onClick={() => setActive("Blog Grid")}
                            >
                              Blog Grid
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                active === "Blog List" ? "active" : ""
                              }`}
                              to="/dashboard/blog/blog-list"
                              onClick={() => setActive("Blog List")}
                            >
                              Blog List
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                active === "Blog Detail" ? "active" : ""
                              }`}
                              to="/dashboard/blog/blog-detail"
                              onClick={() => setActive("Blog Detail")}
                            >
                              Blog Detail
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <Dropdown
                bsPrefix=" "
                className="iq-search-bar device-search position-relative d-none d-lg-block"
              >
                <Dropdown.Toggle
                  as="form"
                  bsPrefix=" "
                  action="#"
                  className="searchbox open-modal-search"
                >
                  <Link className="search-link" to="#">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="7.82491"
                        cy="7.82495"
                        r="6.74142"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M12.5137 12.8638L15.1567 15.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </Link>
                  <Form.Control
                    type="text"
                    className="text search-input form-control bg-light-subtle"
                    placeholder="Search for..."
                  />
                  <Link className="d-lg-none d-flex d-none d-lg-block" to="/">
                    <span className="material-symbols-outlined">search12</span>
                  </Link>
                </Dropdown.Toggle>
                <SearchModal />
              </Dropdown>
            </div>

            <ul className="navbar-nav navbar-list">
              <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  as="a"
                  className="search-toggle d-flex align-items-center"
                  id="notification-drop"
                >
                  <span className="material-symbols-outlined position-relative">
                    notifications
                    <span className="bg-primary text-white notification-badge"></span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={`sub-drop header-notification `}
                  aria-labelledby="notification-drop"
                  data-bs-popper="static"
                >
                  <Card className=" m-0 shadow">
                    <div className="card-header d-flex justify-content-between px-0 pb-4 mx-5 border-bottom">
                      <div className="header-title">
                        <h5 className="fw-semibold">Notifications</h5>
                      </div>
                      <h6 className="material-symbols-outlined">settings</h6>
                    </div>
                    <Card.Body>
                      <div className="item-header-scroll">
                        <Link to="#">
                          <div className="d-flex gap-3 mb-4">
                            <img
                              className="avatar-32 rounded-pill"
                              src={user5}
                              alt=""
                            />
                            <div>
                              <h6 className="font-size-14">
                                Pete Sariya{" "}
                                <span className="text-body fw-normal">
                                  voted for
                                </span>{" "}
                                combination of colors from your brand palette{" "}
                              </h6>
                              <small className="text-body fw-500">
                                1 month ago
                              </small>
                            </div>
                          </div>
                        </Link>
                        <Link to="/dashboard/app/notification">
                          <div className="d-flex gap-3 mb-4">
                            <img
                              className="avatar-32 rounded-pill"
                              src={user2}
                              alt=""
                              loading="lazy"
                            />
                            <div>
                              <h6 className="font-size-14">
                                Dima Davydov{" "}
                                <span className="text-body fw-normal">
                                  replied to your
                                </span>{" "}
                                <span className="text-primary fw-semibold">
                                  Comment
                                </span>
                              </h6>
                              <small className="text-body fw-500">
                                1 month ago
                              </small>
                            </div>
                          </div>
                        </Link>
                        <Link to="/dashboard/app/notification">
                          <div className="d-flex gap-3 mb-4">
                            <img
                              className="avatar-32 rounded-pill"
                              src={user3}
                              alt=""
                              loading="lazy"
                            />
                            <div>
                              <h6 className="font-size-14">
                                Esther Howard{" "}
                                <span className="text-body fw-normal">
                                  reacted comment in to your{" "}
                                </span>
                                <span className="text-primary fw-semibold">
                                  post
                                </span>
                                .
                              </h6>
                              <small className="text-body fw-500">
                                19 min ago
                              </small>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <Link to="/dashboard/app/notification">
                        <button
                          type="button"
                          className="btn btn-primary fw-500 w-100"
                        >
                          View All Notifications
                        </button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Item className="nav-item d-none d-lg-none">
                <Link
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  id="mail-drop-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-symbols-outlined">mail</i>
                  <span className="mobile-text  ms-3">Message</span>
                </Link>
              </Nav.Item>
              <Dropdown as="li" className="nav-item user-dropdown">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  className="d-flex align-items-center"
                  id="drop-down-arrow"
                >
                  <Image
                    src={authUser?.picture || defaultUser}
                    className="img-fluid rounded-circle avatar-48 border border-2 me-3"
                    alt="user"
                    loading="lazy"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className={`sub-drop caption-menu `}>
                  <Card className="shadow-none m-0">
                    <Card.Body className="p-0">
                      {authUser?.otherRoles?.map((role, index) => (
                        <div 
                          className="d-flex align-items-center iq-sub-card justify-content-end"
                          onClick={() =>  handleSwitchRoles(role?.id)}
                          key={index}
                        >
                          <div className="ms-3">
                            <span className="mb-0 h6">
                              {role?.name}
                            </span>
                          </div>
                        </div>
                      ))}
                      <div 
                        className="d-flex align-items-center iq-sub-card justify-content-end"
                        onClick={() => {
                          localStorage.clear();
                        }}  
                      >
                        <span className="material-symbols-outlined">login</span>
                        <div className="ms-3">
                          <Link
                            to="/auth/sign-in"
                            className="mb-0 h6"
                            onClick={() => {
                              localStorage.clear();
                            }}
                          >
                            Sign out
                          </Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </Container>
          {/* </Navbar> */}
        </Navbar>
      </div>
      {/* </div> */}

      {/* <div
        className={`modal-backdrop fade ${show ? "show" : "d-none"}`}
        onClick={handleClose}
      ></div> */}
    </>
  );
};

export default Header;
